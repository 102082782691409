<template>
    <div class="nav d-flex justify-content-between">
        <button 
            class="btn btn-link" 
            v-if="showBack" 
            @mousedown="handleInteractionStart('back')"
            @mouseup="handleInteractionEnd('back')"
            @touchstart="handleInteractionStart('back')"
            @touchend="handleInteractionEnd('back')"
            @touchcancel="handleInteractionEnd('back')"
            :class="{ active: isActiveBack }">
            <SvgIcon v-if="backIcon" :name="backIcon" /> {{ backButtonText }}
        </button>
        <button 
            class="btn btn-link" 
            v-if="showSkip" 
            @mousedown="handleInteractionStart('skip')"
            @mouseup="handleInteractionEnd('skip')"
            @touchstart="handleInteractionStart('skip')"
            @touchend="handleInteractionEnd('skip')"
            @touchcancel="handleInteractionEnd('skip')"
            :class="{ active: isActiveSkip }">
            {{ skipButtonText }}
            <span class="icon">
                <SvgIcon v-if="forwardIcon" :name="forwardIcon" />
            </span>
        </button>
    </div>
</template>

<script>
import SvgIcon from './SvgIcon.vue';

export default {
    name: 'NavBar',
    components: {
        SvgIcon
    },
    props: {
        showBack: {
            type: Boolean,
            default: true
        },
        showSkip: {
            type: Boolean,
            default: false
        },
        backText: {
            type: String,
            default: null
        },
        backIcon: {
            type: String,
            default: 'back'
        },
        forwardText: {
            type: String,
            default: null
        },
        forwardIcon: {
            type: String,
            default: 'forward'
        }
    },
    data() {
        return {
            isActiveBack: false,
            isActiveSkip: false,
            interactionStartTime: 0,
            debounceTimeout: null,
        };
    },
    methods: {
        handleInteractionStart(buttonType) {
            if (buttonType === 'back') {
                this.isActiveBack = true;
            } else if (buttonType === 'skip') {
                this.isActiveSkip = true;
            }
            this.interactionStartTime = Date.now();
        },
        handleInteractionEnd(buttonType) {
            const interactionDuration = Date.now() - this.interactionStartTime;
            const remainingTime = Math.max(0, 200 - interactionDuration);

            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                if (buttonType === 'back') {
                    this.isActiveBack = false;
                    this.$emit('back');
                } else if (buttonType === 'skip') {
                    this.isActiveSkip = false;
                    this.$emit('skip');
                }
            }, remainingTime);
        },
    },
    computed: {
        backButtonText() {
            return this.backText || this.$t('backButtonText');
        },
        skipButtonText() {
            return this.forwardText || this.$t('skipButtonText');
        }
    }
};

</script>
<style scoped>
.nav {
    display: block;
    width: 100%;
}

.btn {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 2.5rem;
    line-height: 3.6373rem;
    font-weight: 700;
    transition: all 0.2s ease-in-out;
}

.btn.active {
    transform: scale(0.98);
    transition: all 0.1s ease-in-out;
}

.svg-icon {
    width: 2.5rem !important;
    height: 2.5rem !important;
    margin-right: 0.5rem;
}

.right {
    float: right;
}
</style>