<template>
    <div v-if="initialized">
        <div class="logout" @click="confirmLogout">
            <SvgIcon name="navLogout" width="0.75rem" height="0.75rem" />
            <span>Logout</span>
        </div>

        <StepComponent :title="steps[currentStep].title" :steps="steps" :currentStep="currentStep"
            :showBackButton="currentStep > 0" :showForwardButton="currentStep < steps.length - 1"
            :showCTAButton="currentStep === steps.length - 1" ctaText="Finish" @back="previousStep" @forward="nextStep"
            @cta="finishSetup" :isKioskActive="config.active" @toggle-kiosk="updateConfig('active', !config.active)">
            <!-- Step Content -->
            <template v-if="currentStep === 0">
                <div class="welcome-step d-flex flex-column justify-content-center align-items-center" ref="step0">
                    <div class="step-content" style="text-align: center;">
                        <div class="icon-hold">
                            <SvgIcon name="kiosk" />
                        </div>
                        <h2>Welcome to SkyTab Kiosk</h2>
                        <p>Let's get your kiosk set up and ready to go!</p>
                    </div>
                </div>
            </template>
            <template v-else-if="currentStep === 1">
                <div class="wifi-setup-step" ref="step1">
                    <h2>Connect SkyTab Kiosk Terminal to WiFi</h2>
                    <p>Please follow these steps to connect to your <strong>private WiFi network</strong>:</p>
                    <StepItem :number="1">
                        Tap the <span class="logo-pill"><img src="/s4-logo.svg" alt="Shift4" width="100" /></span> logo
                        (top right) 6+ times until the PIN code screen appears.
                    </StepItem>
                    <StepItem :number="2">
                        Enter today's date for the PIN (e.g., {{ formattedDate }} for MMDDYYYY) then tap <span
                            class="blue-check">
                            <SvgIcon name="check" width="0.75rem" height="0.75rem" />
                        </span>.
                    </StepItem>
                    <StepItem :number="3">
                        Tap the <strong>Android Settings</strong> button.
                    </StepItem>
                    <StepItem :number="4">
                        Tap on <strong>Network & Internet</strong>.
                    </StepItem>
                    <StepItem :number="5">
                        Select <strong>WiFi</strong>.
                    </StepItem>
                    <StepItem :number="6">
                        Choose your private WiFi network from the list.
                    </StepItem>
                    <StepItem :number="7">
                        Enter the password and tap <strong>Connect</strong>.
                    </StepItem>
                    <StepItem :number="8">
                        Tap the back arrow in the Android navigation, then the blue <strong>BACK</strong> button to
                        return to main screen of the Kiosk.
                    </StepItem>
                </div>
            </template>
            <template v-else-if="currentStep === 2">
                <div class="menu-schedule-step" ref="step2">
                    <h2>Manage Your Menu Availability</h2>
                    <p>You can control how your menu is available to customers:</p>

                    <div class="form-group">
                        <div class="form-check form-switch">
                            <input class="form-check-input large-toggle" type="checkbox" id="scheduleToggle"
                                v-model="config.useScheduleHours"
                                @change="updateConfig('useScheduleHours', config.useScheduleHours)">
                            <label class="form-check-label large-label" for="scheduleToggle">
                                {{ config.useScheduleHours ? 'Use Menu Schedules' : 'Open Menu Availability' }}
                            </label>
                        </div>
                    </div>
                    <div v-if="config.useScheduleHours">
                        <p><strong>SkyTab Kiosk follows the schedule you set.</strong> Customers can place orders only
                            during the specified hours.</p>
                        <p class="text-info">Your kiosk will be available during these days and times:</p>
                        <div v-for="schedule in schedules" :key="schedule.ref" class="schedule">
                            <h3>{{ schedule.name }}</h3>
                            <table class="schedule-table">
                                <tbody>
                                    <tr v-for="day in sortDays(Object.keys(schedule.periods))" :key="day">
                                        <td class="day-column"><strong>{{ formatDay(day) }}:</strong></td>
                                        <td class="time-column">
                                            {{ formatTime(schedule.periods[day].startTime) }} - {{
                                            formatTime(schedule.periods[day].endTime) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="preview-message">
                            <p><strong>Preview Message:</strong> {{ storeOpeningTimeMessage }}</p>
                        </div>
                    </div>
                    <div v-else>
                        <p><strong>SkyTab Kiosk is always available.</strong> Kiosk Customers can place orders for any
                            items at any time.</p>
                    </div>
                </div>
            </template>
            <template v-else-if="currentStep === 3">
                <div class="branding-step" ref="step3">
                    <h2>Personalize Your Kiosk</h2>
                    <p>You can choose a main color that matches your brand. Select from predefined colors or pick a
                        custom one.
                    </p>

                    <div class="color-grid">
                        <div v-for="color in predefinedColors" :key="color.name" class="color-chip"
                            :style="{ backgroundColor: color.value }"
                            :class="{ 'selected': config.template.brandColor === color.value }"
                            @click="updateConfig('template.brandColor', color.value)" :title="color.name">
                        </div>
                    </div>

                    <!--
                    <div class="custom-color-section mt-3">
                        <label for="customBrandColor" class="form-label">Custom Color</label>
                        <input type="color" class="form-control form-control-color ml-2" id="customBrandColor"
                            v-model="config.template.brandColor"
                            @change="updateConfig('template.brandColor', config.template.brandColor)">
                        <small class="form-text text-muted mt-2">Select a custom color if none of the predefined colors suit your needs.</small>
                    </div>
                    -->
                </div>
            </template>
            <template v-else-if="currentStep === 4">
                <div class="deactivate-kiosk-step" ref="step4">
                    <h2>Control Your Kiosk Status</h2>
                    <p>You can enable or disable your kiosk remotely with a simple toggle.</p>
                    <div class="form-group">
                        <div class="form-check form-switch">
                            <input class="form-check-input large-toggle" type="checkbox" id="kioskStatus"
                                v-model="config.active" @change="updateConfig('active', config.active)">
                            <label class="form-check-label large-label" for="kioskStatus">
                                {{ config.active ? 'Active' : 'Deactivated' }}
                            </label>
                        </div>
                        <div v-if="!config.active" class="mt-3">
                            <p>SkyTab Kiosk is <strong class="text-danger">deactivated</strong>. Customers
                                <strong>cannot</strong> place orders.
                            </p>
                            <label for="deactivatedMessage" class="form-label">Deactivation Message</label>
                            <textarea class="form-control" id="deactivatedMessage" rows="4"
                                v-model="config.text.deactivatedMessage"
                                @keyup="updateConfig('text.deactivatedMessage', config.text.deactivatedMessage)">
                            </textarea>
                            <small class="form-text text-muted">This is the message displayed when the kiosk is
                                deactivated. Use it to provide a reason or instructions for your customers.</small>
                        </div>
                        <div v-else class="mt-3">
                            <p>SkyTab Kiosk is <strong class="text-success">active</strong>. Customers can place orders.
                            </p>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="currentStep === 5">
                <div class="save-webpage-step" ref="step5">
                    <h2>Save This Webpage to Your Phone</h2>
                    <p>Save this webpage to your home screen for easy access. This allows you to quickly return and
                        change settings anytime.</p>
                    <StepItem :number="1">
                        <strong>For iOS (Safari):</strong> Tap the share button at the bottom of the screen, then select
                        "Add to Home Screen".
                    </StepItem>
                    <StepItem :number="2">
                        <strong>For Android (Chrome):</strong> Tap the menu button (three dots) at the top right, then
                        select "Add to Home Screen".
                    </StepItem>
                    <p class="text-info">This will allow you to quickly access the management system with just one tap!
                    </p>
                </div>
            </template>
        </StepComponent>
        <div v-if="loading" class="loading-indicator">
            <SpinnerLarge /> <!-- Replace with your spinner component -->
            <p>Saving...</p>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SpinnerLarge from '@/components/features/SpinnerLarge.vue';
import StepComponent from './setup/StepComponent.vue';
import StepItem from './setup/StepItem.vue';
import { openingSoonMessage, isOpen } from "@/utils/time";

export default {
    name: 'SetupView',
    components: {
        SpinnerLarge,
        StepComponent,
        StepItem,
    },
    data() {
        return {
            currentStep: 0,
            loading: false,
            steps: [
                { title: 'Welcome' },
                { title: 'Setup WiFi on Android' },
                { title: 'Branding' },
                { title: 'Operations' },
                { title: 'Ordering' },
                { title: 'Menu View' },
                { title: 'Save Webpage to Home Screen' },
            ],
            isDragOver: false,
            isUploading: false,
            predefinedColors: [
                { name: 'Black', value: '#232221' },
                { name: 'Shift4 Blue', value: '#126EF6' },
                { name: 'Forest Green', value: '#228B22' },
                { name: 'Crimson Red', value: '#DC143C' },
                { name: 'Goldenrod', value: '#DAA520' },
                { name: 'Dark Slate Blue', value: '#483D8B' },
                { name: 'Teal', value: '#008080' },
                { name: 'Chocolate', value: '#D2691E' },
                { name: 'Dark Olive Green', value: '#556B2F' },
                { name: 'Firebrick', value: '#B22222' },
                { name: 'Saddle Brown', value: '#8B4513' },
                { name: 'Midnight Blue', value: '#191970' },
            ],
        };
    },
    computed: {
        ...mapState("admin", ['activeCount', 'isLoggedIn', 'config', 'locations', 'activeLocation', 'initialized', 'accessList', 'orderTypes', 'schedules']),
        formattedDate() {
            const today = new Date();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            const year = today.getFullYear();
            return `${month}${day}${year}`;
        },
        storeOpen() {
            if (!this?.schedules) {
                return false;
            }
            return isOpen(this?.schedules);
        },
        storeOpeningTimeMessage() {
            if (!this?.schedules) {
                return '';
            }
            return openingSoonMessage(this?.schedules);
        }
    },
    methods: {
        ...mapActions("admin", ['updateConfigProperty', 'uploadLogo', 'deleteLogo', 'exitSetupMode', 'fetchOrderTypes', 'logout', 'fetchLocations']),
        async initialize() {
            this.loading = true;
            await this.loadSavedLocation();
            await this.fetchOrderTypes();
            this.enterSetupMode('start');
            this.loading = false;
        },
        async loadSavedLocation() {

            const savedLocation = localStorage.getItem('activeLocation');
            if (savedLocation) {
                await this.$store.dispatch('admin/setActiveLocation', JSON.parse(savedLocation));
            } else {
                await this.fetchLocations();
                await this.$store.dispatch('admin/setActiveLocation', this.locations[0]);
            }
        },
        updateConfig(key, value) {
            this.updateConfigProperty({ key, value });
        },
        formatDay(day) {
            return day.charAt(0) + day.slice(1).toLowerCase();
        },
        formatTime(time) {
            const [hour, minute] = time.split(':');
            const hourInt = parseInt(hour, 10);
            const ampm = hourInt >= 12 ? 'PM' : 'AM';
            const formattedHour = hourInt % 12 || 12;
            return `${formattedHour}:${minute} ${ampm}`;
        },
        sortDays(days) {
            const order = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
            return days.sort((a, b) => order.indexOf(a) - order.indexOf(b));
        },
        handleDragOver(event) {
            event.preventDefault();
            this.isDragOver = true;
        },
        handleOrderTypeChange(index, value) {
            this.updateConfig(`orderTypes.${index}.orderTypePosGuid`, value);
        },
        handleDragLeave() {
            this.isDragOver = false;
        },
        async handleDrop(event) {
            event.preventDefault();
            this.isDragOver = false;
            const file = event.dataTransfer.files[0];
            if (file) {
                await this.uploadFile(file);
            }
        },
        async handleLogoUpload(event) {
            const file = event.target.files[0];
            if (file) {
                await this.uploadFile(file);
            }
        },
        async uploadFile(file) {
            this.isUploading = true;
            const formData = new FormData();
            formData.append('file', file);
            try {
                const response = await this.uploadLogo(formData);
                if (response && response.url) {
                    await this.updateConfigProperty({ key: 'logo.url', value: response.url });
                }
            } catch (error) {
                console.error('Error uploading logo:', error);
                alert('Failed to upload logo. Please try again later.');
            } finally {
                this.isUploading = false;
            }
        },
        async removeLogo() {
            if (this.config.logo?.url) {
                await this.deleteLogo({ url: this.config.logo.url });
                await this.updateConfigProperty({ key: 'logo.url', value: null });
            }
        },
        triggerFileInput() {
            this.$refs.logo.click();
        },
        enterSetupMode(screen) {
            this.isInSetupMode = true;
            this.$store.dispatch('admin/setSetupMode', {
                screen,
                payload: { active: true }
            });
        },
        previousStep() {
            if (this.currentStep > 0) {
                this.currentStep--;
            }
        },
        nextStep() {
            if (this.currentStep < this.steps.length - 1) {
                this.currentStep++;
            }
        },
        finishSetup() {
            this.exitSetupMode();
            this.$router.push({ name: 'admin' });
        },
        handleOrderTypeToggle(index, isActive) {
            if (!isActive && this.config.orderTypes.filter(orderType => orderType.active).length === 0) {
                alert('At least one order type must be active.');
                this.config.orderTypes[index].active = true;
            } else {
                this.updateConfig(`orderTypes.${index}.active`, isActive);
            }
        },
        disableTimeout() {
            this.config.idleTimeoutSeconds = 100000000;
            this.updateConfig('idleTimeoutSeconds', 100000000);
        },
        enableTimeout() {
            this.config.idleTimeoutSeconds = 0;
            this.updateConfig('idleTimeoutSeconds', 0);
        },
        previewColor() {
            alert(`Previewing color: ${this.config.template.brandColor}`);
        },
        confirmLogout() {
            if (confirm("Are you sure you want to log out?")) {
                const currentRoute = this.$route.fullPath;
                this.$store.dispatch('admin/logout');
                this.$router.push({
                    name: 'admin-login',
                    query: { redirect: currentRoute }
                });
            }
        }
    },
    mounted() {
        this.initialize();
        window.addEventListener('pagehide', this.exitSetupMode);
        window.addEventListener('freeze', this.exitSetupMode);
        window.addEventListener('resign', this.exitSetupMode);
    },
    beforeUnmount() {
        window.removeEventListener('pagehide', this.exitSetupMode);
        window.removeEventListener('freeze', this.exitSetupMode);
        window.removeEventListener('resign', this.exitSetupMode);
    }
};
</script>

<style scoped>
.section-holder {
    padding: 1rem;
}

.section {}

.section.active {
    border: 2px solid #126EF6;
}

h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
}

.form-label {
    font-size: 1rem;
    color: #666;
}

.btn-outline-danger {
    border-radius: 5px;
    font-size: 0.875rem;
}

.save-button-container {
    text-align: right;
    padding: 1.5rem;
}

.blue-check {
    color: #fff;
    fill: #fff;
    background-color: #126EF6;
    padding: 0.25rem;
    border-radius: 0.25rem;
    width: 2rem;
    display: inline-block;
}

.blue-check svg {
    width: 1rem !important;
    height: 1rem !important;
}

.image-upload-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    background-color: #f9f9f9;
    text-align: center;
}

.drop-zone {
    border: 2px dashed #bbb;
    padding: 2rem;
    text-align: center;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.drop-zone.drag-over {
    background-color: #e9e9e9;
}

.color-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-items: center;
    align-items: center;
    margin-top: 20px;
}

.color-chip {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: 0 0 0 2px #fff;
    position: relative;
}

.color-chip.selected {
    transform: scale(1.2);
    box-shadow: 0 0 0 3px #126EF6;
}

.color-chip::after {
    content: attr(title);
    position: absolute;
    bottom: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
    font-size: 0.75rem;
    white-space: nowrap;
    display: none;
}

.color-chip:hover::after {
    display: block;
}

.form-check {
    margin-bottom: 1rem;
    border: 0.125rem solid rgba(0, 0, 0, 0.16);
    border-radius: 0.5rem;
    padding: 1rem;
}

.form-check.active {
    border-color: #28a745;
    background-color: #d4edda;
}

.form-check.inactive {
    border-color: #dc3545;
    background-color: #f8d7da;
}

.form-check-input {
    margin-left: 0 !important;
}

.form-check-label {
    font-size: 1rem;
    color: #333;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.custom-color-section {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.form-check-input.large-toggle {
    width: 2.5rem;
    height: 1.5rem;
    margin-left: 0 !important;
}

.form-check-input.large-toggle:checked {
    background-color: #28a745;
}

.form-check-label.large-label {
    font-size: 1.25rem;
    margin-left: 0.75rem;
    color: #333;
}

.section.current {
    border: 2px solid #126EF6;
}

.loading-indicator {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
    padding: 1.5rem;
}

.schedule {
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.schedule.inactive {
    opacity: 0.5;
}

.schedule h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #333;
}

.schedule ul {
    list-style-type: none;
    padding: 0;
}

.schedule li {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
    color: #555;
}

.text-info {
    font-weight: 600;
    color: #333 !important;
    font-size: 1.125rem;
    margin-bottom: 1rem;
}

.header {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    background-color: #f8f9fa;
}

.logout {
    cursor: pointer;
    font-size: 0.75rem;
    padding: 0.25rem;
    border-radius: 2rem;
    background-color: #f0f0f0;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 1000;
}

.schedule-table {
    margin: auto;
    border-collapse: separate;
    border-spacing: 0 0.5rem;
}

.day-column {
    width: 100px;
    text-align: right;
    padding-right: 1rem;
}

.time-column {
    text-align: left;
}

.menu-schedule-step {}

h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.text-info {
    color: #0dcaf0;
    font-weight: 600;
    margin-top: 1rem;
}

.schedule {
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-bottom: 1rem;
}

.schedule h3 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.schedule-table {
    width: 100%;
}

.day-column {
    width: 120px;
    text-align: right;
    padding-right: 1rem;
}

.time-column {
    text-align: left;
}

.preview-message {
    margin-top: 1rem;
    padding: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}
</style>