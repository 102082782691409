<template>
    <div v-if="isVisible" class="modal show d-block" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close" @click="$emit('close')"></button>
                    <br /><br />
                    <slot></slot>
                </div>
            </div>
        </div>
        <div class="modal-backdrop show"></div>
    </div>
</template>

<script>
export default {
    props: {
        isVisible: Boolean
    }
}
</script>

<style scoped>
.modal .modal-dialog {
    max-width: 40rem !important;
    z-index: 9999;
}
</style>
