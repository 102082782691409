
export default {
  namespaced: true,
  state: {
    open: false,
    title: null,
    message: null,
    showCancel: false,
    okText: 'OK',
    cancelText: 'Cancel',
    onConfirm: null,
    onCancel: null,
  },
  getters: {
    open: (state) => state.open,
    title: (state) => state.title,
    message: (state) => state.message,
    showCancel: (state) => state.showCancel,
    okText: (state) => state.okText,
    cancelText: (state) => state.cancelText,
  },
  mutations: {
    SET_OPEN(state, isOpen) {
      state.open = isOpen;
    },
    SET_DIALOG(state, { title, message, showCancel, onConfirm, onCancel, okText, cancelText }) {
      state.title = title;
      state.message = message;
      state.showCancel = showCancel;
      state.onConfirm = onConfirm;
      state.onCancel = onCancel;
      state.okText = okText;
      state.cancelText = cancelText;
    },
  },
  actions: {
    close({ commit }) {
      commit("SET_OPEN", false);
    },
    openDialog({ commit }, { title, message, showCancel, onConfirm, onCancel, okText, cancelText }) {
      if (!okText) {
        okText = 'OK';
      }
      if (!cancelText) {
        cancelText = 'Cancel';
      }
      commit("SET_DIALOG", { title, message, showCancel, onConfirm, onCancel, okText, cancelText });
      commit("SET_OPEN", true);
    },
    confirmDialog({ state, commit }) {
      if (state.onConfirm) state.onConfirm();
      commit("SET_OPEN", false);
    },
    cancelDialog({ state, commit }) {
      if (state.onCancel) state.onCancel();
      commit("SET_OPEN", false);
    },
  },
};
