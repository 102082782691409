

import config from "./../../config";
import router from "@/router";
import store from "@/store";

async function appError(title, message, showBack, showStartOver) {
  router.push({
    name: "error",
    query: {
      title: title,
      message: message,
      back: showBack,
      startOver: showStartOver
    }
  });
}

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  }
  );
}

function lightenHexColor(hex, percentage) {
  console.log('lightenHexColor', hex, percentage)
  // Ensure percentage is between 0 and 100
  percentage = Math.min(100, Math.max(0, percentage));

  // Convert hex to RGB
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  // Calculate the new color values
  const newR = Math.round(r + (255 - r) * (1 - percentage / 100));
  const newG = Math.round(g + (255 - g) * (1 - percentage / 100));
  const newB = Math.round(b + (255 - b) * (1 - percentage / 100));

  // Convert RGB back to hex
  const toHex = (n) => n.toString(16).padStart(2, '0');
  const newHex = `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`;

  return newHex;
}

function formatAddress(locationDetails) {
  return [
    locationDetails.address_1,
    locationDetails.address_2,
    locationDetails.city,
    locationDetails.state,
    locationDetails.postal,
  ]
    .filter(Boolean)
    .join(", ");

}

function hexToRGBA(hex, opacity) {
  if (hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}

function toCamelCase(str) {
  return str.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}

function subtotalItem(item) {
  item.quantity = item.quantity || 1;
  const copyItem = JSON.parse(JSON.stringify(item));
  let itemTotal = copyItem.price;

  console.log(`Calculating subtotal for itemRef ${copyItem.itemRef}. Base price: ${itemTotal}, quantity: ${copyItem.quantity}`);

  let modTotal = 0;

  copyItem.lineModifierSets?.forEach((modSet) => {
    modSet.lineModifiers?.forEach((modifier) => {
      const modifierSubtotal = modifier.price * modifier.quantity;
      modTotal += modifierSubtotal;

      console.log(`Adding modifier subtotal for modifierRef ${modifier.ref}: ${modifierSubtotal} (price: ${modifier.price}, quantity: ${modifier.quantity})`);

      if (modifier?.lineModifierSets) {
        modifier.lineModifierSets.forEach((set) => {
          set.lineModifiers.forEach((nestedModifier) => {
            const nestedModifierSubtotal = nestedModifier.price * nestedModifier.quantity;
            modTotal += nestedModifierSubtotal;

            console.log(`Adding nested modifier subtotal for nestedModifierRef ${nestedModifier.ref}: ${nestedModifierSubtotal} (price: ${nestedModifier.price}, quantity: ${nestedModifier.quantity})`);
          });
        });
      }
    });
  });

  const total = (itemTotal + modTotal) * item.quantity;
  console.log(`Final total for itemRef ${copyItem.itemRef}: ${total}`);
  return total;
}


function subtotalOrder(orderItems) {
  let orderTotal = 0;

  orderItems.forEach((item) => {
    orderTotal += subtotalItem(item);
  });

  return orderTotal;
}

function imageUrl(base) {
  if (base && base.startsWith("http")) return base;
  return base ? `https://${config.API_HOST}${base}` : null;
}

function formatCurrency(value) {

  const locale = store.getters['location/config']?.locale || "en-US";
  const currency = store.getters['location/config']?.currency || "USD";

  return new Intl.NumberFormat(locale || "en-US", {
    style: "currency",
    currency: currency || "USD",
  }).format(value / 100);
}

function findOrCreateModifierSet(state, modifierSetRef, modifierSetName) {
  let modSet = state.lineModifierSets.find(ms => ms.modifierSetRef === modifierSetRef);
  if (!modSet && !modifierSetName) {
    const modSetForName = state.item?.modifierSets.find(ms => ms.ref === modifierSetRef);
    modifierSetName = modSetForName?.name;
  }
  if (!modSet) {
    modSet = {
      name: modifierSetName || "Unknown Set",
      lineModifiers: [],
      modifierSetRef: modifierSetRef,
    };
    state.lineModifierSets.push(modSet);
  }
  return modSet;
}

function paymentDetailsAsString(baseTotal, taxTotal, tipTotal, discounts, surcharges) {
  if (taxTotal === 0 && tipTotal === 0 && discounts === 0) {
    return '';
  }

  let details = `Subtotal: ${formatCurrency(baseTotal)}`;

  if (discounts) {
    details += ` - Discounts: (${formatCurrency(discounts)})`;
  }

  if (surcharges) {
    // list each surcharge
    surcharges.forEach(surcharge => {
      details += ` + ${surcharge.name}: ${formatCurrency(surcharge.amount)}`;
    });
  }

  if (taxTotal > 0) {
    details += ` + Taxes: ${formatCurrency(taxTotal)}`;
  }

  if (tipTotal > 0) {
    details += ` + Tip: ${formatCurrency(tipTotal)}`;
  }

  return details;
}

function toggleModifierInSet(modSet, modifier) {
  const index = modSet.lineModifiers.findIndex(m => m.modifierRef === modifier.ref);
  if (index === -1) {
    modSet.lineModifiers.push({
      name: modifier.name,
      price: modifier.price,
      quantity: modifier?.quantity || 1,
      modifierRef: modifier.ref,
      total: modifier.price * (modifier?.quantity || 1),
      lineModifierSets: modifier.lineModifierSets || [],
    });
  } else {
    modSet.lineModifiers.splice(index, 1);
  }
}

function applyCoupon(subtotal, coupon) {
  if (!coupon) return subtotal;

  if (coupon.type === '%') {
    return Math.max(0, subtotal * (1 - coupon.amount / 100));
  } else if (coupon.type === '$') {
    return Math.max(0, subtotal - (coupon.amount * 100)); // coupons in dollars - and subtotal in cents
  }

  return subtotal;
}

export {
  appError,
  formatAddress,
  hexToRGBA,
  lightenHexColor,
  toTitleCase,
  subtotalOrder,
  toCamelCase,
  subtotalItem,
  imageUrl,
  formatCurrency,
  paymentDetailsAsString,
  findOrCreateModifierSet,
  toggleModifierInSet,
  applyCoupon
};
