<template>
  <div class="guides-component container-fluid py-4">
    <div class="row mb-4">
      <div class="col-lg-8 col-md-6 mb-3 mb-md-0">
        <div class="input-group">
          <span class="input-group-text bg-white">
            <i class="bi bi-search"></i>
          </span>
          <input
            type="text"
            v-model="searchTerm"
            placeholder="Search snippets..."
            class="form-control form-control-lg"
          />
        </div>
      </div>
      <div class="col-lg-4 col-md-6 d-flex justify-content-md-end">
        <div class="btn-group" role="group">
          <button @click="viewMode = 'list'" :class="['btn btn-lg', viewMode === 'list' ? 'btn-primary' : 'btn-outline-primary']">
            <i class="bi bi-list-ul me-2"></i> List View
          </button>
          <button @click="viewMode = 'expanded'" :class="['btn btn-lg', viewMode === 'expanded' ? 'btn-primary' : 'btn-outline-primary']">
            <i class="bi bi-grid-3x3-gap me-2"></i> Expanded View
          </button>
        </div>
      </div>
    </div>

    <div v-if="viewMode === 'list'" class="row">
      <div class="col-md-4 mb-4 mb-md-0">
        <div class="list-group shadow-sm">
          <a
            v-for="snippet in filteredSnippets"
            :key="snippet.name"
            @click.prevent="selectSnippet(snippet)"
            :class="['list-group-item list-group-item-action', selectedSnippet && selectedSnippet.name === snippet.name ? 'active' : '']"
            href="#"
          >
            <h5 class="mb-1">{{ snippet.title }}</h5>
            <div class="tags">
              <span v-for="tag in snippet.tags" :key="tag" class="badge bg-light text-dark me-1">{{ tag }}</span>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-8" v-if="selectedSnippet">
        <div class="card shadow">
          <div class="card-body">
            <h2 class="card-title mb-4">{{ selectedSnippet.title }}</h2>
            <component :is="selectedSnippet.component" />
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="viewMode === 'expanded'" class="row g-4">
      <div
        v-for="snippet in filteredSnippets"
        :key="snippet.name"
        class="col-lg-6 col-xl-4"
      >
        <div class="card h-100 shadow-sm hover-shadow">
          <div class="card-body">
            <h3 class="card-title mb-3">{{ snippet.title }}</h3>
            <component :is="snippet.component" />
            <div class="tags mt-3">
              <span v-for="tag in snippet.tags" :key="tag" class="badge bg-light text-dark me-1">{{ tag }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="filteredSnippets.length === 0" class="text-center mt-5">
      <i class="bi bi-emoji-frown display-1 text-muted"></i>
      <p class="lead mt-3">No snippets found. Try adjusting your search.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: '',
      snippets: [],
      viewMode: 'list', // 'list' or 'expanded'
      selectedSnippet: null,
    };
  },
  computed: {
    filteredSnippets() {
      return this.snippets.filter((snippet) =>
        snippet.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        snippet.title.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        snippet.tags.some(tag => tag.toLowerCase().includes(this.searchTerm.toLowerCase()))
      );
    },
  },
  methods: {
    selectSnippet(snippet) {
      this.selectedSnippet = snippet;
    },
  },
  mounted() {
    const requireComponent = require.context(
      // The relative path to the snippets directory
      '../snippets',
      // Whether to look into subfolders
      false,
      // Regular expression to match component filenames
      /[\w-]+\.vue$/
    );

    requireComponent.keys().forEach((fileName) => {
      // Get component config
      const componentConfig = requireComponent(fileName);

      // Get component name by removing the "./" and file extension
      const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1');

      // Add to snippets array
      this.snippets.push({
        name: componentName,
        title: componentConfig.default.title || componentName,
        tags: componentConfig.default.tags || [],
        component: componentConfig.default || componentConfig,
      });
    });
  },
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");

.guides-component {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.hover-shadow:hover {
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.list-group-item.active {
  background-color: #f8f9fa;
  color: #212529;
  border-color: #dee2e6;
  border-left: 4px solid #007bff;
}

.card-title {
  color: #007bff;
}

.tags {
  margin-top: 0.5em;
}

.badge {
  font-size: 0.8em;
  font-weight: 500;
}

.input-group-text {
  border-right: none;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.btn-group .btn {
  transition: all 0.3s ease;
}

@media (max-width: 767.98px) {
  .btn-group .btn {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
  }
}
</style>