<template>
    <nav class="tab-nav-container" :class="{ 'nav-top': navTop }" aria-label="Tab Navigation">
        <!-- Left Arrow Button -->
        <button v-if="showLeftArrow" class="arrow arrow-left" @click="scrollLeft" aria-label="Scroll left">
            <SvgIcon name="forward" class="arrow-icon left" />
        </button>

        <!-- Tabs List -->
        <ul ref="tabsList" class="tabs-list" role="tablist">
            <li class="logo-holder" v-if="navTop && logo">
                <LocationLogo :logo="logo" :logoName="logoName" :shape="shape" @click="selectFirstTab"  />
            </li>
            <li v-for="tab in tabs" :key="tab.ref" :class="{ 'is-selected': isSelected(tab) }" @click="selectTab(tab)"
                role="tab" :aria-selected="isSelected(tab)">
                <div class="tab-content">
                    <SvgIcon name="reorder" class="reorder-icon" v-if="tab.name === 'Order Again'" />
                    {{ tab.name }}
                </div>
            </li>
        </ul>

        <!-- Right Arrow Button -->
        <button v-if="showRightArrow" class="arrow arrow-right" @click="scrollRight" aria-label="Scroll right">
            <SvgIcon name="forward" class="arrow-icon right" />
        </button>
    </nav>
</template>

<script>
import debounce from 'lodash/debounce';
import SvgIcon from './SvgIcon.vue';
import LocationLogo from './LocationLogo.vue';

export default {
    name: 'TabNav',
    components: { SvgIcon, LocationLogo },
    props: {
        tabs: Array,
        logo: String,
        logoName: String,
        shape: String,
        selectedTabRef: String,
        navTop: Boolean,
    },
    data() {
        return {
            showLeftArrow: false,
            showRightArrow: false,
            debouncedCheck: null,
        };
    },
    watch: {
    },
    mounted() {
        this.debouncedCheck = debounce(this.checkScrollArrows, 200);
        this.$nextTick(() => {
            this.checkScrollArrows();
            this.$refs.tabsList.addEventListener('scroll', this.debouncedCheck);
        });
        window.addEventListener('resize', this.debouncedCheck);

        // Ensure initial scroll position is at the top
        this.$refs.tabsList.scrollTop = 0;
        this.checkInitialNavTop();
    },
    unmounted() {
        window.removeEventListener('resize', this.debouncedCheck);
        if (this.$refs.tabsList) {
            this.$refs.tabsList.removeEventListener('scroll', this.debouncedCheck);
        }
    },
    methods: {
        selectFirstTab() {
            this.$emit('tab-selected', this.tabs[0].ref);
        },
        selectTab(tab) {
            this.$emit('tab-selected', tab.ref);
        },
        isSelected(tab) {
            return this.selectedTabRef === tab.ref;
        },
        checkInitialNavTop() {
            const nav = this.$el;
            const rect = nav.getBoundingClientRect();
            console.log('Initial nav rect:', rect);

            // Ensure navTop is false on initial load
            if (rect.top > 0) {
                this.$emit('update:navTop', false);
            }
        },
        checkScrollArrows() {
            const el = this.$refs.tabsList;
            if (!el) return;

            const tolerance = 5; // Tolerance for floating point inaccuracies
            this.showLeftArrow = el.scrollLeft > tolerance;
            this.showRightArrow =
                el.scrollLeft + el.clientWidth < el.scrollWidth - tolerance;

            // Update navTop based on scroll position
            const nav = this.$el;
            const rect = nav.getBoundingClientRect();
            console.log('Scroll nav rect:', rect);

            if (el.scrollTop > 0 && rect.top <= 0) {
                this.$emit('update:navTop', true);
            } else {
                this.$emit('update:navTop', false);
            }
        },
        scrollLeft() {
            this.scrollTabs(-1);
        },
        scrollRight() {
            this.scrollTabs(1);
        },
        scrollTabs(direction) {
            const el = this.$refs.tabsList;
            const scrollAmount = 160; // 10rem in pixels

            if (direction > 0) {
                // Scroll Right
                el.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            } else {
                // Scroll Left
                el.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
            }

            this.$nextTick(this.checkScrollArrows);
        },
        scrollToSelectedTab() {
            const el = this.$refs.tabsList;
            const selectedTab = el.querySelector('.is-selected');
            if (selectedTab) {
                selectedTab.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                this.$nextTick(this.checkScrollArrows);
            }
        },
    },
};
</script>


<style scoped>
.tab-nav-container {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--grey-50);
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
}

.nav-top.tab-nav-container {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    border-bottom: 0.125rem solid var(--grey-outline);
    position: sticky;
    top: 0px;
    z-index: 3000;
    box-shadow: var(--box-shadow);
    background-color: var(--primary-white);
}

.tabs-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    overflow-x: auto;
    flex-grow: 1;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.tabs-list::-webkit-scrollbar {
    display: none;
}

li {
    cursor: pointer;
    font-size: 1.625rem;
    line-height: 2.21625rem;
    color: var(--primary-black);
    font-weight: normal;
}

li.logo-holder {
    background-color: transparent !important;
    width: 3.5rem;
    height: 3.5rem;
}

.logo-holder .logo-container {
    width: 3.5rem;
    height: 3.5rem;
    margin-left: -1rem;
    margin-right: 1rem;
}

ul {
    margin-left: 0rem !important;
    padding-left: 0rem !important;
}

ul li:first-child {
    margin-left: 2.5rem !important;
}

.tab-content {
    background-color: var(--grey-pressed);
    border-radius: 6.25rem;
    margin-right: 1rem;
    padding: 0.625rem 2.0625rem;
}

li.is-selected .tab-content {
    background-color: var(--primary-black);
    color: var(--primary-white);
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    border: none;
    cursor: pointer;
    background-color: transparent;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem;
    width: 3.25rem;
    height: 3.25rem;
    color: var(--primary-white);
    fill: var(--primary-white);
    background-color: var(--primary-black);
    border-radius: 6.25rem;
    margin-right: 1rem;
}

.arrow-icon {
    fill: var(--primary-white) !important;
    width: 1.5rem;
    height: 1.5rem;
}

.arrow-icon.left {
    transform: rotate(180deg);
}

.arrow-left {
    left: 1rem;
}

.arrow-right {
    right: 0;
}
</style>
