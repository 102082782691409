<template>
    <div>
        <div v-if="isEditing" class="edit-container">
            <input v-model="editableText" @keyup.enter="save" class="edit-input" placeholder="Enter text here..." />
            <button @click="save" class="action-button">Save</button>
            <button @click="cancel" class="action-button">Cancel</button>
        </div>
        <div v-else @click="startEditing" class="text-container" :class="{ 'hover-editable': isLoggedIn }">
            <span v-if="text">{{ text }}</span>
            <span v-else-if="isLoggedIn" class="placeholder-text">Click to add text</span>
        </div>
    </div>
</template>

<script>
//import { mapGetters } from 'vuex';

export default {
    props: {
        textKey: {
            type: String,
            required: true
        },
        initialValue: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isEditing: false,
            editableText: this.initialValue,
            isLoggedIn: false
        };
    },
    computed: {
        /*...mapGetters({
            isLoggedIn: 'admin/isLoggedIn'
        }),*/
        text() {
            return this.$t(this.textKey) || this.editableText;
        }
    },
    methods: {
        startEditing() {
            if (this.isLoggedIn) {
                this.isEditing = true;
            }
        },
        save() {
            this.$store.dispatch('admin/setConfigText', {
                key: this.textKey,
                value: this.editableText
            });
            this.isEditing = false;
            this.$emit('saved', this.editableText);
        },
        cancel() {
            this.editableText = this.initialValue;
            this.isEditing = false;
        }
    }
};
</script>

<style scoped>
.text-container {}

.hover-editable:hover {
    cursor: pointer;
    background-color: #E9EAF7;
    border-radius: var(--border-radius-xl);
    padding: 1rem;
}

.edit-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.edit-input {
    flex: 1;
    margin-right: 0.5rem;
}

.action-button {
    padding: 0.4rem 0.8rem;
    margin-left: 0.5rem;
}

.placeholder-text {
    color: #ccc;
}
</style>
