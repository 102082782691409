<template>
    <div class="d-flex align-items-center justify-content-center message">
        <div class="gleam-wrapper">
            <SvgIcon name="barcode-scanner" width="10rem" class="barcode-icon" />
        </div>
        <div class="ml-3" style="text-align: left">
            <h3>Self Checkout</h3>
            <ol>
                <li>Scan the barcode on each item</li>
                <li>Tap <strong class="button">Pay</strong> to continue</li>

            </ol>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: {

    },
    methods: {
    },
    computed: {
    }
};
</script>

<style scoped>
.ml-3 {
    padding-left: 4.25rem;
}

ol {
    margin-top: 3rem;
}

ol li {
    font-size: 1.625rem;
    font-weight: 400;
    margin-bottom: 1.5rem;

}

strong.button {
    padding: 0.5rem 4.275rem;
    margin: 0rem 0.5rem;
    background: var(--brand-color);
    color: var(--primary-white);
    border-radius: var(--border-radius-xl);
}

.gleam-wrapper {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.gleam-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(75deg, transparent 25%, rgba(255, 255, 255, 0.5) 50%, transparent 75%);
    background-size: 200% 100%;
    animation: gleamAnimation 4s infinite linear;
}

@keyframes gleamAnimation {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

strong {
    color: var(--brand-color);
}

.message p {
    font-size: 2.5rem;
    font-weight: 400;
}

.barcode-icon {
    display: block;
    fill: var(--brand-color);
    margin-right: 5rem;
}
</style>
