<template>
  <div :class="[
    'modifier',
    'mod-hold',
    { selected: isSelected },
    { disabled: disabled },
  ]" @click="toggleNestedSelection">
    <div class="icon">
      <template v-if="modifierType === 'checkbox'">
        <svg v-if="isSelected" v-html="getSvg('checkboxOn')"></svg>
        <svg v-else v-html="getSvg('checkboxOff')"></svg>
      </template>
      <template v-else>
        <svg v-if="isSelected" v-html="getSvg('radioOn')"></svg>
        <svg v-else v-html="getSvg('radioOff')"></svg>
      </template>
    </div>
    <span class="name">
      {{ modifier.name }}
      <p v-if="modifier.description">{{ modifier.description }}</p>
      <span class="extra" v-if="modifier.price">({{ formattedPrice }})</span>
    </span>
  </div>
</template>

<script>
import { formatCurrency } from "@/utils/helpers";
import svgLib from "@/utils/svgLib";

export default {
  props: {
    modifier: Object,
    parentModifierRef: String,
    isSelected: Boolean,
    modSetRef: String,
    modifierType: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedGraph: Array,
    parentModifierSetRef: String,
  },
  computed: {
    formattedPrice() {
      return formatCurrency(this.modifier.price);
    },
  },
  methods: {
    getSvg(name) {
      return svgLib[name];
    },
    toggleNestedSelection() {
      if (this.modifierType === "radio") {
        this.$store.commit("orderItem/UNSET_NESTED_MODIFIERS_FOR_SET", {
          parentModifierSetRef: this.parentModifierSetRef,
          parentModifierRef: this.parentModifierRef,
          modifierSetRef: this.modSetRef,
        });
      }

      this.$store.commit("orderItem/TOGGLE_NESTED_MODIFIER", {
        parentModifierSetRef: this.parentModifierSetRef,
        parentModifierRef: this.parentModifierRef,
        nestedModifierSetRef: this.modSetRef,
        modifier: this.modifier,
      });
    },
  },
};
</script>

<style scoped>
.selected {
  font-weight: bold;
}
</style>