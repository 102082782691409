<template>
    <div id="receipt">
        <div v-if="loadingReceipt" class="text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else class="container">
            <header v-if="receiptHtml">
                <LocationLogo v-if="location.logo_url" style="width: 81px; height: 81px; margin: auto;"
                    :logo="location.logo_url" :shape="location.logo_shape || 'circle'" borderColor="#2A2927" />
                <br />
                <h3>{{ location.name }}</h3>
                <p>{{ text.receiptMessage }}</p>
            </header>

            <div v-if="receiptHtml">
                <div v-html="receiptHtml"></div>
            </div>

            <div v-else class="block">
                <p class="text-center strong">Receipt not found</p>
                <p class="text-center quiet">Please check with an employee to make sure they have your order.</p>
            </div>

            <div class="block" v-if="receiptHtml">
                <p class="text-center strong">{{ text.ratingPrompt }}</p>
                <div class="rating" :class="{ 'loading': loadingRating }">
                    <span v-for="star in 5" :key="star" class="star" @click="!loadingRating && submitRating(star)"
                        :class="{ 'rated': star <= currentRating }"></span>
                </div>
                <br />
                <p class="text-center quiet">Tap a star to rate.</p>
            </div>
            <footer v-if="receiptHtml"><span class="s4-logo"></span></footer>
        </div>
    </div>

</template>

<script>
import LocationLogo from '@/components/global/LocationLogo.vue';
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        LocationLogo,
    },
    data() {
        return {
            currentRating: 0,
        };
    },
    computed: {
        ...mapState('receipt', ['receipt', 'loadingReceipt', 'receiptError', 'loadingRating']),
        location() {
            return this.receipt ? this.receipt.location : {};
        },
        text() {
            return this.receipt ? this.receipt.text : {};
        },
        receiptHtml() {
            return this.receipt ? this.receipt.html : '';
        },
    },
    async created() {
       await this.fetchReceipt(this.$route.params.ref);
    },
    methods: {
        ...mapActions('receipt', ['fetchReceipt', 'submitReview']),
        async submitRating(rating) {
            const review = {
                rating,
            };
            const success = await this.submitReview({ orderRef: this.$route.params.ref, review });
            if (success) {
                this.currentRating = rating;
            } else {
                this.currentRating = 0;
            }
        },
    },
};
</script>
<style>
body {
    font-size: 16px !important;
}

#receipt {
    max-width: 40rem;
    margin: 0 auto;

}

#receipt header {
    align-items: center;
    text-align: center;
    margin: 1.5rem;
}

#receipt header img {
    width: 81px;
    height: 81px;
    margin-bottom: 0.5rem;
}

#receipt .block {
    padding: 1.125rem;
    margin-bottom: 0.75rem;

    background: #FFFFFF;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);
    border-radius: 0.75rem;
}

#receipt .block .customer-info,
#receipt .block .payment-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    color: #475467;
    font-size: 0.875rem;
}

#receipt .strong {
    font-weight: 700;
}

#receipt .quiet {
    font-weight: 100;
    font-size: 0.75rem;
    color: #6c757d;
}

#receipt ul.items {
    list-style: none;
    padding: 1rem;
    margin: 0;

}

#receipt ul.items li {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.5rem 0;
}

#receipt ul.items .quantity {
    flex: 0 0 3rem;
    text-align: left;
}

#receipt ul.items .name {
    flex: 1;
    font-weight: 700;
    margin-left: 1rem;
}

#receipt ul.items .choices {
    font-size: 0.875rem;
    font-weight: 100;
    color: #6c757d;
    margin: 0;
}

#receipt ul.items .price {
    flex: 0 0 4rem;
    text-align: right;
}

#receipt .totals {
    margin-top: 1rem;
    padding: 1rem;
    border-top: 1px solid #EAECF0;

}

#receipt .totals div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

#receipt .card-type {
    display: flex;
    gap: 0.5rem;
}


#receipt .card-badge {
    width: 32px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    background-image: url('@/assets/cards/other.svg');
}

#receipt .card-badge.ax {
    background-image: url('@/assets/cards/amex.svg');
}

#receipt .card-badge.ns {
    background-image: url('@/assets/cards/dsc.svg');
}

#receipt .card-badge.mc {
    background-image: url('@/assets/cards/mc.svg');
}

#receipt .card-badge.vs {
    background-image: url('@/assets/cards/vs.svg');
}

#receipt footer {
    margin: 2.5rem 0;
}

#receipt footer .s4-logo {
    margin: auto;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    height: 2.625rem;
    width: 6.857rem;
    background-image: url('@/assets/powered-by-s4.svg');
}

#receipt .rating {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}

#receipt .star {
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('@/assets/star.svg');
}

#receipt .star.rated {
    background-image: url('@/assets/star-filled.svg');
}

#receipt .rating.loading .star {
    opacity: 0.5;
    pointer-events: none;
}
</style>
