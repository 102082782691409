<template>
    <div class="list-group mt-2">
        <a v-for="(item, index) in items" :key="index"
            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            @click="$emit('edit', item, index)">
            {{ item.name }}
            <span>
                <span :class="{ 'badge bg-success': item.active, 'badge bg-secondary': !item.active }"
                    class="ms-2 rounded-pill">{{ item.active ? 'Active' : 'Inactive' }}</span>
                <button class="btn btn-danger btn-sm ms-2" @click.stop="$emit('delete', item.id)">Delete</button>
            </span>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        items: Array
    },
    emits: ['edit', 'delete']
}
</script>
<style scoped>
a {
    cursor: pointer;
}
</style>
