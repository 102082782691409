<template>
    <div class="phone-input" v-if="selectedCountry" :class="{ 'focused': isFocused }">
        <div class="country-selector" @click="showDropdown = !showDropdown">
            <Icon :icon="flag" width="1.5rem" height="1.5rem" alt="language flag" class="flag-icon" />
            <span>{{ selectedCountry.dialCode }}</span>
            <span class="arrow-down"></span>
        </div>
        <input ref="inputField" type="tel" v-model="displayNumber" @input="onInput" @focus="focusInput"
            @blur="blurInput" @keyup="handleKeyUp" :placeholder="selectedCountry.placeholder" autofocus />
        <div class="dropdown" v-if="showDropdown">
            <div v-for="country in countries" :key="country.code" @click="selectCountry(country)">
                <Icon :icon="getFlag(country)" width="1.5rem" height="1.5rem" alt="language flag" class="flag-icon" />
                <span>{{ country.name }} ({{ country.dialCode }})</span>
            </div>
        </div>
    </div>
</template>

<script>
import { Icon } from '@iconify/vue';
require('@iconify-json/circle-flags');
import { isValidPhoneNumber as parsePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

export default {
    name: 'PhoneInput',
    components: { Icon },
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Enter phone number'
        },
        selectedCountryCode: {
            type: String,
            default: 'us'
        }
    },
    data() {
        return {
            phoneNumber: '',
            displayNumber: '',
            showDropdown: false,
            selectedCountry: null,
            isFocused: false,
            countries: [
                { name: 'United States', dialCode: '+1', flag: 'us', code: 'US', placeholder: '555-123-4567' },
                { name: 'Canada', dialCode: '+1', flag: 'ca', code: 'CA', placeholder: '555-123-4567' },
                { name: 'United Kingdom', dialCode: '+44', flag: 'gb', code: 'GB', placeholder: '07123 456789' },
                { name: 'Australia', dialCode: '+61', flag: 'au', code: 'AU', placeholder: '0412 345 678' },
                { name: 'Germany', dialCode: '+49', flag: 'de', code: 'DE', placeholder: '01512 3456789' },
                { name: 'France', dialCode: '+33', flag: 'fr', code: 'FR', placeholder: '06 12 34 56 78' },
                { name: 'India', dialCode: '+91', flag: 'in', code: 'IN', placeholder: '098765 43210' },
                { name: 'China', dialCode: '+86', flag: 'cn', code: 'CN', placeholder: '138 0013 8000' },
                { name: 'Japan', dialCode: '+81', flag: 'jp', code: 'JP', placeholder: '090-1234-5678' }
            ]
        };
    },
    computed: {
        flag() {
            return `circle-flags:${this.selectedCountry.flag}`;
        }
    },
    watch: {
        value() {
            this.autoDetectCountry();
        }
    },
    methods: {
        getFlag(country) {
            return `circle-flags:${country.flag}`;
        },
        onInput(event) {
            const rawValue = event.target.value.replace(/\D/g, '');
            this.phoneNumber = rawValue;
            this.displayNumber = this.formatPhoneNumber(rawValue);
            this.validatePhoneNumber();
        },
        focusInput() {
            console.log('focusInput');
            this.$emit('focus', true);
            this.isFocused = true;
        },
        handleKeyUp(event) {
            if (event.key === 'Enter') {
                this.submitForm();
            }
        },
        blurInput() {
            //this.$emit('focus', false);
            this.isFocused = false;
        },
        autoDetectCountry() {
            const matchedCountry = this.countries.find(country => this.phoneNumber.startsWith(country.dialCode));
            if (matchedCountry) {
                this.selectedCountry = matchedCountry;
                console.log('Country auto-detected:', matchedCountry);
            }
        },
        selectCountry(country) {
            this.selectedCountry = country;
            this.phoneNumber = '';
            this.displayNumber = '';
            this.showDropdown = false;
            this.validatePhoneNumber(); // reset valid state
            this.$nextTick(() => {
                this.$refs?.inputField?.focus();
            });
        },
        formatPhoneNumber(phoneNumber) {
            if (!phoneNumber) return '';

            const fullNumber = `${this.selectedCountry.dialCode}${phoneNumber}`;
            try {
                const parsedNumber = parsePhoneNumberFromString(fullNumber);
                if (parsedNumber) {
                    return parsedNumber.formatNational();
                }
            } catch (e) {
                console.error('Error formatting phone number:', e);
            }

            return phoneNumber; // fallback to the raw input if formatting fails
        },
        validatePhoneNumber() {
            let isValid = false;
            const fullNumber = `${this.selectedCountry.dialCode}${this.phoneNumber}`;
            if (this.selectedCountry && this.phoneNumber) {
                try {
                    const phoneNumber = parsePhoneNumber(fullNumber, this.selectedCountry.code);
                    isValid = phoneNumber;
                } catch (e) {
                    isValid = false;
                }
            }

            this.$emit('valid', { isValid, val: `${this.selectedCountry.dialCode}${this.phoneNumber}` });
        },
        submitForm() {
            this.$emit('submit');
        }
    },
    mounted() {
        if (typeof this.value === 'string' && this.value) {
            // Auto-detect the country based on the dial code
            this.autoDetectCountry();

            // If no country is detected, set the default country
            if (!this.selectedCountry) {
                this.selectedCountry = this.countries.find(
                    country => country.code === this.selectedCountryCode.toUpperCase()
                );
            }

            // Strip the country code from the provided value if applicable
            if (this.selectedCountry) {
                const dialCode = this.selectedCountry.dialCode || '';
                if (this.value.startsWith(dialCode)) {
                    this.phoneNumber = this.value.replace(dialCode, '').trim(); // Remove the country code
                } else {
                    this.phoneNumber = this.value;
                }

                // Format the phone number without the dial code
                this.displayNumber = this.formatPhoneNumber(this.phoneNumber);
                this.validatePhoneNumber();
            } else {
                // Fallback to the full value if no country is selected
                this.phoneNumber = this.value;
                this.displayNumber = this.phoneNumber;
            }
        } else {
            // Set the default country if no initial value
            this.selectedCountry = this.countries.find(
                country => country.code === this.selectedCountryCode.toUpperCase()
            );
        }

        this.$nextTick(() => {
            this.$refs?.inputField?.focus();
        });
    }
};
</script>

<style scoped>
.phone-input {
    display: flex;
    align-items: center;
    position: relative;
    border: 0.25rem solid var(--grey-focus);
    border-radius: var(--border-radius-sm);
}

input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.country-selector {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--primary-black);
    background: var(--primary-white);
    border: none;
    border-right: 0.25rem solid var(--grey-focus);
    padding: 0.75rem 1.375rem 0.5rem 1.375rem;
    border-top-left-radius: var(--border-radius-sm);
    border-bottom-left-radius: var(--border-radius-sm);
    font-weight: 700;
    font-size: 2.125rem !important;
    position: relative;
}

.country-selector:hover,
.country-selector:focus {
    border-color: var(--brand-color) !important;
}

.arrow-down {
    margin-left: 0.5rem;
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid var(--primary-black);
}

#app input[type="tel"] {
    flex: 1;
    color: var(--primary-black);
    background: var(--primary-white);
    border: none;
    border-left: none;
    padding: 0.75rem 1.375rem 0.5rem 1.375rem;
    font-weight: 700;
    font-size: 2.125rem !important;
}

#app input[type="tel"]:focus,
#app input[type="tel"]:active {
    border: none !important;
    outline: none !important;
}


.focused {
    border: 0.25rem solid var(--brand-color) !important;

}

.focused .country-selector {
    border-color: var(--brand-color) !important;
}


.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 0.125rem solid #ccc;
    max-height: 20rem;
    overflow-y: auto;
    z-index: 1000;
    width: 100%;
    border-radius: var(--border-radius-sm);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown div {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.375rem;
    cursor: pointer;
    font-size: 1.5rem;
}

.dropdown div:hover {
    background: #f0f0f0;
}

.flag-icon {
    margin-right: 0.5rem;
}
</style>
