<template>
  <div class="order-overview-layout">
    <div class="order-items">
      <CouponItem :coupon="coupon" :discounts="discounts" @remove="removeCoupon" />
      <div v-if="items.length === 0" class="empty-order d-flex">
        <div class="empty-message">
          <SelfCheckout v-if="view === 'retail'" />
          <template v-else>
            <div>
              <SvgIcon name="empty-order" v-if="useIcons" />
            </div>
            <p>{{ emptyCartMessage }}</p>
          </template>
        </div>
      </div>
      <div class="items" :class="{ retail: view === 'retail' }" v-else>
        <div
          class="items-shadow-top"
          v-show="isScrolled"
          :style="{ height: shadowHeight + 'px' }"
        ></div>
        <OrderItem
          v-for="(item, index) in items"
          :key="index"
          :order-item="item"
          :item-index="index"
          :showQuantityEdit="showQuantityEdit"
          :showItemDetails="showItemDetails"
          @edit="editItem"
          @remove="removeItem(index)"
        />
      </div>
    </div>
    <div class="checkout-controls">
      <div class="button-group">
        <button class="btn btn-md btn-outline-primary" @click="startOver">
          <SvgIcon v-if="useIcons" name="refresh" class="refresh" width="2rem" height="2rem" /> {{ $t('startOverButtonText') }}
        </button>
        <button class="btn btn-md btn-outline-primary" v-if="hasCoupons" @click="applyCoupon" :disabled="coupon">
          <SvgIcon v-if="useIcons" name="coupon" width="1.5rem" height="1.5rem" /> {{  $t('couponCode') }}
        </button>
      </div>
      <button
        class="btn btn-lg btn-primary d-block w-100 main-checkout-button"
        @click="checkout"
        :disabled="!items.length || !paymentTypes.length"
      >
        {{ buttonText }}
        <span v-if="subtotal > 0">{{ formatCurrency(subtotal) }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils/helpers';
import CouponItem from '@/components/features/order/CouponItem.vue';
import OrderItem from '@/components/features/order/OrderItem.vue';
import SelfCheckout from '@/components/features/selfCheckout/SelfCheckout.vue';

export default {
  props: {
    coupon: {
      type: Object,
      default: () => null,
    },
    discounts: {
      type: Number,
      default: 0,
    },
    paymentTypes: {
      type: Array,
      default: () => [],
    },
    view: {
      type: String,
      default: 'restaurant',
    },
    nextButtonText: {
      type: String,
      default: 'Checkout',
    },
    useIcons: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    emptyCartMessage: {
      type: String,
      default: "",
    },
    subtotal: {
      type: Number,
      default: 0,
    },
    taxTotal: {
      type: Number,
      default: 0,
    },
    showQuantityEdit: {
      type: Boolean,
      default: true,
    },
    showItemDetails: {
      type: Boolean,
      default: false,
    },
    hasCoupons: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['removed', 'checkout', 'edit', 'reset', 'applyCoupon', 'removeCoupon'],
  data() {
    return {
      isScrolled: false,
      shadowHeight: 0,
    };
  },
  methods: {
    formatCurrency,
    editItem(item) {
      this.$emit('edit', item);
    },
    checkout() {
      this.$emit('checkout');
    },
    removeItem(index) {
      this.$emit('removed', index);
    },
    startOver() {
      this.$emit('reset');
    },
    applyCoupon() {
      this.$emit('applyCoupon');
    },
    removeCoupon() {
      this.$emit('removeCoupon');
    },
    handleScroll(event) {
      const scrollTop = event.target.scrollTop;
      this.isScrolled = scrollTop > 0;
      // Increase the rate of height growth and maximum height
      this.shadowHeight = Math.min(scrollTop / 2, 40); // Adjust divisor and max height for a stronger effect
    },
  },
  computed: {
    buttonText() {
      if (!this.paymentTypes.length) {
        return 'No Payment Types Configured';
      } else {
        return this.nextButtonText;
      }
    },
    itemCount() {
      return this.items.length;
    },
  },
  watch: {
    itemCount(newCount, oldCount) {
      console.log('itemCount', newCount, oldCount);
      if (newCount > 0 && oldCount === 0) {
        this.$nextTick(() => {
          const itemsDiv = this.$el.querySelector('.items');
          itemsDiv.addEventListener('scroll', this.handleScroll);
        });
      } else if (newCount === 0 && oldCount > 0) {
        const itemsDiv = this.$el.querySelector('.items');
        if (itemsDiv) {
          itemsDiv.removeEventListener('scroll', this.handleScroll);
        }
      }
    },
  },
  mounted() {
    if (this.itemCount > 0) {
      const itemsDiv = this.$el.querySelector('.items');
      if (itemsDiv) {
        itemsDiv.addEventListener('scroll', this.handleScroll);
      }
    }
  },
  beforeUnmount() {
    const itemsDiv = this.$el.querySelector('.items');
    if (itemsDiv) {
      itemsDiv.removeEventListener('scroll', this.handleScroll);
    }
  },
  components: {
    OrderItem,
    CouponItem,
    SelfCheckout
  },
};
</script>

<style scoped>
.empty-order {
  height: calc(100vh - 16.5rem);
  align-items: center;
  justify-content: center;
  text-align: center;
}

.btn-lg {
  font-size: 2.125rem;
}

.empty-message p {
  font-size: 1.375rem;
  font-weight: 400;
}

.empty-message .svg-icon {
  width: 10rem !important;
  height: 10rem !important;
  margin: auto;
}

.items {
  height: calc(100vh - 20rem);
  overflow-y: auto;
}


.checkout-controls {
  padding: 1.5rem;
  position: sticky;
  bottom: 0;
  background-color: var(--grey-50)
}

.pricing-info {
  margin-bottom: 1rem;
  font-size: 1.25rem !important;
}

.line-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.line-item.total {
  font-weight: bold;
  font-size: 1.75rem;
  border-top: 1px solid var(--grey-outline);
  padding: 1.5rem 0rem 1.5rem 0rem;
}

.btn-primary {
  font-size: 2.25rem;
  padding: 1.5rem !important;
  line-height: 2.875rem;
}

.vertical .order-overview-layout {
  display: flex;
  flex-direction: row;
  height: 25rem;
  width: 100%;
  overflow-y: hidden !important;
}

.vertical .items {
  height: auto;
}

.vertical .order-items {
  width: 60vw;
  overflow-y: scroll;
}


.vertical .empty-order {
  height: auto;
}

.vertical .checkout-controls {
  flex-grow: 1;
  /* Takes the remaining space */
  overflow-y: auto;
  width: 40vw;
  padding: 2.215rem 1.5rem;
  border-left: 1px solid var(--grey-outline);
}

.items.retail {
  display: grid;
  grid-gap: 1rem;
  margin: 1rem;
  margin-top: 5.8rem;
  grid-template-columns: repeat(3, 1fr);
}

.button-group {
  display: flex;
  font-size: 1.5rem !important;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.button-group .btn-md {
  height: 4.6125rem;
  font-size: 1.5rem !important;
  padding: 0.5rem 2rem !important;
  font-weight: 700;
  line-height: 2rem;
  border-width: 0.125rem;
  border-radius: 10rem !important;
  margin-right: 1rem;
}

.button-group .btn-md:last-child {
  margin-right: 0;
}


.button-group .refresh {
  transform: scaleX(-1);
  position: relative;
  top: -0.1rem;
}

.coupon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  background: var(--primary-color-light);
  color: var(--primary-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.coupon svg {
  flex: 0 0 1.5rem;
}

.coupon span:first-of-type {
  flex: 0 0 6rem;
}

.coupon span:last-of-type {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.items-shadow-top {
  position: sticky;
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), transparent); /* Increase opacity for more visibility */
  z-index: 1000;
  transition: height 0.1s ease-in-out; /* Slightly longer transition for smoother effect */
}

</style>
