<template>
    <div v-if="showQuantity" class="quantity-selector" :style="{ '--base-font-size': baseFontSize + 'px' }">
        <button class="minus-button" @click="decrement" :disabled="value <= minQuantity" aria-label="Decrease quantity">
            <SvgIcon name="minus" aria-hidden="true" />
        </button>
        <span
            v-if="!isEditing"
            :class="['quantity', quantityAnimationClass]"
            role="status"
            aria-live="polite"
            @click="editQuantity"
        >
            {{ value }}
        </span>
        <input
            v-else
            type="number"
            ref="quantityInput"
            class="quantity-input"
            :min="minQuantity"
            v-model.number="inputValue"
            @blur="onQuantityInputBlur"
            @keyup.enter="onEnterKey"
        />
        <button class="plus-button" @click="increment" aria-label="Increase quantity">
            <SvgIcon name="plus" aria-hidden="true" />
        </button>
    </div>
</template>

<script>
import svgLib from '@/utils/svgLib';
export default {
    name: 'QuantitySelector',
    props: {
        value: {
            type: Number,
            default: 1
        },
        baseFontSize: {
            type: Number,
            default: 16
        },
        showQuantity: {
            type: Boolean,
            default: true
        }
    },
    emits: ['quantity'],
    data() {
        return {
            lastQuantity: this.value,
            minQuantity: 1,
            svgLib,
            isEditing: false,
            inputValue: this.value
        };
    },
    methods: {
        increment() {
            this.$emit('quantity', this.value + 1);
        },
        decrement() {
            if (this.value > this.minQuantity) {
                this.$emit('quantity', this.value - 1);
            }
        },
        editQuantity() {
            this.inputValue = this.value;
            this.isEditing = true;
            this.$nextTick(() => {
                this.$refs.quantityInput.focus();
                this.$refs.quantityInput.select();
            });
        },
        onQuantityInputBlur() {
            const newValue = Number(this.inputValue);
            if (!isNaN(newValue) && newValue >= this.minQuantity) {
                this.$emit('quantity', newValue);
            }
            this.isEditing = false;
        },
        onEnterKey() {
            this.$refs.quantityInput.blur();
        }
    },
    computed: {
        quantityAnimationClass() {
            return this.value > this.lastQuantity
                ? 'animate__animated animate__fadeInUp'
                : this.value < this.lastQuantity
                ? 'animate__animated animate__fadeInDown'
                : '';
        }
    },
    watch: {
        value(newValue) {
            this.lastQuantity = newValue;
        }
    }
};
</script>


<style scoped>
.quantity-selector {
    display: flex;
    align-items: center;
    border: 0.0625rem solid var(--grey-outline);
    display: inline-flex;
    border-radius: var(--border-radius-xxl);
}

.minus-button,
.plus-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5em;
    background: none;
}

.svg-icon {
    border: none;
}

svg {
    width: 100%;
    height: auto;
    fill: #000 !important;
}

.minus-button:disabled,
.plus-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.quantity {
    display: inline-block;
    position: relative;
    left: 0.10em;
    top: 0em;
    font-weight: 400;
    font-size: 1.25em;
    animation-duration: 0.2s;
}

.quantity-input {
    width: 2em;
    font-weight: 400;
    font-size: 1.25em;
    text-align: center;
    border: none;
    background: none;
    outline: none;
}
</style>
