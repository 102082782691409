const axios = require("axios");
const config = require("../../config");
import store from '@/store';

const baseHost = config.API_PORT ? `${config.API_HOST}:${config.API_PORT}` : config.API_HOST;
const protocol = config.API_HOST === "localhost" ? "http" : "https";
const baseURL = `${protocol}://${baseHost}/`;
const { v4: uuidv4 } = require("uuid");
import logger from "@/utils/logger";

const generateCorrelationId = () => uuidv4();

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use((config) => {
  const correlationId = generateCorrelationId();
  config.headers['X-Correlation-ID'] = correlationId;

  if (!config.metadata) {
    config.metadata = { correlationId };
  }
  config.metadata.requestStart = Date.now();
  config.metadata.method = config.method.toUpperCase();
  config.metadata.url = config.url;

  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
  const { method, url, requestStart, correlationId } = response.config.metadata;
  const duration = Date.now() - requestStart;
  logger.info(`[Kiosk Client] ${method} ${url} [${response.status}] (${response.statusText}) in ${duration}ms [Ref: ${correlationId}]`);

  return response;
}, (error) => {
  const { method, url, requestStart, correlationId } = error.config.metadata;
  const duration = Date.now() - requestStart;
  const status = error.response ? `[${error.response.status}] (${error.response.statusText})` : "No Response";
  logger.error(`[Kiosk Client] ${method} ${url} ${status} in ${duration}ms [Ref: ${correlationId}]`);

  return Promise.reject(error);
});


axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      const requestUrl = error.config.url;
      if (requestUrl.includes('/admin/') || requestUrl.includes('/auth/')) {
        console.log('Handling 401 error for admin routes');
        // Handle 401 error for admin routes
        store.dispatch('admin/handleUnauthorized', 'admin/dashboard');
      }
    }
    return Promise.reject(error);
  }
);

const api = {
  async callAPI(method, url, data, token) {
    try {
      if (token) {
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
      const response = await axiosInstance({
        method,
        url,
        data,
      });

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

export default api;
