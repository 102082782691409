<template>
    <div v-if="isOpen" class="modal-overlay" @click.self="close" :class="{ keyboard: isFocused && isMobile }">
        <div class="modal-window" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-desc">
            <div class="modal-header">
                <h2 id="modal-title">{{ title }}</h2>
                <button @click="close" class="close-btn" aria-label="Close">
                    <SvgIcon name="close" width="3.5rem" height="3.5rem" />
                </button>
            </div>
            <div class="modal-body">
                <p id="modal-desc">{{ instructions }}</p>
                <input ref="textInput" autocomplete="off" v-model="uppercasedText" class="text-input" type="text" pattern="[a-zA-Z0-9]*"
                :maxlength="charLimit"
                    autocapitalize="characters"
                    @focus="isFocused = true" @blur="isFocused = false" @input="handleInput" @keyup.enter="apply"
                    aria-required="true" />
                <div v-if="errorMessage" class="error-message">
                    <SvgIcon name="error" class="error-icon" width="2rem" height="2rem" />
                    {{ errorMessage }}
                </div>
                <p v-if="charLimitMessage">{{ charLimitMessage }}</p>
            </div>
            <div class="modal-footer">
                <button @click="apply" class="btn btn-lg btn-primary d-block w-100" :disabled="loading">
                    <i v-if="!loading" class="bi bi-save"></i>
                    <i v-else class="spinner-border spinner-border-sm"></i>
                    {{ buttonText }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'GetText',
    props: {
        title: {
            type: String,
            required: true,
        },
        instructions: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            required: false,
            default: null
        },
        isOpen: {
            type: Boolean,
            required: true,
        },
        validate: {
            type: Function,
            required: false,
        },
        charLimit: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            text: '',
            loading: false,
            errorMessage: '',
            isFocused: false
        };
    },
    computed: {
        ...mapGetters(["isMobile"]),
        buttonTextUse: {
            get() {
                return this.buttonText || this.$t('submitButtonText');
            }
        },
        uppercasedText: {
            get() {
                return this.text.toUpperCase();
            },
            set(value) {
                this.text = value.toUpperCase();
            }
        },
        charLimitMessage() {
            return this.charLimit ? `${this.text.length}/${this.charLimit}` : '';
        }
    },
    watch: {
        isOpen(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    requestAnimationFrame(() => {
                        this.$refs.textInput.focus();
                    });
                });
            }
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async apply() {
            if (this.loading) return; // Prevent multiple submissions
            this.loading = true;
            this.$refs.textInput.focus(); // Refocus to prevent keyboard from closing
            if (this.validate) {
                try {
                    const { success, message } = await this.validate(this.text);
                    if (success) {
                        this.close();
                        this.$emit('apply', this.text);
                    } else {
                        this.errorMessage = message;
                        this.$nextTick(() => {
                            this.$refs.textInput.focus();
                        });
                    }
                } catch (error) {
                    console.error(error);
                    this.errorMessage = "An unexpected error occurred.";
                    this.$nextTick(() => {
                        this.$refs.textInput.focus();
                    });
                } finally {
                    this.loading = false;
                }
            } else {
                this.$emit('apply', this.text);
                this.close();
                this.loading = false;
            }
        },
        handleInput(event) {
            // Allow alphanumeric characters and spaces
            this.text = event.target.value.replace(/[^a-zA-Z0-9\s]/g, '').toUpperCase();
        },
        handleKeyDown(event) {
            if (event.key === 'Escape') {
                this.close();
            }
        }
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeyDown);
        if (this.isOpen) {
            this.$nextTick(() => {
                requestAnimationFrame(() => {
                    this.$refs.textInput.focus();
                });
            });
        }
    },
    unmounted() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: var(--z-index-8-overlay);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-window {
    background: var(--primary-white);
    z-index: var(--z-index-8);
    width: 50%;
    max-width: 600px;
    padding: 0rem;
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow);
    max-height: 80vh;
    overflow-y: auto;
    height: auto;
    display: flex;
    flex-direction: column;
}

.modal-overlay.keyboard .modal-window {
    margin-bottom: 40vh;
}

input {
    text-transform: uppercase;
}

.modal-header {
    margin: 2rem 2.5rem !important;
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-body {
    margin: 0rem 2.5rem !important;
    margin-bottom: 3.125rem !important;
}

.modal-body p {
    font-size: 1.25rem;
    margin: 0;
    padding: 0;
}

.modal-header h2 {
    font-size: 2.125rem;
    margin: 0;
}

.close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-body {
    margin-top: 1rem;
}

.text-input {
    width: 100%;
    margin-top: 0.5rem;
}

.error-message {
    color: var(--status-error);
    margin-top: 0.5rem;
}

.modal-footer {
    display: flex;
    justify-content: center;
    padding: 1.25rem 1.5rem;
    border-top: 0.125rem solid var(--grey-outline);
}

.apply-btn {
    padding: 0.5rem 1rem;
    background: var(--primary-color);
    color: var(--primary-white);
    border: none;
    border-radius: var(--border-radius-xs);
    cursor: pointer;
}

.apply-btn:hover {
    background: var(--primary-color-darker);
}

.spinner-border {
    width: 1rem;
    height: 1rem;
    border-width: 2px;
}

.error-message {
    color: var(--status-error);
    background-color: #ffebee;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    font-size: 2rem !important;
    margin-top: 1rem;
}

.error-icon {
    margin-right: 0.5rem;
    position: relative;
    top: -0.6rem;
}
</style>
