<!-- components/LoginComponent.vue -->
<template>
  <div class="d-flex align-items-center justify-content-center vh-100 main">
    <div class="login-container p-4 shadow-sm rounded-3">
      <button @click="initiateOAuth" class="btn btn-primary btn-lg w-100 mb-4 d-flex justify-content-center">
        <SvgIcon name="four" class="icon" width="1rem" height="1rem" /> 
        <span>Login with Shift4</span>
      </button>
      <a href="#" @click.prevent="toggleEmailLogin" class="small-link">Login with Email/Password</a>
      <form v-if="showEmailLogin" @submit.prevent="login" class="mt-4">
        <div class="form-floating mb-3">
          <input type="email" class="form-control" id="email" placeholder="Email" v-model="email" required ref="emailInput">
          <label for="email">Email</label>
        </div>
        <div class="form-floating mb-3">
          <input type="password" class="form-control" id="password" placeholder="Password" v-model="password" required>
          <label for="password">Password</label>
        </div>
        <button type="submit" class="btn btn-primary btn-lg w-100">Login</button>
      </form>
      <p v-if="error" class="error mt-3 text-center">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      error: '',
      showEmailLogin: false
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('triggerOAuth')) {
      this.initiateOAuth();
    }
  },
  methods: {
    ...mapActions("admin", ['initiateOAuth']),
    toggleEmailLogin() {
      this.showEmailLogin = !this.showEmailLogin;
      if (this.showEmailLogin) {
        this.$nextTick(() => {
          this.$refs.emailInput.focus();
        });
      }
    },
    async login() {
      try {
        const credentials = { email: this.email, password: this.password };
        await this.$store.dispatch('admin/login', credentials);

        if (this.$store.getters['admin/isLoggedIn']) {
          const redirectPath = this.$route.query.redirect || '/admin';
          this.$router.replace(redirectPath);
        } else {
          this.error = 'Invalid email or password';
        }
      } catch (error) {
        this.error = 'An error occurred during login';
      }
    }
  }
};
</script>

<style scoped>
.main {
  font-size: 1rem;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.login-container {
  background-color: #fff;
  max-width: 28rem;
  width: 100%;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.error {
  color: var(--status-error);
  font-size: 1.25rem;
  font-weight: 600;
}

.small-link {
  display: block;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.875rem;
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}

.small-link:hover {
  text-decoration: none;
  color: var(--primary-dark);
}

.icon {
  margin-right: 0.75rem;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover {
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
}

.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:hover {
  background-color: var(--secondary-dark);
  border-color: var(--secondary-dark);
}

.form-floating label {
  color: #6c757d;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--primary);
}

input::placeholder {
  color: transparent;
}

input:focus::placeholder {
  color: #6c757d;
}
</style>