<template>
  <div class="timeline-container" ref="timelineContainer">
    <svg :viewBox="`0 0 ${width} ${height}`" preserveAspectRatio="xMinYMin meet" style="width: 100%; height: 100%;">
      <line x1="0" :y1="height / 2" :x2="width" :y2="height / 2" stroke="gray" stroke-width="2" />
      <circle
        v-for="order in ordersTimeline"
        :key="order.app_ref"
        :cx="getXPosition(order.created_at)"
        :cy="height / 2"
        :r="getRadius(order.total_in_pennies)"
        fill="blue"
        cursor="pointer"
        :stroke="order === selectedOrder ? 'black' : 'none'"
        :stroke-width="order === selectedOrder ? 2 : 0"
        @mouseover="showTooltip(order, $event)"
        @mouseleave="hideTooltip"
        @click="viewOrder(order.app_ref)"
      />
    </svg>
    <div v-if="tooltipVisible" class="tooltip" :style="{ top: `${tooltipY}px`, left: `${tooltipX}px` }">
      <div><strong>Total:</strong> ${{ (tooltipOrder.total_in_pennies / 100).toFixed(2) }}</div>
      <div><strong>Time:</strong> {{ new Date(tooltipOrder.created_at).toLocaleTimeString() }}</div>
    </div>
  </div>

  <!-- Modal Tray -->
  <div class="modal-overlay" v-if="isModalOpen"></div>
  <div
    class="modal-tray"
    :class="{ 'open': isModalOpen }"
    @click.self="closeModal"
  >
    <div class="modal-content">
      <button class="close-button" @click="closeModal">×</button>
      <iframe :src="iframeUrl" frameborder="0"></iframe>
      <div class="navigation-buttons">
        <button class="circle-button" :disabled="!canGoBack" @click="goBack">
          <SvgIcon width="1.5rem" height="1.5rem" name="back" style="margin-top: 0.22rem;" />
        </button>
        <button class="circle-button" :disabled="!canGoForward" @click="goForward">
          <SvgIcon width="1.5rem" height="1.5rem" name="forward" />
        </button>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  props: {
    periodStartDateTime: String,
    periodEndDateTime: String,
    ordersTimeline: Array,
  },
  data() {
    return {
      width: 800,
      height: 100,
      selectedOrder: null,
      isModalOpen: false,
      iframeUrl: '',
      tooltipVisible: false,
      tooltipOrder: null,
      tooltipX: 0,
      tooltipY: 0,
    };
  },
  computed: {
    canGoBack() {
      return this.selectedOrderIndex > 0;
    },
    canGoForward() {
      return this.selectedOrderIndex < this.ordersTimeline.length - 1;
    },
    selectedOrderIndex() {
      return this.ordersTimeline.findIndex(order => order === this.selectedOrder);
    },
  },
  methods: {
    getXPosition(createdAt) {
      if (!this.ordersTimeline.length) return 0;

      const padding = 10;
      const start = Math.min(
        ...this.ordersTimeline.map((order) =>
          new Date(order.created_at).getTime()
        )
      );
      const end = Math.max(
        ...this.ordersTimeline.map((order) =>
          new Date(order.created_at).getTime()
        )
      );
      const orderTime = new Date(createdAt).getTime();
      const position = ((orderTime - start) / (end - start)) * (this.width - 2 * padding);
      return position + padding;
    },
    getRadius(totalInPennies) {
      const maxTotal = Math.max(
        ...this.ordersTimeline.map((order) => order.total_in_pennies)
      );
      const radius = (totalInPennies / maxTotal) * (this.height / 4);
      return Math.max(radius, 5);
    },
    selectOrder(order) {
      this.selectedOrder = order;
    },
    viewOrder(appRef) {
      this.iframeUrl = `/receipt/${appRef}`;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.iframeUrl = '';
    },
    goBack() {
      if (this.canGoBack) {
        this.selectedOrder = this.ordersTimeline[this.selectedOrderIndex - 1];
        this.viewOrder(this.selectedOrder.app_ref);
      }
    },
    goForward() {
      if (this.canGoForward) {
        this.selectedOrder = this.ordersTimeline[this.selectedOrderIndex + 1];
        this.viewOrder(this.selectedOrder.app_ref);
      }
    },
    getOrderDetailsPosition() {
      if (!this.selectedOrder) return 0;

      const orderXPosition = this.getXPosition(this.selectedOrder.created_at);
      
      // use refs for card
      const orderDetailsCard = this.$refs.orderDetailsCard;
      const cardWidth = orderDetailsCard ? orderDetailsCard.offsetWidth : 450;

      // Get the actual width of the timeline container

      // use refs for container
      const timelineContainer = this.$refs.timelineContainer;
      const containerWidth = timelineContainer ? timelineContainer.offsetWidth : this.width;

      // Calculate the position to ensure the card doesn't go out of bounds
      let position = orderXPosition - cardWidth / 2;
      if (position < 0) position = 0;
      if (position + cardWidth > containerWidth) position = containerWidth - cardWidth;

      return position;
    },
    showTooltip(order, event) {
      this.tooltipOrder = order;
      this.tooltipX = event.clientX + 10; // Offset for better visibility
      this.tooltipY = event.clientY + 10;
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
  },
};
</script>

<style scoped>
.timeline-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  z-index: 10;
}

.order-details-card {
  position: absolute;
  top: 120px; /* Adjust as needed */
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 450px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Remove margin and center horizontally */
  margin: 0;
}

.order-details-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.order-id {
  font-size: 1rem;
  color: #555;
}

.view-link {
  color: #007bff;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.view-link:hover {
  color: #0056b3;
}

.order-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-total, .order-time {
  font-size: 1.1rem;
  color: #333;
}

.order-total strong, .order-time strong {
  font-weight: 700;
}

.modal-tray {
  position: fixed;
  top: 0;
  right: -40%; /* Width of the modal */
  width: 40%;
  height: 100%;
  background-color: #fff;
  box-shadow: var(--box-shadow) !important;
  overflow: auto;
  transition: right 0.3s ease-in-out;
  z-index: 10001;
}

.modal-tray.open {
  right: 0;
}

.modal-content {
  position: relative;
  height: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.navigation-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.circle-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.circle-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.circle-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.circle-button:hover:disabled {
  background-color: transparent;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 10000;
}
</style>
