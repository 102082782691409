
export default {
  namespaced: true,
  state: {
    visible: false,
    mainIcon: "error",
    title: "Error",
    message: "Error has occurred",
    action: {
      title: "Back",
      icon: "back",
      callback: null,
    },
  },
  getters: {
    visible: (state) => state.visible,
    mainIcon: (state) => state.mainIcon,
    title: (state) => state.title,
    message: (state) => state.message,
    action: (state) => state.action,
  },
  mutations: {
    SET_VISIBLE(state, visible) {
      state.visible = visible;
    },
    RESET(state) {
      state.visible = false;
      state.mainIcon = "error";
      state.title = "Error";
      state.message = null;
      state.action = { title: null, icon: null, callback: null };
    },
    SET_ERROR(state, { title, message, action }) {
      state.visible = true;
      state.title = title || "Error";
      state.message = message;
      state.action = action || { title: null, icon: null, callback: null };
    },
    SET_MAIN_ICON(state, icon) {
      state.mainIcon = icon;
    },
  },
  actions: {
    reportError({ commit }, { title, message, action }) {
      commit("SET_ERROR", { title, message, action });
    },
    resetError({ commit }) {
      commit("RESET");
    },
    setMainIcon({ commit }, icon) {
      commit("SET_MAIN_ICON", icon);
    },
  },
};
