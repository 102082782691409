// plugins/translatorPlugin.js

import { reactive, watch } from 'vue';
import Translator from '../utils/translation';
import store from '@/store'; // Import the store

const createTranslatorPlugin = (options) => {
  const translator = reactive(new Translator(store.state.currentLanguage, options.translations, {}, options.configTexts));

  // Watch for language changes in the store
  watch(() => store.state.currentLanguage, (newLanguage) => {
    translator.setLanguage(newLanguage);
  });

  return {
    install(app) {
      app.config.globalProperties.$t = (key) => translator.translate(key);
      app.config.globalProperties.$setLanguage = (language) => {
        store.dispatch('setLanguage', language);
        translator.setLanguage(language);
      };
      app.config.globalProperties.$setConfigTexts = (configTexts) => translator.setConfigTexts(configTexts);
      app.config.globalProperties.$addTranslations = (language, translations) => translator.addTranslations(language, translations);
      app.config.globalProperties.$verifyTranslations = () => translator.verifyTranslations();

      app.provide('translator', translator);
    }
  };
};

export default createTranslatorPlugin;
