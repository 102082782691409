<template>
  <div class="payment-summary">
    <hr class="summary-divider" />
    <div class="summary-item" v-for="(value, label) in summaryDetails" :key="label">
      <span>{{ label }}</span>
      <span>{{ formatCurrency(value) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentSummary',
  props: {
    gpattTicket: {
      type: Object,
      required: true,
    },
    tip: {
      type: Number,
      required: false,
    },
  },
  computed: {
    summaryDetails() {
      const details = {};
      const fields = [
        { label: 'Subtotal', value: this.gpattTicket.subtotal },
        { label: 'Discounts', value: this.gpattTicket.discountTotal*-1 },
        { label: 'Tip', value: (this.tip/100) || 0 },
        { label: 'Surcharge', value: this.gpattTicket.surchargeTotal },
        { label: 'Taxes', value: this.gpattTicket.taxTotal },
        { label: 'Auto Gratuity', value: this.gpattTicket.gratuityTotal },
        
      ];

      // Add each field to details if its value is not zero
      fields.forEach(field => {
        const amount = parseFloat(field.value);
        if (amount !== 0) {
          details[field.label] = amount;
        }
      });

      // Add Supplemental Fee if present and not zero
      if (this.gpattTicket.supplementalFee) {
        const supplementalAmount = parseFloat(this.gpattTicket.supplementalFee.amount);
        if (supplementalAmount !== 0) {
          details[this.gpattTicket.supplementalFee.name] = supplementalAmount;
        }
      }

      return details;
    },
  },
  methods: {
    formatCurrency(value) {
      const formattedValue = `$${Math.abs(value).toFixed(2)}`;
      return value < 0 ? `-${formattedValue}` : formattedValue;
    },
  },
};
</script>

<style scoped>
.payment-summary {
  margin-top: 1.25rem;
  border-top: 1px solid var(--grey-outline);
  font-size: 1.625rem;
  color: var(--grey-medium-emphasis) !important;
  width: 37rem !important;
}

.summary-divider {
  margin: 0;
  padding: 0;
  padding-top: 1.625rem;
  border-top: 1px solid var(--grey-outline);
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
</style>
