
import api from "@/utils/api";
import { saveData, loadData } from "@/utils/storageHelper";
import logger from "@/utils/logger";

const cust = loadData("customer");
const state = () => ({
    name: cust ? cust.name : null,
    phone: cust ? cust.phone : null,
    email: cust ? cust.email : null,
    lastOrderedItems: cust ? cust.lastOrderedItems : [],
});

const getters = {
    name: (state) => state.name,
    phone: (state) => state.phone,
    lastOrderedItems: (state) => state.lastOrderedItems,
    availableLastOrderedItems: (state) => {
        return state.lastOrderedItems;
    },
};

const actions = {
    async fetchCustomer({ commit, rootState, dispatch }, { phoneNumber }) {
        try {
            const response = await api.callAPI("GET", `v2/customer/${phoneNumber}`, null, rootState.terminal.apiToken);
            if (response) {
                response.phone = phoneNumber;
                commit("SET_CUSTOMER", {
                    name: response.name,
                    phone: phoneNumber,
                    email: response.email,
                    lastOrderedItems: response.lastOrderedItems || [],
                });
                dispatch('order/updateCustomerDetails', response, { root: true });
                return Promise.resolve();  // Ensure the promise resolves after customer details are fetched
            } else {
                commit("CLEAR_CUSTOMER");
                return Promise.resolve();  // Return resolve even if no customer is found
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                logger.info(`Customer not found for phone number: ${phoneNumber}`);
                commit("CLEAR_CUSTOMER");
                return Promise.resolve();  // Resolve the promise for 404 error
            } else {
                logger.error("Error fetching customer details", error);
                commit("CLEAR_CUSTOMER");
                return Promise.reject(error);  // Ensure the error is thrown to propagate the rejection
            }
        }
    },
    clearCustomer({ commit }) {
        commit("CLEAR_CUSTOMER");
    },
};

const mutations = {
    SET_CUSTOMER(state, { name, email, phone, lastOrderedItems }) {
        state.name = name;
        state.phone = phone;
        state.email = email;
        state.lastOrderedItems = lastOrderedItems;
        saveData("customer", { name, phone, lastOrderedItems });
    },
    CLEAR_CUSTOMER(state) {
        state.name = null;
        state.phone = null;
        state.lastOrderedItems = [];
        saveData("customer", null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
