<template>
    <div class="list-group mt-2">
        <a v-for="(orderType, index) in orderTypes" :key="index"
            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            @click="$emit('edit', orderType, index)">
            {{ orderType.name }}
            <span>
                <span class="badge bg-primary rounded-pill">{{ orderType.type }}</span>
                <span :class="{ 'badge bg-success': orderType.active, 'badge bg-secondary': !orderType.active }"
                    class="ms-2 rounded-pill">{{ orderType.active ? 'Active' : 'Inactive' }}</span>
                <button class="btn btn-danger btn-sm ms-2" @click.stop="$emit('delete', index)">Delete</button>
            </span>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        orderTypes: Array
    },
    emits: ['edit', 'delete']
}
</script>
<style scoped>
a {
    cursor: pointer;
}   
</style>