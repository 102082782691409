<template>
  <NavBar :showBack="back" :showSkip="false" @back="handleBack" />
  <TwoPartLayout>
    <template v-slot:one>
      <SvgIcon name="error" width="38.625rem" height="38.625rem" />
    </template>
    <template v-slot:two>
      <div class="instructions align-items-start">
        <h3>{{ title }}</h3>
        <p class="instructions">{{ message }}</p>
        <button v-if="startOver" class="btn btn-link text-primary right" @click="resetOrder">
          {{ $t('startOverButtonText') }}
        </button>
      </div>
    </template>
  </TwoPartLayout>
</template>
<script>
import TwoPartLayout from "@/components/global/TwoPartLayout.vue";
import NavBar from "@/components/global/NavBar.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    TwoPartLayout,
    NavBar
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      errorDialogState: 'errorDialogState' // assuming 'errorDialogState' is the getter name
    }),
    title() {
      return this.errorDialogState?.title || 'Error';
    },
    message() {
      return this.errorDialogState?.message || 'An unexpected error occurred.';
    },
    startOver() {
      return this.errorDialogState?.startOver || true;
    },
    back() {
      return this.errorDialogState?.back || false;
    }
  },
  methods: {
    resetOrder() {
      this.$store.commit("order/RESET");
      this.$router.push({ name: "start" });
    },
    handleBack() {
      this.back();
    },
  },
};
</script>
<style scoped>
.instructions {
  margin-top: 2rem;
  font-size: 2.125rem;
  line-height: 3rem;
}

.right .svg-icon {
  margin-left: 1rem;
}
</style>
