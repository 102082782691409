<template>
  <div :class="fadeInClass">
    <QuestionAsker v-if="showQuestionAsker" :questions="questionsToAsk" @complete="handleQuestionComplete"
      @cancel="handleQuestionCancel" />
    <TwoPartLayout>
      <template v-slot:two>
        <div>
          <template v-if="!receiptSent && paymentType === 'CREDIT'">
            <h3>{{ $t('receipt') }}</h3>
            <p>{{ $t('receiptText') }}</p><br />
            <br />
            <br />
            <button @click="sendEmail" :disabled="loading === 'email'"
              class="btn btn-lg btn-outline-primary flex-grow-1">
              <SvgIcon v-if="loading !== 'email'" width="2.15rem" height="2.15rem" name="email" />
              <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{ $t('emailReceipt') }}
            </button><br />
            <button @click="sms" :disabled="loading === 'phone'" class="btn btn-lg btn-outline-primary flex-grow-1">
              <SvgIcon v-if="loading !== 'phone'" width="2.15rem" height="2.15rem" name="sms" />
              <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{ $t('smsReceipt') }}
            </button><br />

            <br />

            <div v-if="config.ratingPromptEnabled">
              <p>{{ $t('ratingPrompt') }}</p>
              <div style="display: block; width: 100%; margin-left: -0.5rem;">
                <RatingComponent :orderRef="appRef" />
              </div>
            </div>
            <div v-else>
              <button @click="no" class="btn btn-lg btn-outline-primary flex-grow-1">
                {{ $t('done') }}
                <SvgIcon name="forward" width="3rem" height="3rem" />
              </button><br />
            </div>
          </template>
          <template v-else>
            <h3>{{ $t('complete') }}</h3>
            <h3 v-if="gpattTicket && gpattTicket.orderNumber">
              {{ $t('orderNumber') }}:
              <span class="badge rounded-pill bg-primary text-light order-number">
                {{ gpattTicket.orderNumber }}
              </span>
            </h3>
            <p v-if="paymentType == 'CASH'">{{ $t('payUpFrontInstructions') }}</p>
            <p v-else>{{ $t('thankYou') }}</p><br />
            <button class="btn btn-link text-primary right" @click="newOrder">
              {{ $t('startNewOrder') }}
              <SvgIcon name="forward" width="3rem" height="3rem" />
            </button>
          </template>
        </div>
      </template>
    </TwoPartLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TwoPartLayout from "@/components/global/TwoPartLayout.vue";
import QuestionAsker from "@/components/features/QuestionAsker.vue";
import RatingComponent from "@/components/features/RatingComponent.vue";
import { orderInactive } from "@/utils/wsHelper"; // Import the function

export default {
  name: "CompleteView",
  data() {
    return {
      lastAppRef: null,
      complete: false,
      receiptSent: false,
      showQuestionAsker: false,
      questionsToAsk: [],
      receiptType: null,
      loading: null,
      fadeInClass: "fade-in",
    };
  },
  components: {
    TwoPartLayout,
    QuestionAsker,
    RatingComponent,
  },
  computed: {
    ...mapGetters(["screenOrientation"]),
    ...mapGetters("order", ["appRef", "paymentType", "email", "phone", "gpattTicket"]),
    ...mapGetters("location", ["config"]),
  },
  methods: {
    askForReceipt(receiptType) {
      this.receiptType = receiptType;
      this.questionsToAsk = [{
        title: receiptType === "email" ? "Enter your email address" : "Enter your phone number",
        text: receiptType === "email" ? "We will send the receipt to your email." : "We will send the receipt as an SMS.",
        inputType: receiptType == "email" ? "email" : "tel",
        placeholder: receiptType === "email" ? "example@example.com" : "(555) 555-5555",
        optIn: false,
        optInText: "Save this for future receipts",
        validationRegex: receiptType === "email" ? "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$" : "^[0-9]{3}-[0-9]{3}-[0-9]{4}$",
        required: true,
        orderProperty: receiptType // Add this line to specify which property to use
      }];
      this.showQuestionAsker = true;
    },
    async handleQuestionComplete(response) {
      this.showQuestionAsker = false;

      const answer = response[0]?.answer || null;

      console.log("Answer", answer);
      console.log("Receipt Type", this.receiptType);
      // Logic to send receipt
      try {
        this.$store.dispatch("order/sendReceipt", { to: answer, type: this.receiptType });
        this.receiptSent = true;
      } catch (error) {
        console.error("Error sending receipt", error);
        this.receiptSent = true;
      } finally {
        this.receiptSent = true;
      }
    },
    handleQuestionCancel() {
      this.showQuestionAsker = false;
    },
    async sendEmail() {
      this.loading = 'email';
      try {
        await this.askForReceipt("email");
      } finally {
        this.loading = null;
      }
    },
    async sms() {
      this.loading = 'phone';
      try {
        await this.askForReceipt("phone");
      } finally {
        this.loading = null;
      }
    },
    no() {
      this.complete = true;
      this.$router.push({ name: "start" });
    },
    newOrder() {
      this.$router.push({ name: "start" });
    },
  },
  mounted() {
    this.lastAppRef = this.$store.getters["order/appRef"];
    this.$store.dispatch("order/setTimeoutActive", true);
    setTimeout(() => {
      this.fadeInClass = "fade-in";
    }, 1000);

    // Call orderInactive when the component is mounted
    const locationId = this.$store.state.locationId; // Assuming locationId is stored in Vuex
    const orderRef = this.appRef;
    orderInactive(locationId, orderRef);
  },
};
</script>

<style scoped>
.hold {
  position: absolute;
  top: 0;
}
</style>
