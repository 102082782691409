<template>
  <div class="store-activation-guide">
    <h3>Store State Management</h3>
    <p>Manage your store's availability and operating hours efficiently:</p>
    <ul>
      <li>Quickly activate or deactivate your store</li>
      <li>Set a custom message for customers when the store is closed</li>
      <li>Configure kiosk ordering schedules</li>
    </ul>

    <div class="activation-controls">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="storeActiveToggle" v-model="sampleData.config.active">
        <label class="form-check-label" for="storeActiveToggle">
          Active
        </label>
      </div>
      <div v-if="!sampleData.config.active" class="mt-3">
        <input type="text" class="form-control" v-model="sampleData.config.text.deactivatedMessage" placeholder="Enter reason for closure (optional)">
      </div>
    </div>

    <div class="scheduling-info">
      <h4>Kiosk Ordering Schedule</h4>
      <p>Kiosks only allow ordering during scheduled hours. Adjust the times below to see how it affects the preview:</p>
      <div v-for="(periods, day) in sampleData.schedules[0].periods" :key="day" class="schedule-item">
        <span>{{ day }}:</span>
        <input type="time" v-model="periods.startTime" @change="updatePreview">
        <span>to</span>
        <input type="time" v-model="periods.endTime" @change="updatePreview">
      </div>
    </div>

    <div class="preview">
      <h4>Demo Preview:</h4>
      <div class="preview-container">
        <StartView :sampleData="sampleData" :previewMode="true" />
      </div>
    </div>
  </div>
</template>

<script>
import StartView from '@/views/StartView.vue';
import { start } from '@/utils/sampleData';

export default {
  title: 'Store State Management',
  tags: ['store management', 'availability'],
  components: {
    StartView
  },
  data() {
    return {
      sampleData: start
    }
  },
  methods: {
    updatePreview() {
      // This method is called when schedule times are changed
      // You can add logic here to update the preview based on the current time
      const now = new Date();
      const currentDay = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'][now.getDay()];
      const currentTime = now.toTimeString().slice(0, 5);

      const todaySchedule = this.sampleData.schedules[0].periods[currentDay];
      if (todaySchedule) {
        this.sampleData.storeOpen = currentTime >= todaySchedule.startTime && currentTime < todaySchedule.endTime;
      } else {
        this.sampleData.storeOpen = false;
      }
    }
  },
  mounted() {
    this.updatePreview();
  }
};
</script>

<style scoped>

.activation-controls {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.preview {
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.preview-container {
  margin-top: 20px;
  transform: scale(0.5);
  transform-origin: top left;
  border: 1px solid #e9ecef;
}

.scheduling-info {
  background-color: #e9ecef;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
}

.schedule-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.schedule-item span {
  margin: 0 10px;
}

.schedule-item input[type="time"] {
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
</style>