<template>
    <div 
        @click="onItemClick"
        class="col press" 
        :class="{ 'active': isActive }"
        v-if="item">
        <div class="card h-100" :class="{ 'sold-out': isSoldOut, 'dark-mode': isDarkMode }">
            <div class="row h-100">
                <!-- Text Content -->
                <div :class="textColumnClass">
                    <div class="card-body">
                        <div class="name">{{ item.name }}</div>
                        <p class="description multiline-truncate">{{ item.description }}</p>
                        <p class="price-stock" v-if="item.price > 0"><strong>{{ formatCurrency(item.price) }}</strong>
                            <span class="stock" v-if="isSoldOut">{{ soldOutText }}</span>
                        </p>
                        <p class="price-stock" v-else><strong>Priced by choices</strong>
                            <span class="stock" v-if="isSoldOut">{{ soldOutText }}</span>
                        </p>
                    </div>
                </div>
                <!-- Image -->
                <div v-if="hasImage" class="col-5">
                    <img :src="imageUrl(item.images[0])" class="img-fluid rounded-end" alt="Image of {{ item.name }}">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { imageUrl, formatCurrency } from '@/utils/helpers';

export default {
    props: {
        item: {
            type: Object,
            required: true,
            validator: item => !!item.ref
        },
        soldOutText: {
            type: String,
            default: 'Sold Out'
        },
        children: Array
    },
    computed: {
        ...mapGetters(['menuItems', 'config']),
        isSoldOut() {
            return this.item.stockStatus === 'OUT_OF_STOCK';
        },
        hasImage() {
            return Boolean(this.item?.images?.length);
        },
        textColumnClass() {
            return this.hasImage ? 'col-7' : 'col-12';
        },
        isDarkMode() {
            return document.documentElement.classList.contains('dark-mode');
        }
    },
    data() {
        return {
            isActive: false,
        };
    },
    methods: {
        imageUrl,
        formatCurrency,
        onItemClick() {
            if (this.isSoldOut) return;
            
            this.isActive = true;
            setTimeout(() => {
                this.isActive = false;
                this.$emit('item-selected', this.item);
            }, 200);
        },
    }
}
</script>

<style scoped>
.col {
    padding-right: calc(var(--bs-gutter-x)* .5) !important;
    padding-left: calc(var(--bs-gutter-x)* .5) !important;
    transition: transform 0.1s ease-in-out;
}

.card {
    cursor: pointer;
    font-size: 1.25rem !important;
    border: none;
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow-small);
    padding: 0;
    background-color: var(--primary-white);
    transition: transform 0.15s ease-in-out;
}

.card .col-5 {
    padding-left: 0;
    margin-left: 0;
}



.card:hover {
    box-shadow: var(--box-shadow-large);
}

.card.dark-mode {
    background-color: var(--grey-1);
    color: var(--primary-black);
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
    padding-right: 0;
    font-size: 1.25rem !important;
    height: 100%;
}

.img-fluid.rounded-end {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: center;
    border-left: 0.125rem solid var(--grey-outline);
    border-top-right-radius: var(--border-radius-lg) !important;
    border-bottom-right-radius: var(--border-radius-lg) !important;
}

.multiline-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.card.sold-out {
    cursor: default;
}

.card.sold-out img,
.card.sold-out .name,
.card.sold-out .description,
.card.sold-out strong {
    opacity: 0.8;
    filter: grayscale(100%);
}

.card.sold-out .stock {
    filter: none !important;
    color: var(--status-error) !important;
}

.name {
    line-height: 1.6875rem;
    font-weight: 700;
    font-size: 1.25rem !important;
}

.description {
    font-size: 1.125rem !important;
    line-height: 1.705rem;
    color: var(--grey-medium);
    flex-grow: 1;
    padding-right: 0.5rem;
    margin-top: none;
    margin-bottom: 1rem;
}

.description.dark-mode {
    color: var(--grey-50);
}

.price-stock {
    font-size: 1.125rem !important;
    margin-top: auto;
    color: var(--primary-color);
    margin-bottom: 0;
}

.price-stock .stock {
    color: var(--status-error);
    font-weight: 700;
    margin-left: 0.4rem;
    opacity: 1 !important;
}

.card:not(.sold-out):hover {
    box-shadow: var(--box-shadow-small);
    border-color: var(--primary-color);
}

.col.active {
    animation: pushButton 0.2s ease-in-out;
}

@keyframes pushButton {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.98);
    }
    100% {
        transform: scale(1);
    }
}

#app .col.active .card {
    background-color: var(--primary-color-transparent) !important;
}

.col.active .card .name,
.col.active .card .description,
.col.active .card .price-stock {
    color: var(--primary-color);
}

.col.active .card.dark-mode {
    background-color: var(--primary-color-dark);
}

.col.active .card.dark-mode .name,
.col.active .card.dark-mode .description,
.col.active .card.dark-mode .price-stock {
    color: var(--primary-color-light);
}

/* Ensure sold-out items don't show active state */
.col.active .card.sold-out {
    background-color: var(--primary-white);
    border-color: var(--grey-outline);
    animation: none;
}

.col.active .card.sold-out.dark-mode {
    background-color: var(--grey-1);
}

.col.active .card.sold-out .name,
.col.active .card.sold-out .description,
.col.active .card.sold-out .price-stock {
    color: inherit;
}
</style>
