import api from "@/utils/api";
import { v4 as uuidv4 } from 'uuid';

const getDefaultState = () => ({
  coupons: [],
  coupon: null,
});

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    coupons: state => state.coupons,
    coupon: state => state.coupon,
  },
  mutations: {
    SET_COUPONS(state, coupons) {
      state.coupons = coupons;
    },
    SET_COUPON(state, coupon) {
      state.coupon = coupon;
    },
    CLEAR_COUPON(state) {
      state.coupon = null;
    },
  },
  actions: {
    async fetchCoupons({ commit, rootState }) {
      const { token, activeLocationId } = rootState.admin;

      const coupons = await api.callAPI('get', `/admin/coupons/${activeLocationId}`, null, token);
      commit('SET_COUPONS', coupons);

    },
    async fetchCouponById({ commit, rootState }, id) {
      const { token, activeLocationId } = rootState.admin;

      const coupon = await api.callAPI('get', `/admin/coupons/${activeLocationId}/${id}`, null, token);
      commit('SET_COUPON', coupon);

    },
    async createCoupon({ dispatch, rootState }, couponData) {
      const { token, activeLocationId } = rootState.admin;

      couponData.id = uuidv4();
      await api.callAPI('post', `/admin/coupons/${activeLocationId}`, couponData, token);
      dispatch('fetchCoupons');

    },
    async updateCoupon({ dispatch, rootState }, { id, couponData }) {
      const { token, activeLocationId } = rootState.admin;

      await api.callAPI('put', `/admin/coupons/${activeLocationId}/${id}`, couponData, token);
      dispatch('fetchCoupons');

    },
    async deleteCoupon({ dispatch, rootState }, id) {
      const { token, activeLocationId } = rootState.admin;

      await api.callAPI('delete', `/admin/coupons/${activeLocationId}/${id}`, null, token);
      dispatch('fetchCoupons');

    },
    async validate({ rootState, commit }, { code }) {
      try {
        const result = await api.callAPI('get', `/v2/validate-coupon/${code}`, null, rootState.terminal.apiToken);
        console.log('validate result:', result);
        if (result.success) {
          commit('order/SET_COUPON', result.coupon, { root: true });  // Set the validated coupon in the store
        }
        return {
          success: result.success,
          message: result.message,
          errorCode: result.errorCode,
          coupon: result?.coupon || null,
        };
      } catch (error) {
        console.error('validate error:', error);
        return { success: false, message: error?.data?.message || 'Unknown Error', errorCode: 'API_ERROR' };
      }
    },
  },
};
