<!-- kiosk/src/components/StepItem.vue -->
<template>
  <div :class="['step-item', { 'step-done': isDone }]" @click="toggleDone">
    <div class="step-number">{{ number }}</div>
    <div class="step-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepItem',
  props: {
    number: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isDone: false
    };
  },
  methods: {
    toggleDone() {
      this.isDone = !this.isDone;
    }
  }
};
</script>

<style scoped>
.step-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.step-item:hover {
  background-color: #f9f9f9;
  transform: translateY(-2px);
}

.step-item.step-done {
  border-color: #4caf50;
  background-color: #e8f5e9;
}

.step-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.step-content {
  flex: 1;
  font-size: 1rem;
  color: #333;
}
</style>
