<template>
    <div class="menu-container" v-if="items">
        <Departments :departments="departments" :config="config" :selectedDepartment="selectedDepartment"
            @select-department="selectDepartment" />
        <Items @item-selected="handleItemSelected" :selectedDepartment="selectedDepartment" :items="items"
            :order-types="orderTypes" :selected-order-type="selectedOrderType" :departmentItems="departmentItems" @set-barcode-active="handleBarcodeActive" />
        <ShoppingBag :total="total" :hasCoupons="hasCoupons" :coupon="coupon" :items="items" :cartTitle="cartTitle" :orderType="selectedOrderType" :orderItems="orderItems"
            @reset="reset" @checkout="checkout" @barcodeScanned="handleScan" @removed="handleRemoved" @apply-coupon="onApplyCoupon" @remove-coupon="onRemoveCoupon" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { imageUrl, formatCurrency } from "@/utils/helpers";

import Departments from '@/components/features/menu/spaceX/DepartmentsCol.vue';
import Items from '@/components/features/menu/spaceX/MenuCol.vue';
import ShoppingBag from '@/components/features/menu/spaceX/OrderCol.vue';

export default {
    components: {
        Departments,
        Items,
        ShoppingBag
    },
    data() {
        return {
            selectedDepartment: null
        }
    },
    props: {
        cartTitle: {
            type: String,
            required: true,
        },
        departments: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        orderItems: {
            type: Array,
            required: true,
        },
        orderTypes: {
            type: Array,
            required: true,
        },
        selectedOrderType: {
            type: Object,
            required: true,
        },
        total: {
            type: Number,
            required: true,
        },
        coupon: {
            type: Object,
            default: () => null,
        },
        hasCoupons: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['item-selected', 'removed', 'reset', 'checkout', 'barcode-scanned', 'applyCoupon', 'removeCoupon'],
    computed: {
        ...mapGetters(["hasSDK"]),
        ...mapGetters("location", ["details", "config"]),
    },
    methods: {
        imageUrl,
        formatCurrency,
        handleBarcodeActive(active) {
            this.$emit('set-barcode-active', active);
        },
        selectDepartment(department) {
            this.selectedDepartment = department;
        },
        onApplyCoupon(coupon) {
            this.$emit('applyCoupon', coupon);
        },
        onRemoveCoupon() {
            this.$emit('removeCoupon');
        },
        departmentItems(department) {
            if (!department) return [];
            const itemMap = new Map(this.items.map((item) => [item.ref, item]));
            return department.items.map((itemRef) => {
                const baseItem = itemMap.get(itemRef.ref);
                if (baseItem) {
                    baseItem.lineModifierSets = itemRef.lineModifierSets || null;
                    return baseItem;
                } else {
                    return null;
                }
            }).filter(Boolean);
        },
        handleItemSelected(item) {
            this.$emit('item-selected', item);
        },
        handleScan(scannedData) {
            this.$emit('barcode-scanned', scannedData);
        },
        handleRemoved(key) {
            this.$emit('removed', key);
        },
        reset() {
            this.$emit('reset');
        },
        checkout() {
            this.$emit('checkout');
        },
        subtotal() {
            return this.orderItems.reduce((total, item) => total + item.price, 0);
        },
    },
    mounted() {
        if (this.departments.length > 0) {
            this.selectDepartment(this.departments[0]);
        }
    }
};
</script>
<style scoped>
.menu-container {
    color: #fff;
    display: flex;
    width: 100vw;
    height: 100vh;
    background: #000;
}

.departments {
    width: 21rem;
    flex-shrink: 0 !important;
    border-right: 0.125rem solid #2A2927;
}

.items {
    flex-grow: 1 !important;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    border-right: 0.125rem solid #2A2927;
}

.shopping-bag {
    width: 30.8125rem;
    flex-shrink: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
}
</style>
