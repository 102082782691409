import api from '@/utils/api';

const onboarding = {
  namespaced: true,
  state: {
    onboardingStatus: null,
    onboardingRedirectUrl: null
  },
  actions: {
    async onboard({ commit }, guid) {
      try {
        const response = await api.callAPI('GET', `kiosk/onboard/${guid}`);
        if (response.ok) {
          const data = await response.json();
          commit('setOnboardingStatus', {
            status: response.status,
            redirectUrl: data.redirectUrl
          });
          return {
            status: response.status,
            redirectUrl: data.redirectUrl
          };
        } else {
          // Return the status for non-OK responses as well
          return { status: response.status };
        }
      } catch (error) {
        console.error('Onboarding error:', error);
        throw error;
      }
    }
  }
};

export default onboarding;
