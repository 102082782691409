<template>
  <TwoPartLayout v-if="visible">
    <template v-slot:one>
      <SvgIcon :name="mainIcon" width="38.625rem" height="38.625rem" />
    </template>
    <template v-slot:two>
      <div class="instructions align-items-start">
        <h3>{{ title }}</h3>
        <p class="instructions">{{ message }}</p>
        <button
          class="btn btn-link text-primary right"
          @click="click"
        >
          {{ action.title || $t("backButtonText") }}
          <SvgIcon v-if="action?.icon" :name="action.icon" width="3rem" height="3rem" />
        </button>
      </div>
    </template>
  </TwoPartLayout>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TwoPartLayout from "@/components/global/TwoPartLayout.vue";
export default {
  components: {
    TwoPartLayout,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("errorDialog", ["resetError"]),
    click() {
      this.resetError();
      if (this.action?.callback) {
        this.action.callback();
      }

    },
  },
  computed: {
    ...mapGetters("errorDialog", [
      "mainIcon",
      "title",
      "message",
      "action",
      "visible",
    ]),
  },
};
</script>
<style scoped>
.payment-total {
  text-align: left;
  font-size: 6rem;
  font-weight: 700;
  color: var(--primary-color);
}

.payment-taxes {
  font-size: 1.5rem;
}

.instructions {
  margin-top: 2rem;
  font-size: 2.125rem;
  line-height: 3rem;
}

.right .svg-icon {
  margin-left: 1rem;
}
</style>
