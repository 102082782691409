<template>
    <div class="logo-container" v-if="logo" :aria-label="name" role="img" :class="{ square: shape == 'square' }">
        <div class="logo-background" :style="{ backgroundImage: 'url(' + logo + ')' }"
            :class="{ 'disabled': disabled }"></div>
    </div>
</template>

<script>
export default {
    name: 'LocationLogo',
    props: {
        logo: {
            type: String,
            default: ''
        },
        shape: {
            type: String,
            default: 'circle'
        },
        name: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    components: {

    }
};
</script>

<style scoped>
.logo-container {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    border: 0.125rem solid var(--grey-focus);
}

.logo-container.square {
    border-radius: 1rem;
}

.logo-background {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.logo-background.disabled {
    filter: grayscale(100%);
}
</style>
