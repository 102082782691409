<template>
    <div class="container">
        <div class="mb-3">
            <label for="imageUpload" class="form-label">Upload Menu Image</label>
            <input class="form-control" type="file" id="imageUpload" @change="handleImageUpload">
        </div>
        <div v-if="menu">
            <h2>Menu Items</h2>
            <div class="row">
                <div v-for="item in menu.items" :key="item.ref" class="col-md-6 mb-4">
                    <div class="card d-flex flex-row">
                        <div class="card-body">
                            <h5 class="card-title">{{ item.name }}</h5>
                            <textarea :class="['form-control', { 'has-suggestion': item.hasSuggestion }]" rows="3"
                                v-model="item.description"></textarea>
                        </div>
                        <div class="card-img-container" :class="{ 'no-image': !item.images.length }">
                            <img v-if="item.images.length" :src="imageUrl(item.images[0])" class="card-img"
                                alt="Image of {{ item.name }}">
                            <div v-else-if="isLoadingImage(item.ref)" class="loading-content">Loading...</div>
                            <div v-else class="no-image-content" @click="generateImageForItem(item)">AI</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { imageUrl } from '@/utils/helpers';

export default {
    computed: {
        ...mapGetters('admin', ['menu']),
        simplifiedMenuData() {
            return this.menu.items.map(item => {
                return {
                    ref: item.ref,
                    name: item.name,
                    description: item.description
                };
            });
        },
    },
    data() {
        return {
            imageText: ''
        };
    },
    methods: {
        imageUrl,
        generateImageForItem(item) {
            const text = `${item.name}: ${item.description}`;
            this.$store.dispatch('admin/generateImage', { itemRef: item.ref, text });
        },
        isLoadingImage(itemRef) {
            return this.$store.state.admin.itemLoadingStates[itemRef];
        },
        requestImageGeneration(item) {
            if (!item.images.length || confirm("Generate new image for this item?")) {
                this.generateImageForItem(item);
            }
        },
        handleImageUpload() {
            return false;
        }
    }
};
</script>
<style scoped>
.card-img-container {
    min-width: 8.125rem;
    height: 8.125rem;
    margin: 0.5rem;
    position: relative;
    background-color: rgb(204, 204, 204);
    border-radius: 0.3125rem;
}

.card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.no-image-content {
    color: white;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.has-suggestion {
    border-color: green !important;
    border-width: 0.3125rem !important;
}

.card {
    display: flex;
    flex-direction: row;
}

.card-body {
    flex-grow: 1;
}
</style>