<template>
    <div v-if="visible">
        <NavBar :showBack="allowBack" @back="handleBack" />

    </div>
    <div class="error-overlay" v-if="visible">
        <div class="error-content">
            <p>{{ parsedMessage }}</p>
            <div>
                <SvgIcon :name="iconName" width="12.5rem" height="12.5rem" />
            </div>
            <button class="btn btn-link text-primary right" v-if="allowBack" @click="handleBack">
                {{ $t('backButtonText') }}
            </button>
            <!--<button class="btn btn-link text-primary right" v-if="allowStartOver" @click="handleStartOver">
                {{ $t('startOverButtonText') }}
            </button>-->
        </div>
        <div v-if="extractedText" class="pill">
            {{ extractedText }}
        </div>
    </div>
</template>

<script>
import SvgIcon from "@/components/global/SvgIcon.vue";
import NavBar from "@/components/global/NavBar.vue";

export default {
    name: "FullScreenError",
    components: {
        SvgIcon,
        NavBar,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            default: "Error, please try again",
        },
        iconName: {
            type: String,
            default: "errorWidthX",
        },
        allowBack: {
            type: Boolean,
            default: false,
        },
        allowStartOver: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        parsedMessage() {
            return this.message.replace(/\[.*?\]/g, '').trim();
        },
        extractedText() {
            const match = this.message.match(/\[(.*?)\]/);
            return match ? match[1] : '';
        }
    },
    methods: {
        handleBack() {
            this.$emit("back");
        },
        handleStartOver() {
            this.$emit("startOver");
        },
    },
};
</script>

<style scoped>
.error-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    /* Change background to white */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;

}

p {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3rem;
    width: 75%;
    margin: 0 auto;
    margin-bottom: 2.25rem;
}

.error-content {
    text-align: center;
}

.pill {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: lightgrey;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 1.5rem;
    font-weight: 600;
}
</style>
