import { createStore } from "vuex";
import api from "@/utils/api";
import { initWebSocket } from "@/utils/wsHelper";
import { saveData, loadData, removeData } from "@/utils/storageHelper";
import onboarding from "./modules/onboarding";
import errorDialog from "./modules/error";
import terminal from "./modules/terminal";
import menu from "./modules/menu";
import order from "./modules/order";
import customer from "./modules/customer";
import location from "./modules/location";
import dialog from "./modules/dialog";
import orderItem from "./modules/orderItem";
import tip from "./modules/tip";
import receipt from "./modules/receipt";
import admin from "./modules/admin";
import coupon from "./modules/coupon";
import logger from "@/utils/logger";

const _fetch = async (what, authToken) => {
  try {
    const pairingMethod = store.state.terminal.pairingMethod;
    const merchantId = store.state.terminal.previewMid;
    const isPreview = pairingMethod === 'mid';
    const endpoint = isPreview ? `v2/preview/${merchantId}/${what}` : `v2/${what}`;
    const response = await api.callAPI("GET", endpoint, null, isPreview ? null : authToken);
    if (!response.ok && response.status === 401) {
      store.dispatch('handleAuthError');
    }
    return response;
  } catch (error) {
    store.dispatch('handleAuthError');
    throw error;
  }
};

const store = createStore({
  modules: {
    onboarding,
    errorDialog,
    terminal,
    location,
    menu,
    order,
    customer,
    dialog,
    orderItem,
    tip,
    receipt,
    admin,
    coupon
  },
  state() {
    return {
      terminalPaired: false,
      terminalAuthenticated: false,
      currentLanguage: 'en',
      feVersion: '2.1.8',
      currentVersion: '1.0.0',
      startOver: false,
      initialized: false,
      errorDialogState: null,
      isLoading: false,
      loadingMessage: null,
      cachedMenuData: null,
      cachedMenuHash: null,
      brandColor: "#295aea",
      screenOrientation: "horizontal",
      idleTimeoutSeconds: 60,
      translator: null,
      isKiosk: window.location.pathname.includes('kiosk/'),
      setupMode: {
        active: false,
        screen: null,
        data: null
      },
    };
  },
  getters: {
    context: () => {
      const context = window.location.pathname;
      // to string kiosk, admin, public
      if (context.includes('/kiosk')) {
        return 'kiosk';
      } else if (context.includes('/admin')) {
        return 'admin';
      } else {
        return 'public';
      }
    },
    initialized: (state) => state.initialized,
    feVersion: (state) => state.feVersion,
    errorDialogState: (state) => state.errorDialogState,
    loadingMessage: (state) => state.loadingMessage,
    isLoading: (state) => state.isLoading,
    isWebSocketConnected: (state) => state.isWebSocketConnected,
    screenOrientation: (state) => state.screenOrientation,
    isMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);
    },
    hasSDK: () => {
      // eslint-disable-next-line no-undef
      return typeof Shift4Kiosk !== "undefined";
    },
    translate: (state) => (key) => state.translator ? state.translator.translate(key) : key,
    currentLanguage: (state) => state.currentLanguage,
    isPreview: (state) => state.preview,
  },
  mutations: {
    SET_LANGUAGE(state, language) {
      state.currentLanguage = language;
      if (state.translator) {
        state.translator.setLanguage(language);
      }
    },
    SET_VERSION(state, version) {
      state.currentVersion = version;
    },
    SET_INITIALIZED(state, initialized) {
      state.initialized = initialized;
    },
    SET_LOCATION_ID(state, id) {
      state.locationId = id;
    },
    SET_CACHED_MENU_DATA(state, menuData) {
      state.cachedMenuData = menuData;
    },
    SET_CACHED_MENU_HASH(state, hash) {
      state.cachedMenuHash = hash;
    },
    SET_LOADING(state, { visible, message = null }) {
      state.isLoading = visible;
      state.loadingMessage = message || '';
    },
    SET_WEBSOCKET_CONNECTION(state, isConnected) {
      state.isWebSocketConnected = isConnected;
    },
    SET_ERROR(state, errorDialog) {
      console.log('SET_ERROR', errorDialog)
      state.errorDialogState = errorDialog;
    },
    SET_SCREEN_ORIENTATION(state, orientation) {
      state.screenOrientation = orientation;
    },
    SET_IDLE_TIMEOUT_SECONDS(state, timeout) {
      state.idleTimeoutSeconds = timeout;
    },
    SET_TRANSLATOR(state, translator) {
      state.translator = translator;
    },
    SET_SETUP_MODE(state, { active, screen, data }) {
      state.setupMode = { active, screen, data };
    },
  },
  actions: {
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language);
    },
    handleAuthError({ commit }) {
      commit('terminal/SET_AUTHENTICATED', false);
    },
    async checkVersion({ commit, state, getters }, { version }) {
      if (state.currentVersion !== version) {
        const isOnline = navigator.onLine;
        const isOrderInProgress = getters['order/orderType'];

        //const pageExists = await fetch(window.location.href).then(response => response.ok).catch(() => false);
        const pageExists = true;

        const shouldRefresh = isOnline && !isOrderInProgress && pageExists;

        if (shouldRefresh) {
          logger.info("New version detected. Reloading page...");
          //window.location.reload(true);
        } else {

          commit('SET_VERSION', version);
        }
      }
    },
    loading({ commit, state }, key) {
      let message = key;

      try {
        message = state.translator ? state.translator.translate(key) : key
      } catch (error) {
        console.error('Error translating loading message', error);
      }

      commit("SET_LOADING", {
        visible: true,
        message,
      });
    },
    doneLoading({ commit }) {
      commit("SET_LOADING", {
        message: null,
        visible: false,
      });
    },
    async loadStockData({ commit, state }) {
      const stockData = await _fetch("menu/stock", state.terminal.apiToken);
      commit("menu/SET_STOCK", stockData?.items || []);
    },
    async loadMenuData({ commit, state, dispatch }) {
      await dispatch('checkAndUpdateMenuHash');

      if (state.cachedMenuData) {
        commit("menu/SET_MENU", state.cachedMenuData);
      } else {
        const menuData = await _fetch("menu", state.terminal.apiToken);
        commit("menu/SET_MENU", menuData);
        state.cachedMenuData = menuData;
        saveData('cachedMenuData', menuData);
      }
    },
    async checkAndUpdateMenuHash({ state }) {
      const result = await _fetch("menu/hash", state.terminal.apiToken);

      const hash = result;

      if (hash !== state.cachedMenuHash) {
        state.cachedMenuData = null;
        removeData('cachedMenuData');
        state.cachedMenuHash = hash;
        saveData('cachedMenuHash', hash);
      }
    },
    setTranslator({ commit }, { translator }) {
      try {
        if (translator) {
          commit('SET_TRANSLATOR', translator);
        } else {
          console.error('Translator instance not found');
        }
      } catch (error) {
        console.error('Error setting config texts', error);
      }
    },
    async loadLocationData({ state, commit }) {
      try {
        this.dispatch("loading", 'loadingLocationInformation');
        let hash = null;
        if (state.cachedMenuData) {
          hash = state.cachedMenuHash;
        }
        const all = await _fetch(`?hash=${hash}&version=${state.feVersion}`, state.terminal.apiToken);

        if (all.config.text && state.translator) {
          state.translator.setConfigTexts(all.config.text);
        }
        // if (all.version) {
        //   dispatch("checkVersion", { version: all.version });
        // }

        commit("location/SET_CONFIG", all.config);
        commit("location/SET_HAS_COUPONS", all.hasCoupons);
        commit("location/SET_DETAILS", all.location);
        commit("SET_IDLE_TIMEOUT_SECONDS", all.config.idleTimeoutSeconds || 60);

        if (all.location.payment_token) {
          commit("SET_LOCATION_ID", all.location.id);
        }

        if (all.menu) {
          commit("menu/SET_MENU", all.menu);
          state.cachedMenuData = all.menu;
          saveData('cachedMenuData', all.menu);
          saveData('cachedMenuHash', all.hash);
          state.cachedMenuHash = all.hash;

        } else {

          if (state.cachedMenuData) {
            commit("menu/SET_MENU", state.cachedMenuData);
          }
        }

        if (all.stock?.items) {
          commit("menu/SET_STOCK", all.stock.items);
        }
      } finally {
        this.dispatch("doneLoading");
      }
    },
    async initKiosk({ dispatch, rootState }) {

      // first is it paired?
      this.terminalPaired = rootState.terminal.terminalDetails.locationId ? true : false;

      // then is it authenticated?
      this.terminalAuthenticated = await dispatch('terminal/checkAuthenticated');



    },
    async initializeLocation({ commit, dispatch, rootState }) {
      const cachedMenuData = loadData('cachedMenuData');
      const cachedMenuHash = loadData('cachedMenuHash');
      commit('SET_CACHED_MENU_DATA', cachedMenuData);
      commit('SET_CACHED_MENU_HASH', cachedMenuHash);
      await dispatch("loadLocationData");
      dispatch("initWebSocketNow", { locationId: rootState.terminal.locationId, terminalId: rootState?.terminal?.terminalDetails?.id });
      commit("SET_INITIALIZED", true);
      commit("SET_LOCATION_ID", rootState.terminal.locationId);

    },
    async initializeLocationOld({ commit, dispatch, rootState }) {
      if (!rootState.terminal.apiToken) return;
      if (rootState.terminal.apiToken) {
        commit('terminal/SET_API_TOKEN', rootState.terminal.apiToken);
      }
      commit("SET_INITIALIZED", false);
      if (!rootState.terminal.locationId) {
        commit("terminal/SET_AUTHENTICATED", false);
      }
      commit("SET_LOCATION_ID", rootState.terminal.locationId);

      const cachedMenuData = loadData('cachedMenuData');
      const cachedMenuHash = loadData('cachedMenuHash');

      commit('SET_CACHED_MENU_DATA', cachedMenuData);
      commit('SET_CACHED_MENU_HASH', cachedMenuHash);

      try {
        await dispatch("loadLocationData");
        dispatch("initWebSocketNow", { locationId: rootState.locationId, terminalId: rootState?.terminal?.terminalDetails?.id });
        commit("SET_INITIALIZED", true);
        return true;
      } catch (error) {
        // if any 4XX error, try to register the terminal
        if (error.message.includes("401") || error.message.includes("403")) {
          commit("terminal/SET_AUTHENTICATED", false);
          commit("SET_LOCATION_ID", null);
          // eslint-disable-next-line no-undef
          if (typeof Shift4Kiosk !== 'undefined' && typeof Shift4Kiosk.deviceConfig === 'function') {
            // eslint-disable-next-line no-undef
            const config = JSON.parse(Shift4Kiosk.deviceConfig());
            await dispatch("terminal/registerTerminalSerial", config.serialNumber);
            if (await dispatch("isTerminalAuthenticated")) {
              await dispatch("initializeLocation");
              commit("SET_INITIALIZED", true);
              return true;
            }
          }
        }
        logger.error("Error loading initial data:", error);
        return false;
      }
    },
    async isTerminalAuthenticated({ state }) {
      try {
        let hash = null;
        if (state.cachedMenuData) {
          hash = state.cachedMenuHash;
        }
        await _fetch(`?hash=${hash}&version=${state.feVersion}`, state.terminal.apiToken);
        return true;
      } catch (error) {
        return false;
      }
    },
    async initWebSocketNow({ commit, state }, { locationId, terminalId }) {
      if (this.websocket && this.websocket.close) {
        this.websocket?.close();
      }

      const wsCallbacks = {
        onOpen: () => commit("SET_WEBSOCKET_CONNECTION", true),
        onMessage: (event) => this.dispatch("handleWebhookEvent", event),
        onError: (error) => logger.error("WebSocket error:", error),
        onClose: () => commit("SET_WEBSOCKET_CONNECTION", false),
      };

      const location = locationId || state.locationId;

      this.websocket = initWebSocket(location, terminalId, wsCallbacks);  // Pass terminalId to the WebSocket helper
    },
    async handleWebhookEvent({ commit, dispatch, state }, event) {
      logger.debug("Webhook event received:", event);
      const data = JSON.parse(event.data);
      const payload = data?.payload || {};
      const status = payload?.status || "UNKNOWN";
      switch (data.type) {
        case 'activeOrderUpdate':
          dispatch("admin/activeCount", data.count);
          break;
        case "REFRESH_DASHBOARD":
          dispatch("admin/fetchSummary", { period: "today" });
          break;
        case "ORDER_STATUS_CHANGED":
          dispatch("order/updateOrderStatus", { status, reasons: payload?.reasons || [] });
          this.dispatch("doneLoading");
          break;
        case "REFRESH_MENU":
          await dispatch("loadMenuData", state.locationId);
          break;
        case "HAS_COUPONS":
          console.log('HAS_COUPONS', state.locationId, JSON.parse(data.payload));
          await commit("location/SET_HAS_COUPONS", JSON.parse(data.payload));
          break;
        case "REFRESH_STOCK":
          await dispatch("loadStockData");
          break;
        case "UPDATE_CONFIG":
          dispatch("loading");
          // eslint-disable-next-line no-case-declarations
          const config = JSON.parse(data.payload);
          await commit("location/SET_CONFIG", config);
          if (config.text && state.translator) {
            state.translator.setConfigTexts(config.text);
          }
          dispatch("doneLoading", false);
          break;
        case "RESTART_TERMINALS":
          window.location.reload();
          break;
        case "SETUP_MODE":
          if (state.isKiosk) {
            commit('SET_SETUP_MODE', { active: true, screen: data.screen, data: data.payload });
          }
          break;
        case "RESET_SETUP_MODE":
          commit('SET_SETUP_MODE', { active: false, screen: null, data: null });
          break;
      }
    },
    retryConnectWebsocket() {
      if (this.websocket && this.websocket.manualReconnect) {
        this.websocket.manualReconnect();
      } else {
        const locationId = this.state.locationId || this.state.terminal.locationId;
        const terminalId = this.state.terminal.terminalId;
        this.dispatch('initWebSocketNow', { locationId, terminalId });
      }
    },
    async loadPublicLocationData({ commit, dispatch }) {

      try {
        await dispatch("loadLocationData");
        //dispatch("initWebSocketNow", { locationId: locationId, terminalId: rootState?.terminal?.terminalDetails?.id });
        commit("SET_INITIALIZED", true);
        return true;
      } catch (error) {
        console.error("Error loading public location data:", error);
      }
    },
  },
});

export default store;
