<template>
    <div v-if="visible" :class="['screensaver', fadeClass]" @click="handleClick">
        <div v-if="backgroundType === 'video'" class="background-video-container">
            <video v-if="videoSource" ref="video" @canplaythrough="onVideoLoaded" v-show="videoLoaded" preload="auto"
                autoplay loop muted class="background-video">
                <source :src="videoSource" type="video/mp4" />
                <source :src="videoSource" type="video/webm" />
                <source :src="videoSource" type="video/ogg" />
                Your browser does not support the video tag.
            </video>
        </div>
        <template v-else-if="backgroundType === 'particles'">
            <ParticlesComponent />
        </template>
        <div class="screensaver-content justify-content-center" v-if="showText">
            <p v-if="orderTypes.length === 1" class="text-slide-up">{{ orderTypes[0].name }}</p>
            <p class="order-here order text-slide-up" style="animation-delay: 0.5s;">{{ startFirstPart }}</p>
            <p class="order-here here text-slide-up" style="animation-delay: 0.75s;">{{ startSecondPart }}</p>
            <p class="tap-screen text-slide-up" style="animation-delay: 1s;">{{ startOrderSubtext }}</p>
        </div>
    </div>
</template>

<script>
import ParticlesComponent from '../global/ParticlesComponent.vue';
export default {
    components: {
        ParticlesComponent
    },
    emits: ['isVisible', 'selectSingleOrderType', 'showOrderTypes'],
    props: {
        orderStarted: { type: Boolean, required: true },
        startOrderText: { type: String, default: 'Order Here' },
        startOrderSubtext: { type: String, default: 'Tap screen to get started' },
        showText: { type: Boolean, default: true },
        enabled: { type: Boolean, required: true },
        orderTypes: { type: Array, default: () => [] },
        backgroundType: { type: String, default: 'video' },
        videoSource: { type: String, default: null },
        primaryColor: { type: String, default: '#000' },
        idleTimeSeconds: { type: Number, default: 15 } // Changed default to 15 seconds
    },
    data() {
        return {
            visible: false,
            timer: null,
            fadeClass: 'ss-fade-in',
            videoLoaded: false
        };
    },
    computed: {
        startFirstPart() {
            return this.startOrderText.split(' ')[0];
        },
        startSecondPart() {
            const words = this.startOrderText.split(' ');
            return words.slice(1).join(' ');
        }
    },
    methods: {
        resetTimer() {
            clearTimeout(this.timer);
            if (this.visible) {
                this.fadeClass = 'ss-fade-out';
                setTimeout(() => {
                    this.visible = false;
                    this.fadeClass = '';
                }, 1000); // Duration of fade-out animation
            }
            this.timer = setTimeout(() => {
                this.visible = true;
                this.$emit('isVisible', true);
                this.fadeClass = 'ss-fade-in';
            }, this.idleTimeSeconds * 1000);
        },
        handleClick() {
            this.$emit('isVisible', false);
            this.resetTimer();
        },
        onVideoLoaded() {
            this.videoLoaded = true;
            this.fadeIn = true;
        }
    },
    watch: {
        enabled(newVal) {
            console.log('enabled prop changed:', newVal);
            if (newVal) {
                console.log('enabled is true');
                this.visible = true;
                this.fadeClass = 'ss-fade-in';
                this.$emit('isVisible', true);
                this.resetTimer(); // Reset the timer when enabled becomes true
            } else {
                console.log('enabled is false');
                this.visible = false;
                this.fadeClass = '';
                clearTimeout(this.timer); // Clear the timer when disabled
            }
        }
    },
    mounted() {
        this.resetTimer(); // Initialize the timer on mount
        window.addEventListener('keydown', this.resetTimer);
        window.addEventListener('click', this.resetTimer);
    },
    unmounted() {
        clearTimeout(this.timer); // Clear timer on component destruction
        window.removeEventListener('keydown', this.resetTimer);
        window.removeEventListener('click', this.resetTimer);
    }
};
</script>

<style scoped>

.screensaver {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 4rem;
    z-index: 9999;
    cursor: pointer;
}

.screensaver-content {
    display: flex;
    flex-direction: column; /* Arrange children vertically */
    justify-content: center; /* Center children vertically */
    align-items: center; /* Center children horizontally */
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    z-index: 1;
}

.screensaver-content p {
    margin: 1rem 0;
    opacity: 0;
    transform: translateY(20px);
    animation: ssFadeInSlideUp 1s forwards;
}

.order-here {
    line-height: 12rem;
    font-size: 12rem;
    font-family: 'Roboto', sans-serif;
}


.order-here.here, 
.order-here.order {
    font-size: 14.5rem;
    color: #fff;
    font-weight: 900;
}

.tap-screen {
    padding-top: 2rem;
    font-size: 2.9rem;
    font-weight: 100;
}

.background-video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fallback-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ss-fade-in {
    animation: ssFadeIn 1s;
}

.ss-fade-out {
    animation: ssFadeOut 1s;
}


@keyframes ssFadeInSlideUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes ssFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes ssFadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
</style>