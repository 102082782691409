<template>
    <div class="coupon-item" v-if="coupon">
        <svg-icon name="coupon" width="2rem" height="2rem" />
        <span class="coupon-name">{{ coupon.name }}</span>
        <span class="coupon-total" v-if="discounts">({{ formatCurrency(discounts) }})</span>
        <svg-icon style="cursor: pointer" name="close" class="coupon-remove" width="2.5rem" height="2.5rem" @click="remove" />
    </div>
</template>

<script>
import { formatCurrency } from '@/utils/helpers';
export default {
    props: {
        coupon: {
            type: Object,
            required: false,
            default: null,
        },
        discounts: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    emits: ['remove'],
    methods: {
        formatCurrency,
        remove() {
            this.$emit('remove', this.coupon);
        },
    },
};
</script>

<style scoped>
.coupon-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    color: var(--primary-white);
    background: var(--primary-color);
    font-size: 1.5rem;
}

.coupon-name {
    flex-grow: 1;
    margin: 0 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.coupon-total {
    font-weight: 700;
}

.close-button {
    cursor: pointer;
}
</style>
