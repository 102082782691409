import { createRouter, createWebHistory } from "vue-router";

//import StartView from "./views/StartSpaceX.vue";
import StartView from "./views/StartView.vue";
import MenuView from "./views/MenuView.vue";
import CheckoutView from "./views/CheckoutView.vue";
import CompleteView from "./views/CompleteView.vue";
import ReceiptView from "./views/ReceiptView.vue";
import ErrorView from "./views/ErrorView.vue";
import NotFound from "./views/NotFound.vue";
import RegisterView from "./views/RegisterView.vue";
import AdminView from "./views/AdminView.vue";
import AdminLogin from "./views/admin/LoginView.vue";
import AdminMenu from "./views/admin/MenuView.vue";
import SimpleAdmin from "./views/SimpleAdmin.vue";
import SetupView from "./views/SetupView.vue";
import OAuthCallbackComponent from "./views/admin/OAuthCallbackComponent.vue";
import { scheduleDailyRestart } from './utils/scheduleDailyRestart';
import store from './store';

let restartScheduled = false; // Flag to track if restart has been scheduled


const routes = [
  {
    path: '/oauth/callback',
    name: 'oauth-callback',
    component: OAuthCallbackComponent,
  },
  { path: "/admin/login", component: AdminLogin, name: "admin-login" },
  {
    path: '/admin/logout',
    name: 'admin-logout',
    beforeEnter: (to, from, next) => {
      // Call the store's logout action
      console.log("Logging out");
      store.dispatch('admin/logout').then(() => {
        next({ name: 'admin-login' });
      }).catch(error => {
        console.error('Logout failed:', error);
        next({ name: 'error' });
      });
    }
  },
  { path: "/admin/advanced", component: AdminView, name: "admin-advanced" },
  { path: "/admin/dashboard", component: AdminView, name: "admin-dashboard", meta: { requiresAuth: true } },
  { path: "/admin/simple", component: SimpleAdmin, name: "admin-simple" },
  { path: "/admin/menu", component: AdminMenu, name: "admin-menu" },
  {
    path: "/admin/",
    redirect: () => {
      const isLoggedIn = store.getters['admin/isLoggedIn'];
      console.log("isLoggedIn: " + isLoggedIn);
      return isLoggedIn ? { name: 'admin-dashboard' } : { name: 'admin-login' };
    }
  },
  { path: "/kiosk/", redirect: () => { return { path: `/kiosk/start` }; }, },
  {
    path: "/kiosk/setup/:guid",
    redirect: (to) => {
      return { path: `/setup/${to.params.guid}` };
    },
  },
  {
    path: "/kiosk/start",
    component: StartView,
    name: "start",
    props: (route) => ({ orderTypeRef: route.query.orderTypeRef })
  },
  { path: "/kiosk/register", component: RegisterView, name: "register" },
  { path: "/kiosk/menu", component: MenuView, name: "menu" },
  { path: "/kiosk/checkout", component: CheckoutView, name: "checkout" },
  { path: "/kiosk/complete", component: CompleteView, name: "complete" },
  { path: "/kiosk/error", component: ErrorView, name: "error", props: true },
  {
    path: '/receipt/:ref',
    name: 'receipt',
    component: ReceiptView
  },
  {
    path: '/setup/:ref',
    name: 'setup',
    component: SetupView,
    meta: { requiresAuth: true }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['admin/isLoggedIn'];
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  console.log('Navigating to:', to.path);
  console.log('Requires auth:', requiresAuth, 'Is logged in:', isLoggedIn);

  if (requiresAuth && !isLoggedIn) {
    console.log('Redirecting to admin-login');
    next({
      name: 'admin-login',
      query: { redirect: to.fullPath }
    });
  } else if (to.name === 'admin-login' && isLoggedIn) {
    console.log('Redirecting to admin');
    next({ name: 'admin' });
  }

  next();

  if (!restartScheduled && to.path.startsWith('/kiosk')) {
    console.log("Scheduling restart");
    scheduleDailyRestart();
    restartScheduled = true;
  }
});

export default router;
