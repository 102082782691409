<template>
  <div v-if="paymentError" class="payment-error" :class="{ 'horizontal': screenOrientation === 'horizontal' }">
    <div class="content">
      <div class="message">
        <h3>{{ $t('paymentUnsuccessful') }}</h3>
        <p>{{ paymentError }}</p>
      </div>
      <div class="actions">
        <button v-if="paymentAttempts < 3" class="btn btn-lg btn-primary d-block w-100" @click="retry">
          <span>{{ $t('tryAgain') }}</span>
        </button>
        <br /><br />
        <button class="btn btn-lg btn-outline-primary d-block w-100" @click="cancel">
          <span>{{ $t('cancelOrder') }}</span>
        </button>
      </div>
    </div>
  </div>
  <div v-else>
    <CenteredContainer class="vertical">
      <div class="top">
        <h3>{{ $t('totalDue') }}</h3>
        <div class="payment-total">
          {{ formatCurrency(grandTotal) }}
        </div>
        <div class="payment-taxes" v-if="!gpattTicket">{{ paymentSummary }}</div>
      </div>
      <div class="bottom">
        <div v-if="config.startPaymentInstantly">
          <br />
          <p>{{ $t('pinPadInstructions') }}</p>
        </div>
      </div>
      <PaymentSummary v-if="gpattTicket" :gpattTicket="gpattTicket" :tip="tip" />

      <div class="arrow-container" v-if="config.startPaymentInstantly && config.pinPadLocation !== 'onboard'"
        style="margin-top: 4rem;">
        <SvgIcon :name="config.pinPadLocation === 'right' ? 'forward' : 'back'" width="3rem" height="3rem" class="arrow"
          v-for="n in 4" :key="n" />
      </div>
      <div style="height: 8rem"></div>
      <div v-if="!config.startPaymentInstantly">
        <br />
        <button class="btn btn-primary btn-lg w-100 pay-submit-button" style="margin-left: -16.65%;"
          @click="startOnboard" :disabled="startingPayment">
          <span v-if="!startingPayment">{{ $t('pay') }}</span>
          <span v-else>
            <div class="arrow-container">
              <span v-if="config.pinPadLocation !== 'onboard'">
                <SvgIcon :name="config.pinPadLocation === 'right' ? 'forward' : 'back'" width="2rem" height="2rem"
                  class="arrow" v-for="n in 4" :key="n" />
              </span>
              <span v-else>
                <img src="@/assets/spinner-sm.png" alt="Loading" class="spinner-sm rotating" />
              </span>
            </div>
          </span>
        </button>
      </div>
    </CenteredContainer>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import CenteredContainer from "@/components/global/CenteredContainer.vue";
import PaymentSummary from "./PaymentSummary.vue";
import { formatCurrency, paymentDetailsAsString } from "@/utils/helpers";
import logger from "@/utils/logger";

const preloadSpinnerSmall = new Image();
preloadSpinnerSmall.src = require('@/assets/spinner-sm.png');

export default {
  components: {
    CenteredContainer,
    PaymentSummary,
  },
  data() {
    return {
      maxPaymentAttempts: 3,
      paymentAttempts: 0,
      paymentError: null,
      selectedTip: null,
      saleInProgress: false,
      saleTimeout: null,
      startingPayment: false
    };
  },
  emits: ["set", "submit", "cancel", "paymentError", "canGoBackChange"],
  methods: {
    formatCurrency,
    retry() {
      this.paymentError = null;
      this.startCreditPayment();
    },
    cancel() {
      this.paymentError = null;
      this.$router.push({ name: "start" });
    },
    startOnboard() {
      this.startingPayment = true; // Disable the button and show loading
      this.startCreditPayment();
      setTimeout(() => {
        this.startingPayment = false; // Reset after 60 seconds
      }, 60000); // 60 seconds
    },
    startCreditPayment() {
      if (this.complete) {
        return;
      }

      this.$store.dispatch("order/setTimeoutActive", false);

      if (!this.config.accessToken) {
        this.paymentError = "Shift4 Access Token not found. Contact Shift4 Support";
        return;
      }

      this.paymentAttempts += 1;

      const saleRequest = {
        isStaging: false,
        accessToken: this.config.accessToken,
        ticketId: uuidv4(),
        invoiceNumber: `${this.invoiceNumber}`,
        saleAmountInPennies: Math.round(this.grandTotal),
        tipAmountInPennies: Math.round(this.tip) || 0,
        taxAmountInPennies: Math.round(this.taxTotal),
        customerCode: "1234",
        allowPartialAuth: false,
        forceDuplicate: false,
      };

      try {
        this.$emit("canGoBackChange", false);
        Shift4Kiosk.sale(JSON.stringify(saleRequest));

        this.saleTimeout = setTimeout(() => {
          this.$store.dispatch("order/setTimeoutActive", true);
          this.paymentError = `Unable to process payment. We did not receive a response from the payment device. Please try again.`;
          this.$emit("canGoBackChange", true);
          this.startingPayment = false; // Reset button on timeout
          logger.error("Payment timed out");
        }, 120000); // 120 seconds
      } catch (error) {
        this.$emit("canGoBackChange", true);
        clearTimeout(this.saleTimeout);
        this.startingPayment = false; // Reset button on error
        let message = `Unable to process payment. ${error.text} ${error.code} ${error.message}`;

        switch (error?.code) {
          case '000100':
            message = 'Try another form of payment.';
            break;
          case '100001':
            message = 'Payment timed out. Please retry';
            break;
        }
        this.$store.dispatch("order/setTimeoutActive", true);
        this.paymentError = message;
        logger.error(error);
      }

    },
  },
  watch: {
  },
  computed: {
    ...mapGetters(["hasSDK", "screenOrientation"]),
    ...mapGetters("location", ["config"]),
    ...mapGetters("order", [
      "taxTotal",
      "subtotal",
      "tip",
      "grandTotal",
      "appRef",
      "invoiceNumber",
      "paymentDetails",
      "hasPayment",
      "complete",
      "discounts",
      "surcharges",
      "gpattTicket",
    ]),
    paymentSummary() {
      return paymentDetailsAsString(this.subtotal, this.taxTotal, this.tip, this.discounts, this.surcharges);
    },
  },
  mounted() {
    if (this.hasSDK) {
      this.$store.dispatch("order/setPaymentDetails", null);
      const localTip = this.tip || 0;


      if (this.config?.startPaymentInstantly) {
        this.startCreditPayment();
      }

      Shift4Kiosk.onSaleSuccess = async (paymentDetails) => {
        this.$store.dispatch("order/setTimeoutActive", true);
        clearTimeout(this.saleTimeout);
        this.startingPayment = false; // Reset button on success
        paymentDetails.tipAmountInPennies = Math.round(localTip);
        this.processing = true;
        clearTimeout(this.saleTimeout);
        this.processingMessage = "Processing Order";
        this.$store.dispatch("order/setPaymentDetails", paymentDetails);
        this.$emit("submit");
      };


      Shift4Kiosk.onSaleError = (error) => {
        this.$store.dispatch("order/setTimeoutActive", true);
        clearTimeout(this.saleTimeout);
        this.startingPayment = false; // Reset button on error
        if (error.code === '100002') {
          this.$emit('paymentError', { code: error.code, message: error.message })
        } else {

          let message = `Unable to process payment. ${error.text} ${error.code} ${error.message}`;

          switch (error?.code) {
            case '000100':
              message = 'Try another form of payment.';
              break;
            case '100001':
              message = 'Make sure to follow the prompts to pay. The payment interaction timed out.';
              break;
          }

          this.paymentError = message;
          logger.error(error);
        }
      };

    }
  }
};
</script>

<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.payment-total {
  font-size: 5.5rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 1.75rem;
}

.payment-taxes {
  font-size: 1.5rem;
}

.instructions {
  margin-top: 2rem;
  font-size: 2.125rem;
  line-height: 3rem;
}

.right .svg-icon {
  margin-left: 1rem;
}

.vertical .top {
  text-align: center !important;
}

.vertical * {
  text-align: center;
}

h3 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.payment-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.payment-error h3 {
  margin-bottom: 1rem;
}

.payment-error .message {
  margin-bottom: 10rem;
}

.payment-error .icon {
  margin-bottom: 7rem;
}

.payment-error.hotizontal {
  flex-direction: row;
}

.payment-error .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40rem !important;
}

.payment-error .actions {
  width: 40rem;
}

.bottom p {
  font-family: Nunito Sans;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.41rem;
  text-align: center;
}

.spinner-sm {
  display: block;
  margin: 0 auto;
  width: 1.5rem;
  height: 1.5rem;
}

.rotating {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.arrow-container {
  justify-content: center;
}

.arrow-container .arrow {
  margin-right: 1rem;
}

.arrow-container .arrow:last-child {
  margin-right: 0;
}

.arrow {
  opacity: 0.2;
  animation: fade 2s infinite;
}

.arrow:nth-child(1) {
  animation-delay: 0s;
}

.arrow:nth-child(2) {
  animation-delay: 0.5s;
}

.arrow:nth-child(3) {
  animation-delay: 1s;
}

.arrow:nth-child(4) {
  animation-delay: 1.5s;
}

@keyframes fade {

  0%,
  100% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }
}
</style>
