<template>
    <div class="status">
        <!--<SvgIcon name="barcode" width="1.5rem" height="1.5rem" v-if="isActive" />-->
    </div>
</template>

<script>
export default {
    name: 'BarcodeScanner',
    props: {
        isActive: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        isActive(newVal) {
            if (newVal) {
                this.startListening();
            } else {
                this.stopListening();
            }
        }
    },
    mounted() {
        if (this.isActive) {
            this.startListening();
        }
    },
    beforeUnmount() {
        this.stopListening();
    },
    methods: {
        startListening() {
            document.addEventListener('keydown', this.handleKeyDown);
        },
        stopListening() {
            document.removeEventListener('keydown', this.handleKeyDown);
        },
        handleKeyDown(e) {
            // Example: Ignore known accessibility device keys
            const accessibilityKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab'];
            if (accessibilityKeys.includes(e?.key)) {
                return; // Ignore accessibility device input
            }

            // Process barcode scanner input
            if (this.isActive) {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    this.emitScanEvent(this.tempBarcode);
                    this.tempBarcode = '';
                } else if (e.key !== 'NumLock') {
                    if (!this.tempBarcode) this.tempBarcode = '';
                    // Handle shifted characters
                    if (e.shiftKey) {
                        // Manually handle shifted keys for letters and other symbols
                        if (e.key.length === 1) {
                            this.tempBarcode += e.key.toUpperCase();
                        }
                    } else {
                        this.tempBarcode += e.key;
                    }
                }
            }
        },
        emitScanEvent(scannedCode) {
            this.$emit('scan', scannedCode);
        }
    },
    data() {
        return {
            tempBarcode: ''
        };
    }
};
</script>

<style scoped>
.status {
    position: absolute;
    top: -0.35rem;
    left: 1.75rem;
    color: white;
}
</style>
