<template>
  <div v-if="type == 'spinner'">
    <div v-if="show" class="loading-container">
      <div class="loading-container" :class="{ 'horizontal': screenOrientation === 'horizontal' }">
        <h3>{{ message }}</h3>
        <SpinnerLarge />
      </div>
    </div>
  </div>
  <div v-else>
    <div class="loading-container" :style="loadingFillStyle" v-if="shouldShow" ref="loadingContainer">
      <div class="loading-fill" ref="loadingFill"></div>
      <div class="loading-text">{{ messageShow }}</div>
    </div>
  </div>
</template>

<script>
import { hexToRGBA } from "@/utils/helpers";
import SpinnerLarge from './SpinnerLarge.vue';
import { mapGetters } from "vuex";
export default {
  name: "LoadingHandler",
  components: {
    SpinnerLarge,
  },
  props: {
    message: {
      type: String,
      default: "Loading...",
    },
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "spinner",
    },
  },
  data() {
    return {
      shouldShow: this.show,
      messageShow: this.message,
    };
  },
  computed: {
    ...mapGetters("location", ["brandColor"]),
    ...mapGetters(["screenOrientation"]),
    loadingFillStyle() {
      return {
        background: hexToRGBA(this.brandColor, 0.9),
      };
    },
  },
  watch: {
    show(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.speedUpAndFadeOut();
      } else {
        this.shouldShow = newVal
      }
    },
    message(newVal) {
      if (newVal) {
        this.messageShow = newVal;
      }
    },
  },
  methods: {
    speedUpAndFadeOut() {
      if (this.type === 'spinner') {
        return;
      }
      const loadingFill = this.$refs.loadingFill;
      const loadingContainer = this.$refs.loadingContainer;

      loadingFill.style.animationDuration = '1s';
      loadingFill.classList.add('animate');

      setTimeout(() => {
        loadingContainer.classList.add('fade-out');
        this.shouldShow = false;
      }, 500); // 1 second for the speed-up animation
    },
  },
};
</script>

<style scoped>
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--grey-surface-overlay-hover) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 8000;
  transition: opacity 0.5s ease;
}

.loading-text {
  position: absolute;
  color: black;
  font-size: 2.5rem;
  z-index: 1;
  background: #fff;
  padding: 0.2rem 1.5rem;
  border: 0.125rem solid var(--grey-focus);
  border-radius: 3rem;
}

.loading-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--primary-white);
  animation: fillAnimation 14s forwards;
}

.loading-fill.animate {
  width: 100%;
}

@keyframes fillAnimation {
  0% {
    width: 0;
  }

  14.29% {
    width: 75%;
  }

  100% {
    width: 100%;
  }
}

.fade-out {
  opacity: 0;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}

h3 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.loading-container.horizontal {
  flex-direction: column;
  justify-content: center;
}
</style>
