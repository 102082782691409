<!-- components/OAuthCallbackComponent.vue -->
<template>
    <div>Loading...</div>
  </template>
  
  <script>
  export default {
    async created() {
      console.log('OAuthCallbackComponent created');
      const code = this.$route.query.code;
      console.log('Authorization code:', code);
      if (code) {
        try {
          await this.$store.dispatch('admin/handleOAuthCallback', code);
          const redirectPath = this.$route.query.redirect || '/admin/dashboard';
          console.log('Redirecting to:', redirectPath);
          this.$router.replace(redirectPath);
        } catch (error) {
          console.error('OAuth login failed:', error);
          this.$router.replace({ name: 'admin-login', query: { error: 'oauth_failed' } });
        }
      } else {
        console.error('No authorization code found');
        this.$router.replace({ name: 'admin-login', query: { error: 'no_code' } });
      }
    },
  };
  </script>
