import api from "@/utils/api";
import router from "@/router";
import { v4 as uuidv4 } from 'uuid';
import { saveData, loadData } from "@/utils/storageHelper";
import { orderActive, orderInactive } from "@/utils/wsHelper";
import { subtotalItem, appError } from "@/utils/helpers";
import logger from "@/utils/logger";
import { datadogRum } from '@datadog/browser-rum';

function createLogger(state, logger) {
  return {
    log(level, message, context = {}) {
      const enrichedContext = { ...context, orderRef: state.orderRef };
      logger.log(level, message, enrichedContext);
    },
    info(message, context = {}) {
      this.log('info', message, context);
    },
    error(message, context = {}) {
      this.log('error', message, context);
    },
  };
}

function loadState() {
  return loadData("orderState");
}

function setState(state) {
  saveData("orderState", state);
}

function calculateTotals(items, tip = 0, taxTotal = 0, discount = 0, surchargesTotal = 0) {
  const subtotal = items.reduce((acc, item) => acc + subtotalItem(item), 0);
  const grandTotal = Math.max(subtotal - discount, 0) + tip + taxTotal + surchargesTotal;
  return { subtotal, grandTotal };
}


const newState = JSON.stringify({
  posTotaled: false,
  orderRef: null,
  started: false,
  isOrderConfirmed: false,
  complete: false,
  status: "NEW",
  optIn: false,
  timeoutActive: false,
  askingQuestions: false,
  appRef: null,
  orderTypeId: null,
  type: null,
  ticketName: null,
  items: [],
  name: null,
  phone: null,
  phoneCountryCode: null,
  email: null,
  extraDetails: [],
  paymentType: null,
  paymentDetails: null,
  invoiceNumber: null,
  discounts: 0,
  subtotal: 0,
  tip: 0,
  tableTentNumber: null,
  taxTotal: 0,
  orderStatusInterval: null,
  totalsLoading: false,
  barcodeLookup: null,
  barcodeActive: true,
  coupon: null,
  startTime: null,
  surcharges: [],
  posGrandTotal: 0,
  posSubtotal: 0,
  gpattTicket: null,
  datadogEnabled: false,
});

export default {
  namespaced: true,
  state() {
    const savedState = loadState();
    return savedState || JSON.parse(newState);
  },
  getters: {
    posTotaled: (state) => state.posTotaled,
    logger: (state) => createLogger(state, logger),
    appRef: (state) => state.appRef,
    orderRef: (state) => state.orderRef,
    started: (state) => state.started,
    complete: (state) => state.complete,
    type: (state) => state.type,
    orderTypeId: (state) => state.orderTypeId,
    timeoutActive: (state) => state.timeoutActive,
    askingQuestions: (state) => state.askingQuestions,
    items: (state) => state.items,
    name: (state) => state.name,
    phone: (state) => state.phone,
    phoneCountryCode: (state) => state.phoneCountryCode,
    email: (state) => state.email,
    invoiceNumber: (state) => state.invoiceNumber,
    extraDetails: (state) => state.extraDetails,
    paymentType: (state) => state.paymentType,
    paymentMethods: (_, state, rootState) => {

      const orderType =
        rootState.location.config.orderTypes.find(
          (x) => x.id === state.orderTypeId
        ) || {};

      return orderType.paymentTypes || [];
    },
    paymentDetails: (state) => state.paymentDetails,
    hasPayment: (state) => state.paymentType !== null,
    subtotal: (state) => state.subtotal,
    tip: (state) => state.tip,
    taxTotal: (state) => state.taxTotal,
    grandTotal: (state, getters) => {
      if (state.posTotaled) {
        return state.posGrandTotal + state.tip;
      }
      const surchargesTotal = state.surcharges.reduce((acc, surcharge) => acc + surcharge.amount, 0);
      const { grandTotal } = calculateTotals(state.items, state.tip, state.taxTotal, getters.discounts, surchargesTotal);
      return grandTotal;
    },
    coupon: state => state.coupon,
    discounts: state => state.discounts,
    total: (getters) => {
      if (getters.posTotaled) {
        return getters.posGrandTotal;
      }
      const subtotal = getters.subtotal;
      const discountAmount = getters.discounts;
      return Math.max(subtotal - discountAmount, 0);
    },
    barcodeLookup: (state) => state.barcodeLookup,
    barcodeActive: (state) => state.barcodeActive,
    surcharges: (state) => state.surcharges,
    gpattTicket: (state) => state.gpattTicket,
  },
  mutations: {
    RUM_SESSIONS(state, enabled) {
      console.log("DATADOG: Setting RUM sessions to", enabled);
      state.datadogEnabled = enabled;
    },
    START(state, record = false) {
      console.log("Starting order");
      state.datadogEnabled = record;
      if (!state.started && state.datadogEnabled) {
        console.log('DATADOG: Starting session');
        datadogRum.startSessionReplayRecording();
        datadogRum.setUser({
          id: state.orderRef,
        });
        datadogRum.startView('Order Session', { orderRef: state.orderRef });
      }
      state.started = true;
      state.complete = false;
      state.startTime = new Date().toISOString();
    },
    RESET(state) {
      console.log("Restting order");
      if (state.datadogEnabled) {
        console.log('Clearing user');
        datadogRum.clearUser();
      }
      this.dispatch("setLanguage", 'en');
      this.dispatch("order/cancelOrder", { appRef: state.appRef });
      const defaultState = JSON.parse(newState);
      for (const key in state) {
        if (!Object.prototype.hasOwnProperty.call(defaultState, key)) {
          delete state[key];
        }
      }
      Object.assign(state, defaultState);

      state.paymentDetails = null;
      state.orderRef = uuidv4();
      setState(state);
      this.dispatch("customer/clearCustomer");

      this.dispatch("dialog/close");

      router.replace({ name: "start" });
    },
    SET_COUPON(state, coupon) {
      console.log("Setting coupon", coupon);
      state.coupon = coupon;
      if (coupon) {
        const subtotal = state.subtotal;
        if (coupon.type === '%') {
          state.discounts = subtotal * (parseFloat(coupon.amount) / 100);
        } else if (coupon.type === '$') {
          state.discounts = Math.min(parseFloat(coupon.amount * 100), subtotal);
        }
      } else {
        state.discounts = 0;
      }

      setState(state);
    },
    SET_COMPLETE(state, complete) {
      console.log("Setting order complete", complete);
      state.complete = complete;
    },
    SET_ORDER_CONFIRMED(state, confirmed) {
      console.log("Setting order confirmed");
      state.isOrderConfirmed = confirmed;
      setState(state);
    },
    UPDATE_INTERVAL_TIMER(state, interval) {
      state.orderStatusInterval = interval;
    },
    SET_TIMEOUT_ACTIVE(state, payload) {
      payload ? console.log("Setting timeout active") : console.log("Setting timeout inactive");
      state.timeoutActive = payload;
    },
    UPDATE_STATE(state, { key, value }) {
      state[key] = value;
      setState(state);
    },
    SET_ORDER_STATUS(state, status) {
      console.log("Setting order status to", status);
      state.status = status;
      setState(state);
    },
    SET_ASKING_QUESTIONS: (state, value) => (state.askingQuestions = value),
    ADD_ITEM: (state, { itemRef, name, quantity, price, lineModifierSets, image, instructions }) => {
      console.log(`Adding item ${name} to order`);
      if (!state.items) state.items = [];

      const item = {
        name,
        itemRef,
        image,
        instructions,
        quantity,
        price,
        lineModifierSets: lineModifierSets || [],
      };
      item.total = subtotalItem(item);
      state.items.unshift(item);

      const { subtotal } = calculateTotals(state.items, state.tip, state.taxTotal);
      state.subtotal = subtotal;
      setState(state);
    },
    UPDATE_ITEM(state, { index, itemRef, price, name, quantity, lineModifierSets, instructions }) {
      console.log(`Updating item ${name} in order`);
      state.items[index] = {
        ...state.items[index],
        itemRef,
        price,
        name,
        quantity,
        lineModifierSets,
        instructions
      };
      state.items[index].total = subtotalItem(state.items[index]);
      setState(state);
    },
    UPDATE_ITEM_QUANTITY(state, { itemIndex, quantity }) {
      if (state.items[itemIndex]) {
        state.items[itemIndex].quantity = quantity;
        state.items[itemIndex].total = subtotalItem(state.items[itemIndex]);
        const { subtotal } = calculateTotals(state.items, state.tip, state.taxTotal);
        state.subtotal = subtotal;
      }
    },
    REMOVE_ITEM: (state, itemIndex) => {
      state.items.splice(itemIndex, 1);
      const { subtotal } = calculateTotals(state.items, state.tip, state.taxTotal);
      state.subtotal = subtotal;
      setState(state);
    },
    SET_TYPE: (state, type) => (state.type = type),
    SET_ORDER_TYPE_ID: (state, id) => (state.orderTypeId = id),
    SET_TICKET_NAME: (state, name) => (state.ticketName = name),
    SET_ITEMS: (state, items) => (state.items = items),
    SET_NAME: (state, name) => (state.name = name),
    SET_TIP: (state, tip) => {
      state.tip = tip;
      const { subtotal } = calculateTotals(state.items, tip, state.taxTotal);
      state.subtotal = subtotal;
    },
    SET_PHONE: (state, phone) => (state.phone = phone),
    SET_PHONE_COUNTRY_CODE: (state, phoneCountryCode) => (state.phoneCountryCode = phoneCountryCode),
    SET_OPT_IN: (state, optIn) => (state.optIn = optIn),
    SET_EXTRA_DETAILS: (state, extraDetails) => {
      const index = state.extraDetails.findIndex(detail => detail.key === extraDetails.key);
      if (index !== -1) {
        state.extraDetails[index].value = extraDetails.value;
      } else {
        state.extraDetails.push({
          key: extraDetails.key,
          value: extraDetails.value,
        });
      }
    },
    SET_PAYMENT_TYPE: (state, paymentType) => (state.paymentType = paymentType),
    SET_PAYMENT_DETAILS: (state, paymentDetails) =>
      (state.paymentDetails = paymentDetails),
    UPDATE_ORDER(state, order) {
      Object.keys(order).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(state, key)) {
          state[key] = order[key];
        }
      });
      // Ensure gpattTicket is updated
      if (order.gpattTicket) {
        state.gpattTicket = order.gpattTicket;
      }
      setState(state);
    },
    SET_TOTALS_LOADING: (state, value) => (state.totalsLoading = value),
    SET_CUSTOMER_DETAILS(state, { name, phone, phoneCountryCode = 'us', email }) {
      state.name = name;
      state.phone = phone;
      state.phoneCountryCode = phoneCountryCode;
      state.email = email;
    },
    SET_BARCODE_LOOKUP(state, barcodeLookup) {
      state.barcodeLookup = barcodeLookup;
    },
    SET_BARCODE_ACTIVE(state, isActive) {
      state.barcodeActive = isActive;
      setState(state);
    },
  },
  actions: {
    setRumSessions({ commit }, enabled) {
      commit("RUM_SESSIONS", enabled);
    },
    async recalculateCartItemPrices({ state }) {
      const cartItems = state.items;
      cartItems.forEach(item => {
        // get menu item from menu store
        const menuItem = this.getters["menu/items"].find(i => i.ref === item.itemRef);
        if (menuItem) {
          console.log(`Setting item price for itemRef ${item.itemRef}: ${menuItem.price} (was: ${item.price})`);
          item.price = menuItem.price;

          // Update modifier prices
          if (item.lineModifierSets && menuItem.modifierSets) {
            item.lineModifierSets.forEach(lineModSet => {
              const menuModSet = menuItem.modifierSets.find(ms => ms.ref === lineModSet.modifierSetRef);
              if (menuModSet) {
                lineModSet.lineModifiers.forEach(lineMod => {
                  const menuMod = menuModSet.modifiers.find(m => m.ref === lineMod.modifierRef);
                  if (menuMod) {
                    console.log(`Setting modifier price for modifierRef ${lineMod.modifierRef}: ${menuMod.price} (was: ${lineMod.price})`);
                    lineMod.price = menuMod.price;

                    // Update nested modifier prices
                    if (lineMod.modifierSets && menuMod.modifierSets) {
                      lineMod.modifierSets.forEach(nestedLineModSet => {
                        const nestedMenuModSet = menuMod.modifierSets.find(nms => nms.ref === nestedLineModSet.modifierSetRef);
                        if (nestedMenuModSet) {
                          nestedLineModSet.modifiers.forEach(nestedLineMod => {
                            const nestedMenuMod = nestedMenuModSet.modifiers.find(nm => nm.ref === nestedLineMod.ref);
                            if (nestedMenuMod) {
                              console.log(`Setting nested modifier price for nestedModifierRef ${nestedLineMod.modifierSetRef}: ${nestedMenuMod.price} (was: ${nestedLineMod.price})`);
                              nestedLineMod.price = nestedMenuMod.price;
                            }
                          });
                        }
                      });
                    }
                  }
                });
              }
            });
          }

          console.log(`Total for itemRef ${item.itemRef} before subtotal: ${item.total}`);
          item.total = subtotalItem(item);
          console.log(`Total for itemRef ${item.itemRef} after subtotal: ${item.total}`);
        }
      });

      state.items = cartItems;

      const { subtotal } = calculateTotals(state.items, state.tip, state.taxTotal);
      state.subtotal = subtotal;

    },
    async recalculateDiscounts({ commit, state }) {
      const coupon = state.coupon;
      if (coupon) {
        commit("SET_COUPON", coupon);
      }
    },
    removeCoupon({ commit }) {
      commit("SET_COUPON", null);
    },
    setBarcodeActive({ commit }, isActive) {
      commit("SET_BARCODE_ACTIVE", isActive);
    },
    async barcodeNotFound({ rootState }, barcode) {

      const response = await api.callAPI("POST", `v2/menu/barcodeNotFound`, { barcode }, rootState.terminal.apiToken);
      return response;
    },
    async start({ commit, rootState, state }) {
      orderActive(rootState.locationId, state.orderRef);
      commit("START", rootState?.location?.config?.rumSessions || false);
    },
    resetOrder({ commit, rootState, state, getters }, source = "unknown") {
      orderInactive(rootState.locationId, state.orderRef);
      if (source === 'idle') {
        getters.logger.info(`Resetting order state due to inactivity`);
      }
      getters.logger.info(`Resetting order state from ${source}`);
      commit("RESET");
    },
    async setBarcodeLookup({ commit }, barcodeLookup) {
      commit("SET_BARCODE_LOOKUP", barcodeLookup);
    },
    async cancelOrder({ rootState, getters }, { appRef }) {
      if (appRef) {
        try {
          await api.callAPI(
            "POST",
            `v2/order/cancel/${appRef}`,
            {},
            rootState.terminal.apiToken
          );
          await getters.logger.info(`Order canceled successfully`, { appRef: appRef });
        } catch (error) {
          await getters.logger.error("Error canceling order", { error, appRef: appRef });
        }
      } else {
        await getters.logger.error("No appRef found, cannot cancel order");
      }
    },
    async clearPendingTicket({ state, rootState }) {

      if (!state.appRef) {
        return;
      }

      try {
        const response = await api.callAPI(
          "POST",
          `order/clear-pending-ticket/${state.appRef}`,
          null,
          rootState.terminal.apiToken
        );

        if (response.message === "Ticket voided successfully") {
          logger.info("Ticket voided successfully");
        } else if (response.message === "No ticket to void") {
          logger.info("No ticket to void");
        }
      } catch (error) {
        logger.error("Error clearing pending ticket", error);
        throw error;
      }
    },
    async sendReceipt({ state, rootState }, { type, to }) {
      try {
        const request = {
          type,
          to
        };

        api.callAPI(
          "POST",
          `v2/order/notify/${state.appRef}`,
          request,
          rootState.terminal.apiToken
        );

        return true;
      } catch (error) {
        logger.error("Error sending receipt", error);
        return false;
      }
    },
    setPaymentType({ commit }, paymentType) {

      commit("SET_PAYMENT_TYPE", paymentType);
      if (paymentType === "CASH") {
        commit("SET_PAYMENT_DETAILS", null);
        commit("SET_TIP", 0);
      }
    },
    setPhone({ commit }, { phone }) {
      commit("SET_PHONE", phone);
    },
    async signIn({ dispatch }, { phone }) {
      try {
        await dispatch("customer/fetchCustomer", { phoneNumber: phone }, { root: true });
        return Promise.resolve();
      } catch (error) {
        console.error("Sign-in error:", error);
        return Promise.reject(error);
      }
    },
    signOut({ dispatch }) {
      dispatch("customer/clearCustomer", null, { root: true });
    },
    setTimeoutActive({ commit }, payload) {
      commit("SET_TIMEOUT_ACTIVE", payload);
    },
    setPaymentDetails({ commit }, paymentDetails) {
      commit("SET_PAYMENT_DETAILS", paymentDetails);
    },
    async setOrderType({ commit, rootGetters, getters, state, dispatch }, ot) {
      console.log(`Setting order type to ${ot.name} with id ${ot.id}`);

      const orderType =
        rootGetters["location/config"].orderTypes.find(
          (x) => x.id === ot.id
        ) || {};

      if (!state.started) {
        await getters.logger.info(`Starting order with type ${orderType.name}`);
      } else {
        await getters.logger.info(`Changing order type to ${orderType.name}`);
      }


      commit("SET_TYPE", orderType.type);
      commit("SET_ORDER_TYPE_ID", orderType.id);
      commit("SET_PAYMENT_TYPE", null);


      const availablePaymentMethods = orderType.paymentTypes || [];
      if (availablePaymentMethods.length === 1) {
        commit("SET_PAYMENT_TYPE", availablePaymentMethods[0]);
      }
      commit("UPDATE_STATE", { key: "type", value: orderType.type });
      // Dispatch action from another store
      await dispatch('menu/setPriceRule', { type: orderType.priceAdjustType, amount: orderType.priceAdjustAmount }, { root: true });
      await dispatch('order/recalculateCartItemPrices', null, { root: true });
      await dispatch('order/recalculateDiscounts', null, { root: true });

    },
    async fillOrderDetails({ commit, state }, answers) {
      answers.forEach((answer) => {

        if (Object.keys(state).includes(answer.orderProperty)) {
          console.log(`Filling order details with ${answer.answer} for ${answer.orderProperty}`);
          commit("UPDATE_STATE", { key: answer.orderProperty, value: answer.answer });
        } else if (answer.posLabel) {
          console.log(`Filling extra details with ${answer.answer} for ${answer.posLabel}`);
          commit("SET_EXTRA_DETAILS", {
            key: answer.posLabel,
            value: answer.answer,
          });
        }
        if (answer.useAsTicketName) {
          commit("SET_TICKET_NAME", answer.answer);
        }
      });
    },
    async updateTotals({ rootState, state, commit, dispatch }) {
      commit("SET_TOTALS_LOADING", true);

      try {
        const locationId = rootState.locationId;

        const request = {
          locationId,
          context: "kiosk",
          order: state,
        };

        const response = await api.callAPI(
          "POST",
          `order/calculate`,
          request,
          rootState.terminal.apiToken
        );
        // Update all relevant totals
        commit("UPDATE_ORDER", {
          ...response,
          subtotal: response.subtotal || state.subtotal,
          tip: response.tip || state.tip,
          taxTotal: response.taxTotal || state.taxTotal,
        });

        // Recalculate totals based on updated state
        if (!response.posTotaled) {
          const { subtotal } = calculateTotals(state.items, state.tip, state.taxTotal);
          commit("UPDATE_STATE", { key: "subtotal", value: subtotal });
        } else {
          commit("UPDATE_STATE", { key: "subtotal", value: response.subtotal });
        }
      } catch (error) {
        dispatch("error", error);
        throw error;  // Throw the error to be caught by the calling function
      } finally {
        commit("SET_TOTALS_LOADING", false);
        console.log('Validation Done');
      }
    },
    async validateOrder({ state, rootState, commit }) {
      try {
        const locationId = rootState.locationId;
        const request = {
          locationId,
          context: "kiosk",
          order: state,
        };

        const response = await api.callAPI(
          "POST",
          `order/calculate`,
          request,
          rootState.terminal.apiToken
        );
        if (response.orderRef) {
          commit("UPDATE_ORDER", {
            ...response,
            subtotal: response.subtotal || state.subtotal,
            tip: response.tip || state.tip,
            taxTotal: response.taxTotal || state.taxTotal,
          });

          // Recalculate totals based on updated state
          if (!response.posTotaled) {
            const { subtotal } = calculateTotals(state.items, state.tip, state.taxTotal);
            commit("UPDATE_STATE", { key: "subtotal", value: subtotal });
          } else {
            commit("UPDATE_STATE", { key: "subtotal", value: response.subtotal });
          }
          return { success: true };
        } else {
          console.error(response);
          return { success: false, errors: response };
        }
      } catch (error) {
        return { success: false, errors: [error?.response?.data?.error || error?.message || error || 'Unknown error'] };
      }
    },
    async validate({ dispatch, getters }) {
      dispatch("setTimeoutActive", false);
      dispatch("loading", 'validatingOrder', { root: true });

      let submitted = false;

      const response = {
        success: true,
        errorMessage: null
      };
      try {
        const validationResponse = await dispatch("validateOrder");
        console.log('validationResponse', validationResponse);
        if (!validationResponse.success) {
          console.log('validationResponse.errors', validationResponse.errors);
          response.success = false;
          response.errorMessage = validationResponse.errors.join(", ");
          return response;
        }

        if (getters.total === 0) {
          console.log('Total is 0, skipping validation');
          submitted = true;
          await dispatch("submit");
          console.log('Order submitted');
        }

        return response;
      } catch (error) {
        response.success = false;
        response.errorMessage = error?.response?.data?.error || error?.message || error || 'Unknown error';
        return response;
      } finally {
        if (!submitted) {
          dispatch("doneLoading", null, { root: true });
        }
      }
    },
    subscribeToOrderUpdates({ rootState, state, dispatch, commit }, orderId) {

      let pollingTimeout;
      let loadingMessageTimeout;
      let pollCount = 0;
      let giveUpTimeout;

      const clearTimers = () => {
        clearTimeout(pollingTimeout);
        clearTimeout(loadingMessageTimeout);
        clearTimeout(giveUpTimeout);
      };

      const handleGiveUp = () => {
        dispatch("setTimeoutActive", true);
        clearTimers();
        if (this.websocket) {
          this.websocket.unsubscribeFromOrder(orderId);
        }
        appError("Order verification timed out", "We could not verify the status of your order. Please contact support.", false, true);
        dispatch("doneLoading", null, { root: true });
      };

      const checkStatus = async () => {

        if (state.isOrderConfirmed || !state.started) return; // Order has already been confirmed

        try {
          const status = await api.callAPI("GET", `v2/order/status/${orderId}`, null, rootState.terminal.apiToken);
          dispatch("updateOrderStatus", { status });
          pollCount++;

          if (!["CONFIRMED", "REJECTED", "FULFILLED", "CANCELED", "FAILED"].includes(status)) {
            pollingTimeout = setTimeout(checkStatus, 5000);
          } else {
            clearTimers();
            dispatch("setTimeoutActive", true);
            if (this.websocket) {
              this.websocket.unsubscribeFromOrder(orderId);
            }
            commit("SET_ORDER_CONFIRMED", true);

            dispatch("doneLoading", null, { root: true });
          }
        } catch (error) {
          dispatch("setTimeoutActive", true);
          clearTimers();
          if (this.websocket) {
            this.websocket.unsubscribeFromOrder(orderId);
          }
          appError("Order was rejected", error || "An error occurred while processing your order.", false, true);
          dispatch("doneLoading", null, { root: true });
        }
      };

      const updateLoadingMessage = () => {
        if (["CONFIRMED", "REJECTED", "FULFILLED", "CANCELED", "FAILED"].includes(state.status)) {
          clearTimers();
          dispatch("doneLoading", null, { root: true });
          return;
        }

        if (pollCount === 3) {
          dispatch("loading", 'oneMoment', { root: true });
        } else if (pollCount === 6) {
          dispatch("loading", 'oneMoreMoment', { root: true });
        }
        loadingMessageTimeout = setTimeout(updateLoadingMessage, 5000);
      };

      pollingTimeout = setTimeout(checkStatus, 5000);
      loadingMessageTimeout = setTimeout(updateLoadingMessage, 5000);
      giveUpTimeout = setTimeout(handleGiveUp, 60000);

      if (rootState.isWebSocketConnected) {

       /*
        this.websocket.subscribeToOrder(orderId, (status) => {
          dispatch("updateOrderStatus", status);
          if (["CONFIRMED", "REJECTED", "FULFILLED", "CANCELED", "FAILED"].includes(status)) {
            commit("SET_ORDER_CONFIRMED", true);
            this.unsubscribeFromOrder(orderId);
            clearTimers();
          }
        });
        */
      } else {
        logger.error("WebSocket is not connected. Cannot subscribe to order updates.");
      }
    },
    updateOrderStatus({ commit, state, dispatch }, { status, reasons }) {

      if (state.isOrderConfirmed || !state.started) return; // Order has already been confirmed

      commit("SET_ORDER_STATUS", status);
      if (["CONFIRMED", "REJECTED", "FULFILLED", "CANCELED", "FAILED"].includes(status)) {
        clearInterval(state.orderStatusInterval);
        dispatch("doneLoading", null, { root: true });
      }
      if (status === "REJECTED") {
        commit("SET_COMPLETE", true);
        const errors = reasons.map((r) => r.explanation || r.code) || [];
        const message = `Order was rejected: ${errors.join(", ")}`;
        appError("Error", message, false, true);
      } else if (status === "CONFIRMED" || status === "FULFILLED") {
        commit("SET_COMPLETE", true);
        if (router.currentRoute.name !== "menu" && router.currentRoute.name !== "start" && state.appRef) {
          router.replace({ name: "complete" });
        }
      }
    },
    async submit({ rootState, state, dispatch, commit, getters }) {
      dispatch("setTimeoutActive", false);
      dispatch("loading", 'submittingOrder', { root: true });

      if (getters.total === 0) {
        commit("SET_PAYMENT_TYPE", "CASH");
      }

      try {
        const locationId = rootState.locationId;
        const request = {
          locationId,
          paymentType: state.paymentType,
          paymentDetails: state.paymentDetails,
          appRef: state.appRef,
        };

        const response = await api.callAPI(
          "POST",
          `order/commit`,
          request,
          rootState.terminal.apiToken
        );
        if (response.success) {
          if (response.webhookRef) {
            // handle conecto order
            this.dispatch("order/subscribeToOrderUpdates", response.webhookRef);
            this.dispatch("loading", 'submittingOrder', { root: true });
          } else {
            router.replace({ name: "complete" });
            commit("SET_ORDER_CONFIRMED", true);
            dispatch("doneLoading", null, { root: true });
          }
          return { success: true };
        } else {
          //commit("SET_COMPLETE", true);
          // dispatch("error", { message: response.details });
          dispatch("setTimeoutActive", true);
          dispatch("doneLoading", null, { root: true });
          commit("SET_PAYMENT_DETAILS", null);
          commit("SET_TIP", null);
          return { success: false, errorMessage: response.details };
        }
      } catch (error) {
        dispatch("setTimeoutActive", true);
        commit("SET_COMPLETE", true);
        dispatch("doneLoading", null, { root: true });
        commit("SET_PAYMENT_DETAILS", null);
        commit("SET_TIP", null);
        return false;
      }
    },
    async addItem({ commit, dispatch }, item) {
      commit("ADD_ITEM", item);
      await dispatch("recalculateDiscounts");
    },
    async removeItem({ commit, dispatch }, itemIndex) {
      commit("REMOVE_ITEM", itemIndex);
      await dispatch("recalculateDiscounts");
    },
    async updateItemQuantity({ commit, dispatch }, payload) {
      commit('UPDATE_ITEM_QUANTITY', payload);
      await dispatch("recalculateDiscounts");
    },
    async updateItem({ commit, dispatch }, { index, itemRef, price, name, quantity, lineModifierSets }) {
      commit('UPDATE_ITEM', { index, itemRef, price, name, quantity, lineModifierSets });
      await dispatch("recalculateDiscounts");
    },
    error({ commit, dispatch }, error) {
      console.log("Error:", error);
      const dialog = {
        title: "Error",
        message: error.message || "An error occurred",
        type: "error",
      }
      commit("SET_ERROR", dialog, { root: true });
      dispatch("doneLoading", null, { root: true });
      router.push({ name: "error" });
    },
    updateCustomerDetails({ commit }, customerDetails) {
      commit('SET_CUSTOMER_DETAILS', customerDetails);
    },
    showErrorScreen({ commit }, { title, message }) {
      commit("errorDialogState", { title, message, startOver: true, back: true });
      router.push({ name: "error" });
    }
  },
};

