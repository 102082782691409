<template>
  <div class="controls" :class="{ vertical: screenOrientation == 'vertical' }">
    <div class="shopping-bag">
      <span class="description">{{ formattedCartTitle }}</span>
      <span class="count">({{ orderCount }})</span>
    </div>
    <div class="button-group">
      <button v-for="ot in orderTypes" :key="ot.type" class="btn"
        :class="{ 'selected': selected === ot.id, 'btn-secondary': selected !== ot.type }" @click="selectOrderType(ot)">
        {{ ot.name }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    customerName: {
      type: String,
      required: false,
    },
    cartTitle: {
      type: String,
      required: true,
    },
    orderTypes: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      default: ""
    },
    orderCount: {
      type: Number,
      default: 0,
    },
  },
  components: {
  },
  computed: {
    ...mapGetters(['screenOrientation']),
    formattedCartTitle() {
      if (this.cartTitle.toLowerCase().startsWith('your') && this.customerName) {
        const firstName = this.customerName.split(' ')[0];
        return this.cartTitle.replace(/^your/i, firstName);
      }
      return this.cartTitle;
    }
  },
  emits: ['order-type-selected', 'reset'],
  methods: {
    selectOrderType(orderType) {
      if(orderType.id !== this.selected) {
        this.$emit('order-type-selected', orderType);
      }
    },
    startOver() {
      this.$emit('reset');
    },
  },
}; 
</script>

<style scoped>
.controls {
  z-index: 5000;
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1.5rem 1rem 1.5rem;
  background-color: var(--primary-white);
  display: flex;
}

.shopping-bag {
  display: flex;
  align-items: center;
  font-size: 1.625rem;
  line-height: 2.25rem;
  margin-right: 2rem;
}

.shopping-bag .description {
  margin-right: 0.25rem;
  font-weight: 700;
}

.controls.vertical {
  background-color: transparent;
  width: 40vw;
  margin-top: 5rem;
  position: absolute;
  right: 0;
  top: 0;
  border-bottom: none;
}

.btn {
  white-space: nowrap;
  min-width: 9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 14rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
  letter-spacing: 0.01125rem;
}

.button-group {
  background-color: var(--grey-focus);
  padding: 0.25rem;
  border-radius: var(--border-radius-sm);
  color: var(--primary-black);
  margin-left: 0;
  border-radius: 30px;
}

.start-over .btn-outline-primary {
  background: var(--primary-white);
  border-radius: var(--border-radius-sm);
  border: 0.125rem solid var(--grey-outline) !important;
  fill: var(--primary-color);
  font-weight: 700;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
}

.start-over .btn-outline-primary:hover {
  color: var(--primary-color);
  background-color: var(--grey-focus);
}

.button-group .btn {
  background-color: var(--grey-focus);
  color: var(--primary-black);
  font-size: 1.5rem;
  font-weight: 700;
  border: none;
  border-radius: var(--border-radius-sm);
  border-radius: 30px;
  margin-right: 0.5rem;
  transition: background-color 0.3s, color 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 10ch; /* Limit visible length to 10 characters */
}

.button-group .btn:last-child {
  margin-right: 0;
}

.selected {
  background-color: var(--primary-white) !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

</style>
