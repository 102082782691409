import { createApp } from "vue";
import DOMPurify from "dompurify";
import axios from 'axios';
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import { datadogRum } from '@datadog/browser-rum';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import TooltipDirective from './directives/tooltip';
import SvgIcon from "./components/global/SvgIcon.vue";
import ConfigText from "./components/global/ConfigText.vue";
import logger from "./utils/logger";
import createTranslatorPlugin from './plugins/translatorPlugin';
import "animate.css";
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '3574e0e6-bcb8-417e-9962-d10cba09c744',
  clientToken: 'pub91f8cdb9736a637a36526c3d441bbe59',
  site: 'datadoghq.com',
  service: 'skytab-kiosk',
  env: process.env.NODE_ENV,
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackViewsManually: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

axios.interceptors.response.use(
  response => response,
  error => {
    logger.error("Request failed", { error: error.toString() });
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      router.push({ name: 'register' });
    }
    return Promise.reject(error);
  }
);

async function initializeApp() {
  const translations = await fetchTranslations();

  const translatorPlugin = createTranslatorPlugin({
    defaultLanguage: 'en',
    translations: translations
  });

  const app = createApp(App);

  app.component("SvgIcon", SvgIcon)
    .component("ConfigText", ConfigText)
    .directive('tooltip', TooltipDirective)
    .directive("safe-html", {
      beforeMount(el, binding) {
        el.innerHTML = DOMPurify.sanitize(binding.value);
      },
      updated(el, binding) {
        if (binding.value !== binding.oldValue) {
          el.innerHTML = DOMPurify.sanitize(binding.value);
        }
      },
    })
    .use(router)
    .use(store)
    .use(translatorPlugin);


  app.mount("#app");
}

async function fetchTranslations() {
  const response = await fetch('/locales/translations.json'); // Adjust the path accordingly
  return response.json();
}

initializeApp().catch(error => {
  console.error('Failed to initialize app:', error);
});