<template>
  <h2 :id="id">
    <SvgIcon name="reorder" class="reorder-icon" v-if="name == 'Order Again'" /> {{ name }}
  </h2>
  <p>{{ description }}</p>
  <div :class="columnClasses">
    <MenuItem v-for="item in items" :key="item.id" :item="item" @item-selected="itemSelected" />
  </div>
</template>

<script>
import MenuItem from "@/components/features/menu/MenuItem.vue";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    cols: {
      type: Number,
      default: 3,
    },
  },
  emits: ['item-selected'],
  components: {
    MenuItem
  },
  data() {
    return {};
  },
  computed: {
    columnClasses() {
      const cols = parseInt(this.cols, 10);
      return `row row-cols-${cols} row-cols-sm-${cols} row-cols-md-${cols} row-cols-lg-${cols} g-4`;
    },
  },
  methods: {
    itemSelected(item) {
      this.$emit("item-selected", item);
    }
  },
};
</script>

<style scoped>
#app h2 {
  font-size: 2rem;
  line-height: 2.7281rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
}
</style>
