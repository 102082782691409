<template>
  <div :class="['controls animate__animated animate__fadeIn', { shadow: item.modifierSets?.length }, { disabled }]">
    <button class="btn btn-lg btn-primary d-block w-100 add-item-button" @click="handleClick" :disabled="disabled">
      {{ isEdit ? $t('updateItem') : $t('addToCart') }}
      <span v-if="subtotal > 0">{{ formatCurrency(subtotal) }}</span>
    </button>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils/helpers';

export default {
  components: {},
  data() {
    return {};
  },
  props: ['subtotal', 'item', 'disabled', 'isEdit'],
  methods: {
    formatCurrency,
    handleClick() {
      if (this.isEdit) {
        this.$emit('updateItem');
      } else {
        this.$emit('addToOrder');
      }
    },
  },
};
</script>

<style scoped>
.controls {
  position: absolute;
  width: 38rem;
  bottom: 0;
  padding: 1.5rem;
  background: var(--primary-white);
  z-index: 10;
  display: flex;
  gap: 1rem;
}

.controls.shadow {
  box-shadow: var(--box-shadow) !important;
}
</style>