<template>
    <div class="departments" ref="departments">
        <div class="logo" v-if="config && config.logo?.url">
            <LocationLogo :logo="config.logo?.url" :shape="config.logo?.shape || 'circle'" borderColor="#2A2927" />
        </div>
        <ul class="department-list">
            <li v-for="department in departments" :key="department.ref" @click="$emit('select-department', department)"
                :class="{ selected: department.ref === selectedDepartment?.ref }">
                <div class="info">
                    <div class="name">{{ department.name }}</div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import LocationLogo from '@/components/global/LocationLogo.vue';

export default {
    data() {
        return {
            isSearching: false,
            searchQuery: ''
        }
    },
    components: {
        LocationLogo
    },
    props: {
        departments: {
            type: Array,
            required: true,
        },
        config: {
            type: Object,
            required: false
        },
        selectedDepartment: {
            type: Object,
            required: false
        }
    },
    methods: {
        searchItems() {
            this.isSearching = true;
        },
        itemSelected(item) {
            this.$emit('item-selected', item);
        },
        startSearch() {
            this.isSearching = true;
            this.searchQuery = '';
            this.filteredItems = this.items; // Initialize filteredItems when search starts
            this.$nextTick(() => {
                this.$refs.searchInput.focus();
            });
        },
    },
    computed: {
        filteredItems() {
            const query = this.searchQuery.toLowerCase();
            return this.items.filter(item => item.name.toLowerCase().includes(query));
        }
    }
};
</script>


<style scoped>
.departments {
    width: 17rem !important;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    position: relative;
}

.logo {
    margin: auto;
    padding-top: 2.5rem;
    width: 14rem;
    height: 16.5rem;
    transition: all 0.3s ease;
    z-index: 100;
    background-color: #000;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 14rem;
    margin: auto;
    margin-top: 2.875rem;
}

ul li {
    margin: 0;
    padding: 0;
    font-size: 1.625rem;
    margin-top: 2rem;
    cursor: pointer;
}

ul li.selected {
    font-weight: bold;
    color: white;
}

.department-list {
    padding-bottom: 5rem; /* 5rem is the height of the android nav bar that pops up when you swipe up from bottom of screen */
}

.department-list li .info .name {
    position: relative;
    display: inline-block;
    padding-bottom: 2px;
}

.department-list li.selected .info .name::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: white;
    transition: all 0.3s ease-out;
    animation: underline-animation 0.25s forwards;
}

@keyframes underline-animation {
    from {
        width: 0;
        left: 50%;
    }

    to {
        width: 100%;
        left: 0;
    }
}
</style>
