const config = require("./../../config");

let ourSocket;
let reconnectionAttempts = 0;
const reconnectionDelayMin = 1000;  // Minimum delay before attempting reconnection
const reconnectionDelayMax = 1500000;  // Maximum delay to prevent overly long wait times

function orderActive(locationId, orderRef) {
  console.log("orderActive", locationId, orderRef);
  if (ourSocket && ourSocket.readyState === WebSocket.OPEN && locationId && orderRef) {
    ourSocket.send(JSON.stringify({ type: "orderActive", locationId, orderRef }));
  }
}

function orderInactive(locationId, orderRef) {
  console.log("orderInactive", locationId, orderRef);
  if (ourSocket && ourSocket.readyState === WebSocket.OPEN && locationId && orderRef) {
    ourSocket.send(JSON.stringify({ type: "orderInactive", locationId, orderRef }));
  }
}


function initWebSocket(locationId, terminalId, callbacks) {
  const { onOpen, onMessage, onError, onClose } = callbacks;

  function connect() {
    if (navigator.onLine === false) {
      console.log("No internet connection available.");
      setTimeout(connect, reconnectionDelayMin);
      return;
    }

    const protocol =  config.API_HOST === 'localhost' ? 'ws' : 'wss';

    ourSocket = new WebSocket(`${protocol}://${config.API_HOST}${config.API_PORT ? `:${config.API_PORT}` : ""}`);
    ourSocket.onopen = () => {
      console.log("WebSocket connection established");
      subscribeToLocation(locationId, terminalId);
      reconnectionAttempts = 0;
      if (onOpen) onOpen();
    };

    ourSocket.onmessage = (event) => {
      if (isPingMessage(event.data)) {
        handlePing();
      } else {
        if (onMessage) onMessage(event);
      }
    };

    ourSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
      if (onError) onError(error);
    };

    ourSocket.onclose = (event) => {
      console.log("WebSocket closed", event.reason);
      if (onClose) onClose();
      attemptReconnect();
    };
  }

  function attemptReconnect() {
    let delay = reconnectionDelayMin * Math.pow(2, reconnectionAttempts);
    delay = Math.min(delay, reconnectionDelayMax); // Ensuring delay does not exceed the max
    delay = delay + Math.random() * reconnectionDelayMin; // Adding jitter

    setTimeout(connect, delay);
    reconnectionAttempts++;
    console.log(`Attempt ${reconnectionAttempts}: Reconnecting in ${delay} ms`);
  }

  function isPingMessage(message) {
    return message === "ping";
  }

  function handlePing() {
    if (ourSocket && ourSocket.readyState === WebSocket.OPEN) {
      console.log("Sending pong");
      ourSocket.send("pong");
    }
  }

  function subscribeToLocation(locationId, terminalId) {
    console.log("Subscribing to location", locationId, terminalId);
    if (ourSocket.readyState === WebSocket.OPEN) {
      ourSocket.send(JSON.stringify({ type: "subscribe", locationId, terminalId }));
    }
  }

  function subscribeToOrder(orderId) {
    console.log("Subscribing to order", orderId);
    if (ourSocket && ourSocket.readyState === WebSocket.OPEN && orderId) {
      ourSocket.send(JSON.stringify({ type: "subscribeOrder", orderId }));
    }
  }

  function unsubscribeFromOrder(orderId) {
    console.log("Unsubscribing from order", orderId);
    if (ourSocket && ourSocket.readyState === WebSocket.OPEN && orderId) {
      ourSocket.send(JSON.stringify({ type: "unsubscribeOrder", orderId }));
    }
  }

  async function manualReconnect() {
    if (ourSocket) {
      connect()
    } else {
      var currentHref = window.location.href;
      console.log("Reloading page", currentHref);
    }
  }

  connect();

  return {
    manualReconnect,
    subscribeToOrder,
    unsubscribeFromOrder
  };
}


export { orderActive, orderInactive, initWebSocket };
