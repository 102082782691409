<template>
    <div v-if="enabled" class="language-picker">
        <button @click="toggleDropdown" class="language-dropdown-toggle">
            <component :is="currentLanguageKey === 'mr' ? 'SvgIcon' : 'Icon'"
                :name="currentLanguageKey === 'mr' ? currentLanguageFlag : undefined"
                :icon="currentLanguageKey !== 'mr' ? currentLanguageFlag : undefined" width="24px" height="24px"
                alt="current language flag" class="flag-icon" />
            <span class="language-name">{{ currentLanguage }}</span>
            <SvgIcon v-if="isOpen" name="carrot-up" width="24px" height="24px" alt="dropdown carrot"
                class="carrot-icon" />
            <SvgIcon v-else name="carrot-down" width="24px" height="24px" alt="dropdown carrot" class="carrot-icon" />
        </button>
        <ul v-if="isOpen" class="language-dropdown-menu">
            <li v-for="language in filteredLanguages" :key="language.code" @click="selectLanguage(language)"
                :class="{ selected: language.code === currentLanguageKey }">
                <component :is="language.code === 'mr' ? 'SvgIcon' : 'Icon'"
                    :name="language.code === 'mr' ? getFlagName(language.code) : undefined"
                    :icon="language.code !== 'mr' ? getFlagName(language.code) : undefined" width="24px" height="24px"
                    alt="language flag" class="flag-icon" />
                {{ language.label }}
            </li>
        </ul>
    </div>
</template>

<script>
import { Icon } from '@iconify/vue';
require('@iconify-json/circle-flags');

export default {
    inject: ['translator'],
    components: { Icon },
    props: {
        defaultLanguage: {
            type: String,
            default: 'en'
        },
        supportedLanguages: {
            type: Array,
            default: () => ['en']
        },
        enabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOpen: false,
            selectedLanguage: { label: "English", code: "en" },
            languages: [
                { label: "English", code: "en" },
                { label: "Español", code: "es" },
                { label: "Italiano", code: "it" },
                { label: "한국어", code: "ko" },
                { label: "中文", code: "zh" },
                { label: "Lietuvių", code: "lt" },
                { label: "Français", code: "fr" },
                { label: "Martian", code: "mr" },
                { label: "ייִדיש", code: "yi" }
            ]
        };
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectLanguage(language) {
            this.$store.dispatch('setLanguage', language.code);
            this.isOpen = false;
        },
        getFlagName(code) {
            const flagMapping = {
                'en': 'circle-flags:us', // Use 'gb' for English flag
                'es': 'circle-flags:es',
                'it': 'circle-flags:it',
                'ko': 'circle-flags:kr',
                'fr': 'circle-flags:fr',
                'mr': 'mars',
                'zh': 'circle-flags:cn',
                'lt': 'circle-flags:lt',
                'yi': 'circle-flags:il'
            };
            return flagMapping[code] || 'circle-flags:xx';
        }
    },
    computed: {
        currentLanguageKey() {
            return this.$store.getters.currentLanguage;
        },
        currentLanguage() {
            const name = this.languages.find(lang => lang.code === this.$store.getters.currentLanguage);
            return name ? name.label : 'Language';
        },
        currentLanguageFlag() {
            return this.getFlagName(this.currentLanguageKey);
        },
        filteredLanguages() {
            return this.languages.filter(lang => this.supportedLanguages.includes(lang.code));
        }
    },
};
</script>

<style scoped>
.language-picker {
    position: relative;
    display: inline-block;
    z-index: 2999;
}

.language-dropdown-toggle {
    width: 15rem !important;
    background-color: #fff;
    cursor: pointer;
    padding: 0.75rem 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    border-radius: var(--border-radius-xl);
    cursor: pointer;
    background-color: var(--primary-white);
    box-shadow: var(--box-shadow-large) !important;
    border: 0.125rem solid var(--grey-outline);
}

.language-dropdown-menu {
    width: 100%;
    font-size: 1rem;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 7001;
    background-color: var(--primary-white);
    border-radius: var(--border-radius-lg);
    border: 0.125rem solid var(--grey-outline);
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 1rem;
    box-shadow: var(--box-shadow-large) !important;
    width: 15rem !important;
    overflow: hidden;
}

.language-dropdown-menu li {
    font-size: 1rem !important;
    padding: 1.25rem 2.125rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.language-dropdown-menu li:hover,
.selected {
    background-color: #f0f0f0;
}

.language-name {
    flex: 1;
    text-align: left;
    margin-left: 0.625rem;
}

.carrot-icon {
    margin-left: auto;
}

.flag-icon {
    margin-right: 0.625rem;
}

.selected {
    font-weight: 700;
}
</style>