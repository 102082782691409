// tooltip.js
import { Tooltip } from 'bootstrap';

export default {
  mounted(el, binding) {
    // Ensure the title is a string
    let title = typeof binding.value === 'string' ? binding.value : (typeof binding.value === 'function' ? binding.value() : JSON.stringify(binding.value));
    let tooltipInstance = new Tooltip(el, {
      title: title,
      placement: binding.arg || 'top',
      trigger: 'hover focus'
    });

    el._tooltip = tooltipInstance;
  },
  beforeUnmount(el) {
    if (el._tooltip) {
      el._tooltip.dispose();
    }
  },
  updated(el, binding) {
    if (el._tooltip) {
      el._tooltip.dispose();
      let title = typeof binding.value === 'string' ? binding.value : (typeof binding.value === 'function' ? binding.value() : JSON.stringify(binding.value));
      let tooltipInstance = new Tooltip(el, {
        title: title,
        placement: binding.arg || 'top',
        trigger: 'hover focus'
      });
      el._tooltip = tooltipInstance;
    }
  }
};