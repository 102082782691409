<template>
  <div class="start-view">
    <div v-if="config && !config.active" role="alert" aria-live="assertive">
      <CenteredContainer>
        <LocationLogo :name="details.name" style="width: 8.25rem; height: 8.25rem" v-if="config.logo?.url"
          :logo="config.logo?.url" :shape="config.logo?.shape || 'circle'" />
        <br />
        <h3>{{ details?.name }}</h3>
        <p>{{ $t('deactivatedMessage') }}</p>
      </CenteredContainer>
    </div>
    <div v-else>
      <BarcodeScanner v-if="storeOpen" @scan="handleScan" aria-label="Barcode Scanner" role="application" />
      <IdleScreen v-if="storeOpen && config.screensaverEnabled && !showQuestions" @is-visible="handleIdleScreenVisible"
        :start-order-text="$t('startOrder')" :showText="config.screensaverShowText"
        :start-order-subtext="$t('startOrderSubtext')" :enabled="config.screensaverEnabled" :orderTypes="orderTypes"
        :backgroundType="config.screensaverBackgroundType" :videoSource="config.screensaverVideoSource"
        :idle-time-seconds="15" @selectSingleOrderType="orderTypeSelected" @showOrderTypes="showOrderTypes"
        :order-started="started" />
      <div v-if="!showScreensaverContent">
        <question-asker v-if="showQuestions && started" :questions="questionsToAsk" @cancel="handleQuestionCancel"
          @complete="handleQuestionComplete" @signin="handleSignIn" />
        <CenteredContainer v-if="details" :class="fadeInClass">
          <div class="top-right">
            <LanguageSelector v-if="config" :class="languageSelectorClass"
              :default-language="config.languageSupport.defaultLanguage" :enabled="config.languageSupport.enabled"
              :supported-languages="config.languageSupport.supportedLanguages" />
          </div>
          <LocationLogo :name="details.name" style="width: 8rem; height: 8rem" v-if="config.logo?.url"
            :logo="config.logo?.url" :shape="config.logo?.shape || 'circle'" />
          <!--<p class="welcome-message" v-if="config.text.welcomeMessage !== ' '">
            <ConfigText textKey="welcomeMessage" :initialValue="$t('welcomeMessage')" />
          </p>-->
          <p v-if="!storeOpen" :class="typingClass" class="opening-time">{{ storeOpeningTimeMessage }}</p>
          <div v-else>
            <p :class="[typingClass, 'order-type-question']">
              <ConfigText textKey="selectOrderTypeQuestion" :initialValue="$t('selectOrderTypeQuestion')" />
            </p>
            <div class="d-flex justify-content-center animated-button">
              <ButtonLarge v-for="orderType in orderTypes" :key="orderType.type" :name="orderType.name"
                :icon="orderType.icon" :use-icon="config.icons" class="align-items-center"
                @buttonClicked="orderTypeSelected(orderType)" />
            </div>
          </div>
        </CenteredContainer>
      </div>
    </div>
    <div class="version-number">{{ feVersion }}</div>
    <div class="kiosk-serial" v-if="serial" @click="handleSerialTap">{{ serial }}</div>
  </div>
</template>

<script>
import LanguageSelector from "@/components/features/LanguageSelector.vue";
import { mapGetters, mapState } from "vuex";
import { openingSoonMessage, isOpen } from "@/utils/time";
import CenteredContainer from "@/components/global/CenteredContainer.vue";
import LocationLogo from "@/components/global/LocationLogo.vue";
import ButtonLarge from "@/components/global/ButtonLarge.vue";
import IdleScreen from "@/components/features/IdleScreen.vue";
import QuestionAsker from "@/components/features/QuestionAsker.vue";
import BarcodeScanner from "@/components/features/BarcodeScanner.vue";
import logger from "@/utils/logger";
import { datadogRum } from '@datadog/browser-rum';

export default {
  inject: ['translator'],
  name: "StartView",
  components: {
    CenteredContainer,
    LocationLogo,
    ButtonLarge,
    IdleScreen,
    QuestionAsker,
    BarcodeScanner,
    LanguageSelector,
  },
  data() {
    return {
      showQuestions: false,
      questionsToAsk: [],
      storeOpen: false,
      selectedOrderType: null,
      showScreensaverContent: false,
      fadeInClass: "fade-in",
      languageSelectorClass: "animate__animated animate__slideInDown",
      typingClass: '',
      serialTapCount: 0,
    };
  },
  computed: {
    ...mapGetters(["feVersion"]),
    ...mapGetters("location", ["details", "config", "orderTypes"]),
    ...mapGetters("terminal", ["serial"]),
    ...mapGetters("order", ["type", "started", "orderTypeId", "orderRef"]),
    ...mapGetters("menu", ["schedules"]),
    ...mapState(['setupMode']),
    isInSetupMode() {
      return this.setupMode.active && this.setupMode.screen === this.$route.name;
    },
    storeOpeningTimeMessage() {
      if (!this.storeOpen) {
        return openingSoonMessage(this?.schedules || []);
      }
      return "";
    },
  },
  watch: {
    orderTypeRef: {
      immediate: true,
      handler(orderTypeRef) {
        if (orderTypeRef) {
          console.log("Order type ref found in URL, selecting order type");
          const orderType = this.orderTypes.find((ot) => ot.id === orderTypeRef);
          this.orderTypeSelected(orderType);
          this.$router.replace({ query: {} });

        }
      },
    },
    'config.active'(newValue) {
      if (!newValue && !this.isLoggedIn) {
        this.$router.push({ name: 'start' });
      }
    },
    schedules: {
      immediate: true,
      handler() {
        this.checkStoreStatus();
      }
    },
    storeOpeningTimeMessage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.triggerTypingAnimation();
      }
    },
  },
  props: {
    orderTypeRef: {
      type: String,
      default: null
    }
  },
  methods: {
    handleIdleScreenVisible(val) {
      if (!val) {
        this.fadeInClass = "fade-in";
        this.languageSelectorClass = "animate__animated animate__slideInDown";

        // if only 1 active order type - select it
        if (this.orderTypes.filter(ot => ot.active).length === 1) {
          this.orderTypeSelected(this.orderTypes.find(ot => ot.active));
        }


      } else {
        this.fadeInClass = "";
        this.languageSelectorClass = "";
      }
    },
    handleTouchStart(orderType) {
      if (orderType === this.selectedOrderType) return;
      orderType._touchActive = true;
    },
    handleTouchEnd(orderType) {
      if (orderType === this.selectedOrderType) return;
      orderType._touchActive = false;
      this.orderTypeSelected(orderType);
    },
    prepareQuestionsToAsk(questions) {
      const keysToTranslate = ['email', 'phone', 'badge', 'name'];
      return questions.map(question => {

        if (this.translator.currentLanguage === this.config.languageSupport.defaultLanguage) {
          return question;
        }

        const key = keysToTranslate.find(k => question.title.toLowerCase().includes(k));
        console.log('Key to translate', key);
        if (key) {
          console.log('Translating question', key);
          question.title = this.$t(`${key}Question`);
          question.text = '';
        }
        return question;
      });
    },
    checkStoreStatus() {
      this.storeOpen = isOpen(this.schedules);
    },
    async orderTypeSelected(orderType, barcode = null) {
      this.checkStoreStatus();
      if (!this.type) {
        logger.info(`Order type selected: ${orderType.name}`);
      } else {
        logger.info(`Order type changed from ${this.type} to ${orderType.name}`);
      }
      this.$store.dispatch("order/start");
      this.$store.dispatch("order/setTimeoutActive", true);
      this.selectedOrderType = orderType;

      this.questionsToAsk = [];
      const orderDetailsClone = JSON.parse(JSON.stringify(orderType.orderDetails));

      if (this.config.phoneLogin) {
        this.questionsToAsk = [{
          title: "Sign In",
          text: "Please enter your phone number",
          inputType: "tel",
          optIn: true,
          orderProperty: "phone",
          optInText: "Remember my personal details by phone",
          validationRegex: "\\d{10}",
          placeholder: "(555) 555-5555",
          required: false
        }];
      }

      const orderTypeQuestions = orderDetailsClone?.filter(d => d.timing == 'startOrder') || [];
      const theQuestions = [...this.questionsToAsk, ...orderTypeQuestions];

      this.questionsToAsk = this.prepareQuestionsToAsk(theQuestions);

      logger.info(`Questions prepared for display: ${this.questionsToAsk.length} questions`);

      if (!this.questionsToAsk.length) {
        await this.$store.dispatch("order/setOrderType", orderType);
        this.$store.dispatch("order/setBarcodeLookup", barcode);
        if (this.type) {
          setTimeout(() => {
            this.$router.push({ name: "menu" });
          }, 100);

        }
      } else {
        setTimeout(() => {
          this.showQuestions = true;
        }, 100);
      }
    },
    selectSingleOrderType() {
      const singleOrderType = this.orderTypes[0];
      this.orderTypeSelected(singleOrderType);
      logger.info(`Single order type selected: ${singleOrderType.name}`);
    },
    showOrderTypes() {
      this.showScreensaverContent = false;
    },
    handleQuestionCancel() {
      this.showQuestions = false;
      this.questionsToAsk = [];
      logger.info('Question answering process canceled by the user');
      if (this.orderTypeId) {
        this.$router.push({ name: "menu" });
      } else {
        this.$store.dispatch("order/resetOrder");
      }
    },
    async handleSignIn(phone) {
      if (!phone) {
        return Promise.reject('Phone number is required');
      }
      try {
        this.$store.commit("order/SET_OPT_IN", true);
        await this.$store.dispatch("order/signIn", { phone: phone });
        return Promise.resolve();  // Ensure the promise resolves after sign-in
      } catch (error) {
        console.error(`Error signing in with phone number: ${phone}`, error);
        return Promise.reject(error);
      }
    },
    async handleQuestionComplete(answers) {
      console.log('Questions completed, processing answers');
      this.$store.dispatch("order/fillOrderDetails", answers);
      this.showQuestions = false;
      await this.$store.dispatch("order/setOrderType", this.selectedOrderType);
      this.$router.push({ name: "menu" });
    },
    handleScan(scannedCode) {
      logger.info(`Barcode scanned: ${scannedCode}`);
      this.proceedWithFirstRetailOrderType(scannedCode);
    },
    proceedWithFirstRetailOrderType(barcode) {
      const firstRetailOrderType = this.orderTypes.find(ot => ot.view === 'retail');
      if (firstRetailOrderType) {
        this.orderTypeSelected(firstRetailOrderType, barcode);
      } else {
        logger.error('No retail order type found');
      }
    },
    triggerTypingAnimation() {
      this.typingClass = 'typing-animation';
      setTimeout(() => {
        this.typingClass = '';
      }, 500); // Reset the class after the animation duration
    },
    handleSerialTap() {
      this.serialTapCount += 1;
      if (this.serialTapCount >= 10) {
        this.$emit('show-pairing-view');
        this.serialTapCount = 0; // Reset the count after triggering
      }
    },
    async reset(source) {
      if (this.started) {
        await this.$store.dispatch("order/resetOrder", source);
        this.$router.push({ name: "start" });
      }
    },
  },
  mounted() {
    console.log('StartView mounted');

    // order mutation to enable datadog
    this.$store.dispatch("order/setRumSessions", this.config?.rumSessions || false);

    if (!this.started && this.config?.rumSessions) {
      console.log('Stopping RUM sessions');
      datadogRum.stopSession();
      datadogRum.stopSessionReplayRecording();
    }
    this.showQuestions = false;
    this.checkStoreStatus();
    if (this.orderTypeRef) {
      console.log('Order type ref found in URL, selecting order type');
      const ot = this.orderTypes?.find(ot => ot.id === this.orderTypeRef);
      if (!ot) {
        logger.error(`Order type with id ${this.orderTypeRef} not found`);
        return;
      }
      this.orderTypeSelected(ot);
      return;
    }

    this.$store.dispatch("order/resetOrder");
    this.storeOpen = isOpen(this.schedules || []);
    this.intervalId = setInterval(this.checkStoreStatus, 10000);
    setTimeout(() => {
      this.fadeInClass = "fade-in";
    }, 500);

    if (!this.storeOpen) {
      this.triggerTypingAnimation();
    }
    this.triggerTypingAnimation(); // Trigger for ConfigText on load
  },
  unmounted() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
.flex-column {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.logo-container {
  margin-bottom: 3.625rem;
}

.welcome-message {
  font-size: 2.125rem;
  margin-top: 1.5rem;
  margin-bottom: 5.75rem;
  color: var(--grey-medium);
}

.opening-time {
  font-size: 2.5rem;
  font-weight: 700;
}

.order-type-question {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 5rem;
  text-align: center;
  width: fit-content;
  margin: 0 auto 5rem;

}

.btn-container:last-child,
.vertical .btn-container {
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.vertical .d-flex {
  flex-direction: column;
  align-items: center;
}

.vertical .btn-container {
  margin-right: 0;
  margin-bottom: 7.75rem !important;
}

/* Adjust ButtonLarge component's width and margin for vertical layout */
.vertical .d-flex .col-6 {
  width: 100%;
  margin-bottom: 1rem;
}

/* Remove the margin from the last button to clean up spacing */
.vertical .d-flex .col-6:last-child {
  margin-bottom: 0;
}

.single-order-type {
  background-color: var(--brand-color);
  color: #fff;
  font-size: 8rem !important;
}

.single-order-message {
  font-size: 6rem;
  line-height: 9rem;
  font-weight: 100;
  text-align: center;
}

.top-right {
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
}

.animated-button {
  visibility: hidden;
  animation: fade-in-up 0.5s ease-in-out forwards;
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(30px);
    visibility: visible;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}

.animate__slideInDown {
  visibility: hidden;
  animation-duration: 0.8s !important;
  animation-fill-mode: forwards;
  visibility: visible;
}


.active {
  border: 0.125rem solid var(--brand-color);
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.animated-button .btn-container {
  flex: 1 1 calc(33.33% - 7rem);
  /* Allow up to 3 buttons per line */
  width: 30rem !important;
  padding: 3.625rem !important;
  margin-right: 7rem;
  box-sizing: border-box;
}

.animated-button .btn-container:last-child {
  margin-right: 0;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.typing-animation {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 0.5s steps(30, end);
}
</style>
