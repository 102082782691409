import axios from 'axios';

const RESTART_HOUR_START = 2;
//const RESTART_HOUR_END = 3;
//const RESTART_CHECK_INTERVAL = 60000; // 1 minute in milliseconds
const RESTART_COOLDOWN = 3600000; // 1 hour in milliseconds

let lastRestartAttempt = 0;

export function scheduleDailyRestart() {
  const now = new Date();
  const restartTime = getNextRestartTime(now);
  
  console.log(`Next restart scheduled for: ${restartTime}`);

  setTimeout(() => {
    checkAndRestart();
  }, restartTime.getTime() - now.getTime());
}

function getNextRestartTime(now) {
  const restartTime = new Date(now);
  restartTime.setHours(RESTART_HOUR_START);
  restartTime.setMinutes(Math.random() * 60);
  restartTime.setSeconds(Math.random() * 60);

  if (restartTime <= now) {
    restartTime.setDate(restartTime.getDate() + 1);
  }

  return restartTime;
}

async function checkAndRestart() {
  if (await isSystemAvailable() && !isInCooldownPeriod()) {
    try {
      await restartSystem();
      lastRestartAttempt = Date.now();
    } catch (error) {
      console.error('Failed to restart system:', error);
    }
  }

  // Schedule the next check
  scheduleDailyRestart();
}

async function isSystemAvailable() {
  try {
    const response = await axios.get('/api/system/status');
    return response.data.status === 'online';
  } catch (error) {
    console.error('Failed to check system status:', error);
    return false;
  }
}

function isInCooldownPeriod() {
  return Date.now() - lastRestartAttempt < RESTART_COOLDOWN;
}

async function restartSystem() {
  try {
    await axios.post('/api/system/restart');
    console.log('System restart initiated');
  } catch (error) {
    throw new Error('Failed to initiate system restart');
  }
}
