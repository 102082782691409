<template>
    <div class="hold" v-if="visible" role="dialog" aria-labelledby="question-title" aria-describedby="question-text">
        <div class="keyboard-container h-100 d-flex flex-column" :class="{ keyboard: (isMobile && isFocused) }">
            <nav class="nav" role="navigation" aria-label="Navigation">
                <NavBar :showBack="true" @back="previous" />
            </nav>
            <div class="fill-remaining">
                <div class="content-center">
                    <div class="text-center">
                        <h3 id="question-title">{{ currentQuestion.title }}</h3>
                        <div v-if="errorMessage" class="alert alert-danger phone-error" role="alert" aria-live="assertive">
                            {{ errorMessage }}
                        </div>
                        <p v-else id="question-text">{{ currentQuestion.text }}</p>
                        <PhoneInput v-if="currentQuestion.inputType === 'tel'" :value="currentQuestion.answer"
                            :placeholder="currentQuestion.placeholder" @valid="handleValidPhone" @focus="handleFocus" />
                        <input v-else-if="currentQuestion.inputType === 'money'" ref="inputField" type="number"
                            :placeholder="currentQuestion.placeholder" :value="formattedMoney"
                            @input="handleMoneyInput" @keyup="handleKeyUp" :maxlength="currentQuestion.maxLength"
                            :required="currentQuestion.required" @focus="isFocused = true" aria-required="true"
                            :aria-invalid="!isInputValid" />
                        <input v-else ref="inputField" :type="currentQuestion.inputType"
                            :placeholder="currentQuestion.placeholder" :value="currentQuestion.answer"
                            @input="handleInput" @keyup="handleKeyUp" :maxlength="currentQuestion.maxLength"
                            :pattern="currentQuestion.validationRegex" :required="currentQuestion.required"
                            @focus="isFocused = true" aria-required="true" :aria-invalid="!isInputValid" />
                        <!-- Error message -->
                        <!-- Conditional checkbox input -->
                        <div v-if="currentQuestion.optIn" @click="toggleOptIn"
                            :class="['modifier', 'opt-in', { highlight: currentQuestion.optInAnswer }]" role="checkbox"
                            :aria-checked="currentQuestion.optInAnswer">
                            <div class="icon">
                                <SvgIcon :name="control" />
                            </div>
                            <span class="name">
                                <label for="optInCheckbox">{{ currentQuestion.optInText }}</label>
                            </span>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <footer class="footer" role="contentinfo">
                <div class="content-center">
                    <button class="btn btn-lg question-submit" :class="buttonClass"
                        :disabled="(currentQuestion.required && !isInputValid) || (!currentQuestion.required && !isInputValid && hasText) || loading" @click="handleSubmit"
                        :aria-busy="loading">
                        <div v-if="loading" class="spinner" role="status" aria-live="polite"></div>
                        <span>{{ buttonText }}</span>
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import NavBar from "@/components/global/NavBar.vue";
import PhoneInput from "@/components/global/PhoneInput.vue";
import { mapGetters } from "vuex";

export default {
    name: 'QuestionAsker',
    props: {
        questions: Array,
    },
    components: {
        NavBar,
        PhoneInput
    },
    mounted() {
        this.$nextTick(() => {
            this.focusInputField();
        });
    },
    emits: ['complete', 'cancel', 'signin'],
    data() {
        return {
            currentQuestionIndex: 0,
            isInputValid: false,
            loading: false,
            buttonText: 'Submit',
            visible: true,
            isFocused: false,
            localQuestions: [],
            errorMessage: '',
            hasText: false,
            formattedMoney: '',
        };
    },
    created() {
        this.localQuestions = this.questions.map(question => {
            const existingAnswer = this.getExistingAnswer(question);
            return {
                ...question,
                answer: existingAnswer || '', // Set the initial answer value
                optInAnswer: question.optIn ? true : undefined
            };
        });
        this.validateInput();
    },
    methods: {
        handleFocus(focused) {
            this.isFocused = focused;
        },
        toggleOptIn() {
            this.currentQuestion.optInAnswer = !this.currentQuestion.optInAnswer;
        },
        handleInput(event) {
            let value = event.target.value;
            // Enforce maxLength by slicing the value
            if (this.currentQuestion.maxLength && value.length > this.currentQuestion.maxLength) {
                value = value.slice(0, this.currentQuestion.maxLength);
                event.target.value = value; // Update the input field value
            }
            this.currentQuestion.answer = value;
            this.validateInput();
        },
        handleKeyUp(event) {
            if (event.key === 'Enter') {
                this.handleSubmit();
            }
        },
        handleValidPhone(event) {
            console.log(`Valid phone: ${event.isValid}`);
            this.isInputValid = event.isValid;

            if (event.val.length > 2) {
                console.log(`Has text: ${event.val}`);
                this.hasText = true;
            } else {
                console.log(`No text: ${event.val}`);
                this.hasText = false;
            }

            console.log(`Input valid: ${this.isInputValid}`);
            if (this.isInputValid) {
                console.log(`Setting phone: ${event.val}`);
                this.currentQuestion.answer = event.val;
                this.errorMessage = ''; // Clear error message if valid
            } else if (event.val.length > 12) {
                this.errorMessage = 'Enter a valid phone number';
            } else {
                this.errorMessage = ''; // Clear error message if length is not > 12
            }

            this.updateButtonState();
        },
        focusInputField() {
            if (this.$refs?.inputField) {
                this.$refs.inputField.focus();
            }
        },
        validateInput() {
            console.log(`Validating input: ${this.currentQuestion.answer}`);
            const currentQuestion = this.currentQuestion;
            this.errorMessage = '';

            // New rule: At least one character is required for required inputs
            if (currentQuestion.required && currentQuestion.answer.length < 1) {
                this.isInputValid = false;
                this.updateButtonState();
                return;
            }

            if (currentQuestion.inputType !== 'tel') {
                this.isInputValid = !!currentQuestion.answer;
            }

            if (currentQuestion.minLength && currentQuestion.answer.length < currentQuestion.minLength) {
                this.isInputValid = false;
                this.updateButtonState();
                return;
            }

            if (currentQuestion.maxLength && currentQuestion.answer.length > currentQuestion.maxLength) {
                this.isInputValid = false;
                this.updateButtonState();
                return;
            }

            if (this.currentQuestion.inputType === 'money') {
                this.isInputValid = this.currentQuestion.answer.length > 0;
            }

            const regex = new RegExp(currentQuestion.validationRegex || ".*");
            this.isInputValid = regex.test(currentQuestion.answer);
            this.updateButtonState();
        },
        updateButtonState() {

            if (this.isInputValid && this.hasText) {
                this.buttonText = this.$t('submitButtonText');
            } else if (!this.currentQuestion.required && !this.hasText && (!this.isInputValid || !this.currentQuestion.answer)) {
                this.buttonText = this.$t('skipButtonText');
            } else {
                this.buttonText = this.$t('submitButtonText');
            }
        },
        updateCurrentQuestionAnswer() {
            const currentQuestion = this.localQuestions[this.currentQuestionIndex];
            const existingAnswer = this.getExistingAnswer(currentQuestion);

            currentQuestion.answer = existingAnswer || '';
            this.validateInput();
        },
        getExistingAnswer(question) {
            if (question.orderProperty === 'phone') {
                return this.phone || '';
            } else if (question.orderProperty === 'email') {
                return this.email || '';
            } else if (question.orderProperty === 'name') {
                return this.name || '';
            } else if (question.orderProperty) {
                return this.extraDetails.find(d => d.key === question.orderProperty)?.value || '';
            }
            return '';
        },
        async handleSubmit() {
            if (this.currentQuestion.inputType === 'tel' && this.currentQuestion.optInAnswer && this.buttonText === 'Submit') {
                const phone = this.currentQuestion.answer.replace(/\D/g, '');
                this.loading = true;
                try {
                    await this.$emit('signin', phone);  // Ensure this is awaited
                } catch (error) {
                    console.error('Error during signin:', error);
                    this.loading = false;
                    return;  // Exit the function if signin fails
                }
                this.loading = false;
            }

            if (this.isInputValid || !this.currentQuestion.required) {
                if (this.currentQuestionIndex < this.localQuestions.length - 1) {
                    this.currentQuestionIndex++;
                    this.validateInput();
                    this.$nextTick(() => {
                        this.focusInputField();
                    });
                } else {
                    this.complete();
                }
            }
        },
        previous() {
            if (this.currentQuestionIndex > 0) {
                this.currentQuestionIndex--;
                this.validateInput();
            } else {
                this.$emit('cancel');
            }
        },
        complete() {
            this.$emit('complete', this.localQuestions.map(q => ({
                ...q,
                answer: q.answer,
                optInAnswer: q.optIn ? q.optInAnswer : undefined
            })));
        },
        handleMoneyInput(event) {
            console.log(`Handling money input: ${event.target.value}`);
            let value = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
            if (value.length > 0) {
                value = parseInt(value, 10).toString(); // Convert to integer
                this.formattedMoney = (value / 100).toFixed(2); // Format as currency
            } else {
                this.formattedMoney = '';
            }
            this.currentQuestion.answer = `${this.formattedMoney}`;
            this.validateInput();
        },
    },
    computed: {
        ...mapGetters(["isMobile"]),
        ...mapGetters('order', ['name', 'phone', 'email', 'extraDetails']),
        skippable() {
            return !this.currentQuestion.required;
        },
        currentQuestion() {
            return this.localQuestions[this.currentQuestionIndex];
        },
        buttonClass() {
            return [
                'btn-lg',
                this.buttonText === 'Skip' ? 'btn-outline-primary' : 'btn-primary'
            ];
        },
        control() {
            const type = "checkbox";
            const state = this.currentQuestion.optInAnswer ? "on" : "off";
            return `${type}-${state}`;
        },
    },
    watch: {
        name() {
            this.updateCurrentQuestionAnswer();
        },
        email() {
            this.updateCurrentQuestionAnswer();
        },
        phone() {
            this.updateCurrentQuestionAnswer();
        },
        currentQuestionIndex() {
            this.updateCurrentQuestionAnswer();
        }
    }
}
</script>
<style scoped>

.nav {
    position: fixed;
    padding: 2rem;
    top: 0;
    left: 0;
    z-index: 8000;
}
.hold {
    position: absolute;
    z-index: 8000;
    background: var(--grey-surface-overlay-hover) !important;
}

.phone-error {
    margin-top: 1rem;
}

input {
    margin: auto;
}

.opt-in {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
    margin-top: 3rem !important;
    margin: 1rem 0rem !important;
}

.keyboard {
    height: 50vh !important;
}

.content-center,
.fill-remaining {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox-container {
    font-size: 1.5rem;
    margin-top: 1rem;
    font-size: 1.25rem;
}

.checkbox-container input[type="checkbox"] {
    width: 1.5rem;
    height: 1.5rem;

    margin-right: 0.5rem;
    position: relative;
    top: 0.25rem;
}

.checkbox-container label {
    font-size: 1.5rem;
}

.spinner {
    float: left;
    border: 0.25rem solid rgba(255, 255, 255, 0.3);
    border-top: 0.25rem solid var(--brand-color);
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.625rem;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

footer button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38rem !important;
    position: relative;
    top: -2rem;
}

footer {
    height: 6rem;
}
</style>
