import { datadogLogs } from '@datadog/browser-logs';

let currentLogLevel = 10;

const logLevels = {
    debug: 10,
    info: 20,
    warn: 30,
    error: 40,
    silent: 50,
};

const isDatadogInitialized = () => {
    return !!datadogLogs.logger;
};

const shouldLog = (level) => {
    return level >= currentLogLevel;
};

function obfuscatePII(text) {
    if (typeof text === 'string') {
        text = text.replace(/(\b\d{3}[-.\s]?){2}\d{4}\b/g, (match) => match.replace(/\d/g, "*").slice(0, -4) + match.slice(-4));
        text = text.replace(/\b\d{13,16}\b/g, (match) => match.replace(/\d/g, "*").slice(0, -4) + match.slice(-4));
        text = text.replace(/\b([A-Za-z]{4})[A-Za-z]*[.]?([A-Za-z])[A-Za-z]*@([A-Za-z]+\.[A-Za-z.]+)\b/g, (match, p1, p2, p3) => `${p1}***.${p2}*@***.${p3.split('.').slice(-1)}`);
    }
    return text;
}

function obfuscateContext(context) {
    const obfuscatedContext = {};
    for (const key in context) {
        if (typeof context[key] === 'string') {
            obfuscatedContext[key] = obfuscatePII(context[key]);
        } else {
            obfuscatedContext[key] = context[key];
        }
    }
    return obfuscatedContext;
}

const logger = {
    setLevel: (levelName) => {
        const level = logLevels[levelName];
        if (level !== undefined) {
            currentLogLevel = level;
        } else {
            console.warn(`Invalid log level: ${levelName}`);
        }
    },
    log: (level, message, context = {}) => {
        if (shouldLog(level)) {
            const obfuscatedMessage = obfuscatePII(message);
            const obfuscatedContext = obfuscateContext(context);
            const formattedMessage = `[${context.orderRef || 'No Ref'}] ${obfuscatedMessage}`;
            console.log(`[${level}] ${formattedMessage}`, obfuscatedContext); // Log to console
            if (isDatadogInitialized()) {
                datadogLogs.logger.log(level, formattedMessage, obfuscatedContext);
            }
        }
    },
    debug: function (message, context = {}) {
        this.log(logLevels.debug, message, context);
    },
    info: function (message, context = {}) {
        this.log(logLevels.info, message, context);
    },
    warn: function (message, context = {}) {
        this.log(logLevels.warn, message, context);
    },
    error: function (message, context = {}) {
        this.log(logLevels.error, message, context);
    },
};

export default logger;
