<template>
    <div>
      <!-- Overlay -->
      <div v-if="showPrompt" class="install-prompt-overlay" @click.self="dismissPrompt">
        <div
          class="install-prompt bubble fade show"
          :class="bubblePosition"
          role="alert"
          ref="bubble"
        >
          <button type="button" class="btn-close" aria-label="Close" @click="dismissPrompt"></button>
          <p v-html="promptMessage"></p>
          <!-- For Android devices with install prompt -->
          <button
            v-if="deferredPrompt"
            class="btn btn-primary"
            @click="installApp"
          >
            Add to Home Screen
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'InstallPrompt',
    data() {
      return {
        deferredPrompt: null,
        isIos: false,
        isAndroid: false,
        isDesktop: false,
        isInStandaloneMode: false,
        showPrompt: false,
        promptMessage: '',
        bubblePosition: '',
      };
    },
    mounted() {
      this.checkPlatform();
      window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
      // Invoke the prompt after a delay or based on some condition
      setTimeout(() => {
        this.invokePrompt();
      }, 2000);
    },
    beforeUnmount() {
      window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    },
    methods: {
      checkPlatform() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        this.isIos = /iphone|ipad|ipod/.test(userAgent);
        this.isAndroid = /android/.test(userAgent);
        this.isDesktop = !this.isIos && !this.isAndroid;
        this.isInStandaloneMode =
          ('standalone' in window.navigator && window.navigator.standalone) ||
          window.matchMedia('(display-mode: standalone)').matches;
      },
      handleBeforeInstallPrompt(event) {
        // Prevent the mini-infobar from appearing on mobile
        event.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = event;
      },
      invokePrompt() {
        // Show the prompt only if not in standalone mode
        if (!this.isInStandaloneMode) {
          this.setupPrompt();
          this.showPrompt = true;
        }
      },
      setupPrompt() {
        if (this.isIos) {
          this.promptMessage = `
            To install this app on your iPhone/iPad, tap the
            <strong>Share</strong> icon
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16">
              <path d="M13 1a1 1 0 0 1 1 1v4.793l-1.146-1.147a.5.5 0 0 0-.708.708L14.293 8l-2.147 2.146a.5.5 0 0 0 .708.708L14 9.207V14a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h11z"/>
            </svg>
            and then select <strong>Add to Home Screen</strong>.
          `;
          this.bubblePosition = 'bubble-bottom-center';
        } else if (this.isAndroid && this.deferredPrompt) {
          this.promptMessage = `
            To install this app on your Android device, tap
            <strong>Add to Home Screen</strong>.
          `;
          this.bubblePosition = 'bubble-bottom-center';
        } else if (this.isDesktop) {
          this.promptMessage = `
            To install this app, click the
            <strong>Install</strong> button in your browser's address bar.
          `;
          this.bubblePosition = 'bubble-top-right';
        }
      },
      dismissPrompt() {
        this.showPrompt = false;
      },
      async installApp() {
        if (this.deferredPrompt) {
          this.deferredPrompt.prompt();
          const { outcome } = await this.deferredPrompt.userChoice;
          if (outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
          this.deferredPrompt = null;
          this.dismissPrompt();
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .install-prompt-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Use a transparent background to allow clicks to dismiss the prompt */
    background: rgba(0, 0, 0, 0.0);
    z-index: 1050; /* Bootstrap modals have z-index of 1050 */
  }
  
  .install-prompt.bubble {
    position: absolute;
    max-width: 300px;
    padding: 15px;
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .bubble::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }
  
  /* Positioning for iOS and Android (bottom center) */
  .bubble-bottom-center {
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .bubble-bottom-center::after {
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px 10px 0 10px;
    border-color: #fff transparent transparent transparent;
  }
  
  /* Positioning for Desktop (top right) */
  .bubble-top-right {
    top: 10px;
    right: 10px;
  }
  
  .bubble-top-right::after {
    top: -10px;
    right: 20px;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
  }
  
  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  
  @media (prefers-color-scheme: dark) {
    .install-prompt.bubble {
      background: #333;
      color: #fff;
      border-color: #444;
    }
    .bubble-bottom-center::after,
    .bubble-top-right::after {
      border-color: transparent transparent #333 transparent;
    }
  }
  </style>
  