// utils/translation
export default class Translator {
  constructor(defaultLanguage = 'en', translations = {}, defaultTexts = {}, configTexts = {}) {
    this.currentLanguage = defaultLanguage;
    this.translations = translations;
    this.defaultTexts = defaultTexts;
    this.configTexts = configTexts;
  }

  setLanguage(language) {
    if (this.translations[language]) {
      this.currentLanguage = language;
    } else {
      console.warn(`Translations for ${language} not found`);
    }
  }

  translate(key) {
    try {
      if (this.currentLanguage === 'en' && this.configTexts) {
        if (this.configTexts[key] === '') {
          return '';
        }
        if (this.configTexts[key]) {
          return this.configTexts[key];
        }
      }
      if (this.translations && this.translations[this.currentLanguage] && this.translations[this.currentLanguage][key]) {
        return this.translations[this.currentLanguage][key];
      }
      if (this.defaultTexts && this.defaultTexts[key]) {
        return this.defaultTexts[key];
      }
    } catch (error) {
      console.error('Error translating key:', key, error);
    }
    return key;
  }

  addTranslations(language, newTranslations) {
    this.translations[language] = {
      ...this.translations[language],
      ...newTranslations
    };
  }

  addDefaultTexts(newDefaultTexts) {
    this.defaultTexts = {
      ...this.defaultTexts,
      ...newDefaultTexts
    };
  }

  setConfigTexts(configTexts) {
    this.configTexts = { ...this.configTexts, ...configTexts };
  }

  verifyTranslations() {
    const keys = Object.keys(this.defaultTexts);
    for (const lang in this.translations) {
      keys.forEach(key => {
        if (!this.translations[lang][key]) {
          throw new Error(`Missing translation for key: ${key} in language: ${lang}`);
        }
      });
    }
  }
}
