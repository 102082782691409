<template>
    <!--<CameraScanner @fullscreen-changed="handleFullscreenChange" :items="items" @scan="onScan"
        v-if="orderType?.barcodeScan" widthRem="30.8125" heightRem="10.9375" />-->
    <div class="shopping-bag" :class="{ hasScanner: spaceForScanner }">
        <h2>{{ cartTitle }} <span class="quan">({{ orderItems.length }})</span></h2>
        <CouponItem :coupon="coupon" :discounts="discounts" @remove="removeCoupon" v-if="coupon" />
        <div class="cart-items">
            <div class="cart-item" v-for="(item, index) in orderItems" :key="index">
                <img v-if="item.image" :src="imageUrl(item.image)" :alt="item.name" class="order-item-image" />
                <div class="cart-item-content">
                    <div class="cart-item-header">
                        <span class="order-item-name">{{ item.name }}</span>
                        <button class="delete-button" @click="removeItem(index)">
                            <SvgIcon name="delete" width="0.5rem" height="0.5rem" />
                        </button>
                    </div>
                    <div class="cart-item-footer">
                        <span class="order-item-price">{{ formatCurrency(item.price) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-bar">
            <div class="button-group">
                <button class="bottom-bar-button w-100" @click="$emit('reset')">{{ $t('startOverButtonText') }}</button>
                <button class="bottom-bar-button coupon w-100" v-if="hasCoupons" @click="applyCoupon" :disabled="coupon">
                    {{ $t('couponCode') }}
                </button>
            </div>
            <button @click="$emit('checkout')" v-if="subtotal" class="bottom-bar-button primary pay-button w-100">Pay {{
                formatCurrency(total) }}</button>
        </div>
    </div>
</template>

<script>
import { formatCurrency, imageUrl } from "@/utils/helpers";
import eventBus from '@/eventBus';
//import CameraScanner from "../../CameraScanner.vue";
import CouponItem from '@/components/features/order/CouponItem.vue'; // Import CouponItem component

export default {
    components: {
        //CameraScanner,
        CouponItem // Register CouponItem component
    },
    props: {
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        orderType: {
            type: Object,
            required: true,
        },
        cartTitle: {
            type: String,
            required: true,
        },
        orderItems: {
            type: Array,
            required: true,
        },
        coupon: { // Add coupon prop
            type: Object,
            default: () => null,
        },
        total: {
            type: Number,
            required: true,
        },
        discounts: { // Add discounts prop
            type: Number,
            default: 0,
        },
        hasCoupons: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['reset', 'checkout', 'barcode-scanned', 'removed', 'removeCoupon', 'applyCoupon'], // Add applyCoupon to emits
    data() {
        return {
            scannerFullscreen: false,
        };
    },
    computed: {
        subtotal() {
            return this.orderItems.reduce((total, item) => total + item.price, 0);
        },
        spaceForScanner() {
            return this.orderType?.barcodeScan && !this.scannerFullscreen;
        }
    },
    methods: {
        formatCurrency,
        imageUrl,
        onScan(scannedData) {
            this.$emit('barcode-scanned', scannedData);
            eventBus.emit('barcode-scanned', scannedData);
        },
        removeItem(key) {
            this.$emit('removed', key);
        },
        removeCoupon() { // Add removeCoupon method
            this.$emit('removeCoupon');
        },
        applyCoupon() { // Add applyCoupon method
            this.$emit('applyCoupon');
        },
        handleFullscreenChange(isFullscreen) {
            this.scannerFullscreen = isFullscreen;
        }
    }
};
</script>

<style scoped>
.shopping-bag {
    width: 30.8125rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.shopping-bag h2 {
    font-size: 1.625rem;
    line-height: 2.25rem;
    padding: 2rem 1.5rem 0rem 1.5rem;
}

.shopping-bag h2 .quan {
    font-weight: 400;
}

.shopping-bag.hasScanner {
    padding-top: 10.9375rem;
}

.cart-items {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 1.5rem;
    align-items: flex-start;
    flex-grow: 1;
}

.cart-items .cart-item {
    display: flex;
    border-radius: 1rem;
    background-color: #1B1B1F;
    margin-bottom: 1rem;
    width: 100%;
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
}

.order-item-image {
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    margin-right: 1rem;
}

.cart-item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
}

.cart-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-item-name {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 1.5rem);
}

.delete-button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.delete-button:hover,
.delete-button:focus {
    color: rgba(255, 255, 255, 1);
}

.cart-item-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.625rem;
}

.order-item-price {
    font-weight: bold;
}

.bottom-bar {
    border-top: 1px solid #2A2927;
    align-items: center;
    padding: 1.5rem;
    gap: 1rem;
}

.button-group {
    display: flex;
    font-size: 1.5rem !important;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.bottom-bar-button {
    margin: 0.5rem 0;
    padding: 1.5rem;
    line-height: 2.875rem;
    font-size: 1.525rem;
    color: #fff;
    border: 0.125rem solid #2A2927;
    border-radius: 1rem;
    box-sizing: border-box;
}

.bottom-bar-button.primary {
    background: var(--primary-color);
    color: var(--primary-standoff) !important;
}

.coupon:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pay-button {
    font-size: 2.125rem;
}
</style>
