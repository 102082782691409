<template>
    <div v-if="initialized">
        <LocationPicker v-if="activeLocation" :locations="locations" :active-location="activeLocation"
            @select-location="handleSelectLocation" />
        <div class="section-holder" ref="sectionHolder">
            <div class="section" ref="brandingSection">
                <h1>Branding</h1>
                <div class="logo-upload-section mb-4">
                    <label for="logoUpload" class="form-label d-block">
                        <strong>Location Logo</strong>
                    </label>
                    <div v-if="!config.logo?.url" class="image-upload-container">
                        <div class="drop-zone" @dragover.prevent="handleDragOver" @dragleave="handleDragLeave"
                            @drop="handleDrop" :class="{ 'drag-over': isDragOver }">
                            <p>Drag and drop images to upload</p>
                        </div>
                        <button type="button" class="btn btn-outline-primary mt-2" @click="triggerFileInput">
                            Select Image
                        </button>
                        <input type="file" class="d-none" id="logoUpload" ref="logo" name="logo"
                            @change="handleLogoUpload" />
                        <small class="form-text text-muted mt-2">Supported File Types: JPEG, PNG, GIF, BMP</small>
                    </div>
                    <div v-else class="form-group mt-3">
                        <LocationLogo :style="{ width: '8.25rem', height: '8.25rem' }" :logo="config.logo.url"
                            :shape="config.logo.shape || 'circle'" />
                        <label for="logoShape">Logo Shape</label>
                        <select v-model="config.logo.shape" class="form-control" id="logoShape"
                            @change="updateConfig('logo.shape', config.logo.shape)">
                            <option value="circle">Circle</option>
                            <option value="square">Square</option>
                        </select>
                        <button type="button" class="btn btn-outline-danger btn-sm mt-3" @click="removeLogo">
                            Delete Logo
                        </button>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="brandColor" class="form-label">Brand Color</label>
                    <div class="d-flex flex-wrap mb-2">
                        <div v-for="color in predefinedColors" :key="color.name" class="color-chip"
                            :style="{ backgroundColor: color.value }"
                            :class="{ 'selected': config.template.brandColor === color.value }"
                            @click="updateConfig('template.brandColor', color.value)" :title="color.name">
                        </div>
                    </div>
                    <div class="custom-color-section">
                        <label for="customBrandColor" class="form-label">Custom Color</label>
                        <input type="color" class="form-control form-control-color ml-2" id="customBrandColor"
                            v-model="config.template.brandColor"
                            @change="updateConfig('template.brandColor', config.template.brandColor)">
                        <small class="form-text text-muted mt-2">Select a custom color if none of the predefined colors
                            suit your needs.</small>
                    </div>
                </div>
            </div>

            <div class="section" ref="operationsSection" id="operationsSection">
                <h1>Operations</h1>
                <div class="form-group">
                    <label for="kioskStatus" class="form-label">Kiosk Terminals</label>
                    <div :class="['form-check form-switch', { 'active': config.active, 'inactive': !config.active }]">
                        <input class="form-check-input large-toggle" type="checkbox" id="kioskStatus"
                            v-model="config.active" @change="updateConfig('active', config.active)">
                        <label class="form-check-label large-label" for="kioskStatus">
                            {{ config.active ? 'Active' : 'Deactivated' }}
                        </label>
                        <div v-if="!config.active" @click.stop class="mt-3">
                            SkyTab Kiosk is deactivated. Customers <strong>can not</strong> place orders.

                            <label for="deactivatedMessage" class="form-label">Deactivation Message</label>
                            <input type="text" class="form-control" id="deactivatedMessage"
                                v-model="config.text.deactivatedMessage"
                                @keyup="updateConfig('text.deactivatedMessage', config.text.deactivatedMessage)">
                        </div>
                        <div v-else class="mt-3" @click.stop>
                            SkyTab Kiosk is <strong>active</strong>. Customers can place orders.
                        </div>
                    </div>
                </div>

                <CheckboxItem v-model="config.screensaverEnabled" label="Screensaver" :childrenShownOn="true"
                    helpText="Screensaver is a feature that displays a background image or video when the kiosk is not in use. This can be used to display a message or a video to the customer."
                    @update:modelValue="updateConfig('screensaverEnabled', $event)">
                    <div class="row" @click.stop>
                        <div class="col-md-6" v-if="config.screensaverEnabled">
                            <label for="screensaverBackgroundType" class="form-label">Screensaver Background
                                Type</label>
                            <select class="form-select" v-model="config.screensaverBackgroundType"
                                id="screensaverBackgroundType"
                                @change="updateConfig('screensaverBackgroundType', config.screensaverBackgroundType)">
                                <option value="particles">Particles</option>
                                <option value="video">Video</option>
                            </select>
                        </div>
                        <div class="col-md-6"
                            v-if="config.screensaverEnabled && config.screensaverBackgroundType === 'video'">
                            <label for="screensaverVideoSource" class="form-label">Screensaver Video Source</label>
                            <input type="text" class="form-control" id="screensaverVideoSource"
                                v-model="config.screensaverVideoSource"
                                @change="updateConfig('screensaverVideoSource', config.screensaverVideoSource)">
                        </div>
                        <div class="col-md-12" v-if="config.screensaverEnabled" style="margin-top: 1rem;">
                            <CheckboxItem v-model="config.screensaverShowText" label="Show Screensaver Text"
                                @update:modelValue="updateConfig('screensaverShowText', $event)">

                                <div v-if="config.screensaverShowText" @click.stop>
                                    <div class="mb-3">
                                        <label for="startOrder" class="form-label">Main Text</label>
                                        <input type="text" class="form-control" id="startOrder"
                                            v-model="config.text.startOrder"
                                            @keyup="updateConfig('text.startOrder', config.text.startOrder)">
                                    </div>
                                    <div class="mb-3">
                                        <label for="startOrderSubtext" class="form-label">Small Text</label>
                                        <input type="text" class="form-control" id="startOrderSubtext"
                                            v-model="config.text.startOrderSubtext"
                                            @keyup="updateConfig('text.startOrderSubtext', config.text.startOrderSubtext)">
                                    </div>
                                </div>

                            </CheckboxItem>
                        </div>
                    </div>
                </CheckboxItem>


                <CheckboxItem v-model="config.icons" label="Use Icons"
                    @update:modelValue="updateConfig('icons', $event)"
                    helpText="Icons are used to enhance the user experience by providing visual cues and icons.">
                </CheckboxItem>

                <CheckboxItem v-model="config.darkMode" label="Dark Mode"
                    @update:modelValue="updateConfig('darkMode', $event)"
                    helpText="Enable dark mode for a different color scheme and reduced eye strain in low-light environments.">
                </CheckboxItem>

            </div>

            <div class="section" ref="orderingSection" id="orderingSection">
                <h1>Ordering</h1>
                <div class="mb-3" v-for="(orderType, index) in config.orderTypes" :key="orderType.id">
                    <label for="orderType">{{ orderType.name }}</label>
                    <input type="text" class="form-control" id="orderType" v-model="orderType.name"
                        @change="updateConfig('orderTypes.' + index + '.name', orderType.name)">
                    <div class="form-check form-switch mt-2">
                        <input class="form-check-input" type="checkbox" id="orderTypeActive" v-model="orderType.active"
                            @change="updateConfig('orderTypes.' + index + '.active', orderType.active)">
                        <label class="form-check-label" for="orderTypeActive">
                            {{ orderType.active ? 'Active' : 'Inactive' }}
                        </label>
                    </div>
                </div>
            </div>

            <div class="section" ref="menuSection" id="menu">
                <h1>Menu View</h1>

                <div class="mb-3">
                    <label for="menuColumns" class="form-label">Menu Columns</label>
                    <select class="form-select" id="menuColumns" v-model="config.menu_cols"
                        @change="updateConfig('menu_cols', config.menu_cols)">
                        <option value="1">1 Column</option>
                        <option value="2">2 Columns</option>
                        <option value="3">3 Columns</option>
                    </select>
                    <small class="form-text text-muted">Control how many columns menu items display in on the kiosk.
                        More columns can show more items at once but may reduce readability.</small>
                </div>

                <CheckboxItem v-model="config.orderSummaryShowModifiers" label="Show Modifiers in Cart"
                    @update:modelValue="updateConfig('showModifiersInCart', $event)"
                    helpText="When enabled, modifiers will be displayed in the cart, making users' choices more clear. If disabled, only the item name and price will be shown." />

                <CheckboxItem v-model="config.allowSpecialRequests" label="Allow Special Requests"
                    @update:modelValue="updateConfig('allowSpecialRequests', $event)"
                    helpText="Enable this to allow customers to enter special requests (up to 32 characters) for their orders. Disable to remove the option for custom instructions." />

                <div class="mb-3">
                    <label for="idleTimeout" class="form-label">Idle Timeout (seconds)</label>
                    <div class="d-flex align-items-center">
                        <input type="number" class="form-control" id="idleTimeout" v-model="config.idleTimeoutSeconds"
                            @change="updateConfig('idleTimeoutSeconds', config.idleTimeoutSeconds)" min="0" step="30">
                        <button class="btn btn-outline-secondary ms-2"
                            @click="config.idleTimeoutSeconds = 0; updateConfig('idleTimeoutSeconds', 0)">Disable</button>
                    </div>
                    <small class="form-text text-muted">Set the time (in seconds) of inactivity that will trigger an
                        automatic reset. This helps when a customer walks away in the middle of an order. Set to 0 to
                        disable the timeout.</small>
                </div>

                <div class="mb-3">
                    <label for="cartTitle" class="form-label">Cart Text</label>
                    <input type="text" class="form-control" id="cartTitle" v-model="config.text.cartTitle"
                        @keyup="updateConfig('text.cartTitle', config.text.cartTitle)">
                    <small class="form-text text-muted">The text displayed at the top of the cart section.</small>
                </div>

                <div class="mb-3">
                    <label for="emptyCartMessage" class="form-label">Empty Cart Text</label>
                    <input type="text" class="form-control" id="emptyCartMessage" v-model="config.text.emptyCartMessage"
                        @keyup="updateConfig('text.emptyCartMessage', config.text.emptyCartMessage)">
                    <small class="form-text text-muted">The text displayed when the cart is empty.</small>
                </div>

                <div class="mb-3">
                    <label for="checkout" class="form-label">Checkout Text</label>
                    <input type="text" class="form-control" id="checkout" v-model="config.text.checkout"
                        @keyup="updateConfig('text.checkout', config.text.checkout)">
                    <small class="form-text text-muted">The text displayed on the checkout button.</small>
                </div>

            </div>
            <SaveAppComponent />
        </div>
    </div>
    <div v-if="!realtimeSaving" class="save-button-container">
        <button type="button" class="btn btn-primary" @click="saveConfig">Save Changes</button>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LocationPicker from '@/components/features/admin/LocationPicker.vue';
import LocationLogo from '@/components/global/LocationLogo.vue';
import CheckboxItem from '@/components/global/form/CheckboxItem.vue';
import SaveAppComponent from '@/components/SaveAppComponent.vue';

export default {
    name: 'SimpleAdmin',
    components: {
        LocationPicker,
        LocationLogo,
        CheckboxItem,
        SaveAppComponent
    },
    data() {
        return {
            predefinedColors: [
                { name: 'Black', value: '#232221' },
                { name: 'Shift4 Blue', value: '#126EF6' },
                { name: 'Forest Green', value: '#228B22' },
                { name: 'Crimson Red', value: '#DC143C' },
                { name: 'Goldenrod', value: '#DAA520' },
                { name: 'Dark Slate Blue', value: '#483D8B' },
                { name: 'Teal', value: '#008080' },
                { name: 'Chocolate', value: '#D2691E' },
                { name: 'Dark Olive Green', value: '#556B2F' },
                { name: 'Firebrick', value: '#B22222' },
                { name: 'Saddle Brown', value: '#8B4513' },
                { name: 'Midnight Blue', value: '#191970' },
            ],
            realtimeSaving: true,
            pendingConfig: {},
            isDragOver: false,
            isInSetupMode: false
        };
    },
    computed: {
        ...mapState("admin", ['activeCount', 'isLoggedIn', 'config', 'locations', 'activeLocation', 'initialized', 'accessList', 'schedules']),
    },
    methods: {
        ...mapActions("admin", ['updateConfigProperty', 'uploadLogo', 'deleteLogo', 'exitSetupMode', 'fetchLocations']),
        async loadSavedLocation() {
            try {
                const savedLocationId = localStorage.getItem('activeLocation');
                console.log('Saved location ID:', savedLocationId);
                
                if (savedLocationId) {
                    console.log('Fetching location for saved ID:', savedLocationId);
                    await this.fetchLocations();
                    const location = this.locations.find(loc => loc.id === parseInt(savedLocationId));
                    if (location) {
                        console.log('Setting active location:', location);
                        await this.$store.dispatch('admin/setActiveLocation', location);
                    } else {
                        console.error('Saved location not found in fetched locations');
                    }
                } else {
                    console.log('No saved location, fetching locations');
                    await this.fetchLocations();
                    console.log('Fetched locations:', this.locations);
                    
                    const activeLocations = this.locations.filter(location => location.active);
                    console.log('Active locations:', activeLocations);
                    
                    if (activeLocations.length > 0) {
                        console.log('Setting first active location:', activeLocations[0]);
                        await this.$store.dispatch('admin/setActiveLocation', activeLocations[0]);
                    } else if (this.locations.length > 0) {
                        console.log('No active locations, setting first location:', this.locations[0]);
                        await this.$store.dispatch('admin/setActiveLocation', this.locations[0]);
                    } else {
                        console.error('No locations available');
                        return;
                    }
                }
                
                if (this.$store.state.admin.activeLocation) {
                    console.log('Active location set:', this.$store.state.admin.activeLocation);
                    this.enterSetupMode('start');
                } else {
                    console.error('Failed to set active location');
                }
            } catch (error) {
                console.error('Error in loadSavedLocation:', error);
            }
        },
        updateConfig(key, value) {
            console.log('updateConfig', key, value);
            if (this.realtimeSaving) {
                this.updateConfigProperty({ key, value });
            } else {
                this.pendingConfig[key] = value;
            }
        },
        saveConfig() {
            for (const [key, value] of Object.entries(this.pendingConfig)) {
                this.updateConfigProperty({ key, value });
            }
            this.pendingConfig = {};
        },
        handleSelectLocation(location) {
            this.loading = true;
            localStorage.setItem('activeLocation', JSON.stringify(location));
            this.$store.dispatch('admin/setActiveLocation', location)
                .finally(() => {
                    this.loading = false;
                });
        },
        handleDragOver(event) {
            event.preventDefault();
            this.isDragOver = true;
        },
        handleDragLeave() {
            this.isDragOver = false;
        },
        async handleDrop(event) {
            event.preventDefault();
            this.isDragOver = false;
            const file = event.dataTransfer.files[0];
            if (file) {
                await this.uploadFile(file);
            }
        },
        async handleLogoUpload(event) {
            const file = event.target.files[0];
            if (file) {
                await this.uploadFile(file);
            }
        },
        async uploadFile(file) {
            const formData = new FormData();
            formData.append('file', file);
            try {
                const response = await this.uploadLogo(formData);
                if (response && response.url) {
                    await this.updateConfigProperty({ key: 'logo.url', value: response.url });
                }
            } catch (error) {
                console.error('Error uploading logo:', error);
                alert('Failed to upload logo. Please try again later.');
            }
        },
        async removeLogo() {
            if (this.config.logo?.url) {
                await this.deleteLogo({ url: this.config.logo.url });
                await this.updateConfigProperty({ key: 'logo.url', value: null });
            }
        },
        triggerFileInput() {
            this.$refs.logo.click();
        },
        enterSetupMode(screen) {
            this.isInSetupMode = true;
            this.$store.dispatch('admin/setSetupMode', {
                screen,
                payload: { active: true }
            });
        },
        handleIntersection(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const rect = entry.target.getBoundingClientRect();
                    if (rect.top <= window.innerHeight * 0.15) {
                        this.$refs.sectionHolder.querySelectorAll('.section').forEach(section => {
                            section.classList.remove('current');
                        });
                        entry.target.classList.add('current');
                        //this.loadInitialView(entry.target);
                    }
                }
            });
        },
        handleScroll() {
            const sectionHolder = this.$refs.sectionHolder;
            const sections = sectionHolder.querySelectorAll('.section');

            if (sectionHolder.scrollTop === 0) {
                sections.forEach(section => section.classList.remove('current'));
                sections[0].classList.add('current');
                this.loadInitialView(sections[0]);
            } else if (sectionHolder.scrollHeight - sectionHolder.scrollTop === sectionHolder.clientHeight) {
                sections.forEach(section => section.classList.remove('current'));
                sections[sections.length - 1].classList.add('current');
                this.loadInitialView(sections[sections.length - 1]);
            } else {
                // Recalculate active section based on the top 15% of the viewport
                this.observer.disconnect();
                sections.forEach(section => this.observer.observe(section));
            }
        },
        handleSectionClick(event) {
            const section = event.currentTarget;
            this.$refs.sectionHolder.querySelectorAll('.section').forEach(sec => {
                sec.classList.remove('current');
            });
            section.classList.add('current');
            this.loadInitialView(section);
        },
        loadInitialView(section) {
            if (section.id === 'operationsSection') {
                this.loadStartView();
            } else {
                this.enterSetupMode(section.id);
            }
        },
        setupIntersectionObserver() {
            const options = {
                root: null,
                rootMargin: '-15% 0px 0px 0px',
                threshold: 0 // Trigger as soon as the top of the section enters the top 15% of the viewport
            };
            this.observer = new IntersectionObserver(this.handleIntersection, options);

            setTimeout(() => {
                if (this.$refs.sectionHolder) {
                    const sections = this.$refs.sectionHolder.querySelectorAll('.section');
                    sections.forEach(section => {
                        this.observer.observe(section);
                        section.addEventListener('click', this.handleSectionClick);
                    });
                    this.$refs.sectionHolder.addEventListener('scroll', this.handleScroll);
                }
            }, 1000);
        },
        loadStartView() {
            this.enterSetupMode('start');
        },
        logout() {
            const currentRoute = this.$route.fullPath;
            this.$store.dispatch('admin/logout');
            this.$router.push({
                name: 'admin-login',
                query: { redirect: currentRoute }
            });
        },
    },
    mounted() {
        this.loadSavedLocation();
        window.addEventListener('pagehide', this.exitSetupMode);
        window.addEventListener('freeze', this.exitSetupMode);
        window.addEventListener('resign', this.exitSetupMode);
        this.setupIntersectionObserver();
    },
    unmounted() {
        window.removeEventListener('pagehide', this.exitSetupMode);
        window.removeEventListener('freeze', this.exitSetupMode);
        window.removeEventListener('resign', this.exitSetupMode);
        if (this.observer) {
            this.observer.disconnect();
        }
    }
}
</script>

<style scoped>
.section-holder {
    padding: 1rem;
}

.section {
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.section.active {
    border: 2px solid #126EF6;
    /* Example active class styling */
}

h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
}

.form-label {
    font-size: 1rem;
    color: #666;
}

.btn-outline-danger {
    border-radius: 5px;
    font-size: 0.875rem;
}

.save-button-container {
    text-align: right;
    padding: 1.5rem;
}

.image-upload-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    background-color: #f9f9f9;
    text-align: center;
}

.drop-zone {
    border: 2px dashed #bbb;
    padding: 2rem;
    text-align: center;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.drop-zone.drag-over {
    background-color: #e9e9e9;
}

.color-chip {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: transform 0.2s;
    box-shadow: 0 0 0 2px #fff;
    position: relative;
}

.color-chip.selected {
    transform: scale(1.1);
    box-shadow: 0 0 0 3px #126EF6;
}

.color-chip::after {
    content: attr(title);
    position: absolute;
    bottom: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
    font-size: 0.75rem;
    white-space: nowrap;
    display: none;
}

.color-chip:hover::after {
    display: block;
}

.form-check {
    margin-bottom: 1rem;
    border: 0.125rem solid rgba(0, 0, 0, 0.16);
    border-radius: 0.5rem;
    padding: 1rem;
}

.form-check.active {
    border-color: #28a745;
    /* Green border for active state */
    background-color: #d4edda;
    /* Light green background for active state */
}

.form-check.inactive {
    border-color: #dc3545;
    /* Red border for inactive state */
    background-color: #f8d7da;
    /* Light red background for inactive state */
}

.form-check-input {
    margin-left: 0 !important;
}

.form-check-label {
    font-size: 1rem;
    color: #333;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.custom-color-section {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.form-check-input.large-toggle {
    width: 2.5rem;
    height: 1.5rem;
    margin-left: 0 !important;
}

.form-check-input.large-toggle:checked {
    background-color: #28a745;
}

.form-check-label.large-label {
    font-size: 1.25rem;
    margin-left: 0.75rem;
    color: #333;
}

.section {
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: transform 0.3s ease;
    /* Add smooth scaling animation */
}

.section.current {
    border: 2px solid #126EF6;
    /* Example active class styling */
}
</style>