<template>
  <div class="checkout-view">
    <nav class="nav" role="navigation" aria-label="Navigation">
      <NavBar :showBack="showBack" @back="handleBack" />
    </nav>
    <div v-if="paymentTypes" :class="fadeInClass">
      <div v-if="!paymentType">
        <CenteredContainer>
          <!-- If no payment type is set on order -->
          <h3>{{ $t('howWouldYouLikeToPay') }}</h3>
          <br />
          <br />
          <div class="choice-hold row">
            <ButtonLarge v-for="paymentType in paymentTypes" :key="paymentType.type" :use-icon="config.icons !== ''"
              :name="paymentType.name" @buttonClicked="setPay(paymentType)" :icon="paymentType.icon" />
          </div>
        </CenteredContainer>
      </div>
      <div v-else>
        <CreditCard v-if="paymentType === 'CREDIT' && !selectingTip" @submit="onSubmit"
          @payment-error="handlePaymentError" @canGoBackChange="handleGoBack" :tip="tip" />
        <SelectTip v-if="paymentType === 'CREDIT' && selectingTip" @set="setTip" @cancel="setPay(null)"
          :locale="locale" />
        <OtherPayment v-if="paymentType === 'NON_CASH'" :payment-config="paymentConfig" @submit="onSubmit" />
        <PayUpFront v-if="paymentType === 'CASH'" @submit="onSubmit" />
      </div>
    </div>
    <div v-else>
      <NavBar :showBack="showBack" @back="handleBack" style="padding: 3.5rem; margin-bottom: -11rem; z-index: 1000" />
      <CenteredContainer>
        <h3>Payment methods not available</h3>
      </CenteredContainer>
    </div>
    <ErrorModal :visible="errorModalVisible" :message="errorMessage" @back="closeErrorModal" @startOver="reset"
      :allowBack="true" :allowStartOver="true" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonLarge from "@/components/global/ButtonLarge.vue";
import NavBar from "@/components/global/NavBar.vue";
import CreditCard from "@/components/features/checkout/CreditCard.vue";
import PayUpFront from "@/components/features/checkout/PayUpFront.vue";
import OtherPayment from "@/components/features/checkout/OtherPayment.vue";
import SelectTip from "@/components/features/checkout/SelectTip.vue";
import CenteredContainer from "@/components/global/CenteredContainer.vue";
import ErrorModal from "@/components/global/ErrorModal.vue";

export default {
  name: "CheckoutView",
  components: {
    CreditCard,
    PayUpFront,
    OtherPayment,
    SelectTip,
    ButtonLarge,
    NavBar,
    CenteredContainer,
    ErrorModal,
  },
  data() {
    return {
      selectingTip: false,
      paymentConfig: null,
      showBack: true,
      fadeInClass: "fade-in",
      errorModalVisible: false,
      errorMessage: '',
    };
  },
  methods: {
    closeErrorModal() {
      this.errorModalVisible = false;
      this.errorMessage = '';
      this.$router.push({ name: 'menu' });
    },
    handleGoBack(val) {

      this.showBack = val;
    },
    setTip(tip) {
      this.$store.commit("order/SET_TIP", tip);
      this.selectingTip = false;
    },
    setPay(type) {
      this.paymentConfig = type;
      this.$store.dispatch("order/setPaymentType", this.paymentConfig.type);
      const selectingTip = this.paymentConfig.type === 'CREDIT' && this.promptForTip;
      this.selectingTip = selectingTip;
    },
    handleBack() {
      console.log('handleBack', this.selectingTip, this.paymentType, this.paymentTypes.length)
      if (this.selectingTip) {
        console.log('backing out of tip');
        this.$store.dispatch("order/setPaymentType", null);
        this.selectingTip = false;
        if (this.paymentTypes.length === 1) {
          this.$router.back();
        }
        return;
      }

      if (this.paymentType == 'CREDIT' && this.promptForTip) {
        console.log('backing out credit');
        this.$store.dispatch("tip/init");
        this.setPay('CREDIT');
      } else if (this.paymentType) {
        console.log('backing out other payment type to choose screen');
        this.$store.dispatch("order/setPaymentType", null);
        if (this.paymentTypes.length === 1) {
          this.$router.back();
        }
      } else {
        console.log('backing out to menu');
        this.$router.back();
      }
    },
    handlePaymentError(error) {
      if (error.code === '100002') {

        if (this.promptForTip && !this.selectingTip) {
          this.selectingTip = true;
        } else if (this.paymentTypes.length > 1) {
          this.$store.dispatch("order/setPaymentType", null);
        } else {
          this.$router.push({ name: 'menu' });
        }
      }
    },
    async onSubmit() {
      try {
        const result = await this.$store.dispatch("order/submit");
        if (!result.success) {
          this.errorModalVisible = true;
          this.errorMessage = result.errorMessage;
        }
      } catch (error) {
        this.errorModalVisible = true;
        this.errorMessage = "An error occurred during the order submission.";
      }
    },
  },
  computed: {
    ...mapGetters(["hasSDK", "screenOrientation"]),
    ...mapGetters("order", ["type", "orderTypeId", "items", "subtotal", "tip", "paymentType", "paymentMethods", "gpattTicket"]),
    ...mapGetters("location", ["config"]),
    ...mapGetters("tip", {
      tipVisible: "visible",
    }),
    locale() {
      return this.config?.locale || 'EN-US';
    },
    paymentTypes() {
      const allowedPaymentTypes = this.orderType?.allowedPaymentTypes || [];
      return allowedPaymentTypes.map((pt) => this.config.paymentTypes.find((configPt) => configPt.type === pt));

    },
    orderType() {
      return this.config.orderTypes.find((ot) => ot.id === this.orderTypeId);
    },
    promptForTip() {
      return this.orderType.promptForTip;
    },
  },
  mounted() {

    this.$store.dispatch('tip/init');
    this.$store.dispatch("order/setTimeoutActive", true);

    if (this.paymentTypes.length === 1) {
      this.setPay(this.paymentTypes[0]);
    }

    setTimeout(() => {
      this.fadeInClass = "fade-in";
    }, 1000);
  },
  watch: {
    selectingTip(val) {
      if (val) {
        this.showBack = true;
      }
    },
    paymentType(val) {
      // Show back button after 10 seconds if credit card payment is selected
      if (val === 'CREDIT' && !this.selectingTip) {
        this.showBack = false;
        setTimeout(() => {
          this.showBack = true;
        }, 30000);
      } else {
        this.showBack = true;
      }
    },
  },
};
</script>

<style scoped>
.nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 2rem !important;
}

.choice-hold {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.choice-hold .btn-container {
  flex: 1 1 calc(33.33% - 7rem);
  /* Allow up to 3 buttons per line */
  width: 30rem !important;
  padding: 3.625rem !important;
  margin-right: 7rem;
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
}

.choice-hold .btn-container:last-child {
  margin-right: 0;
}

.btn-container {
  margin-right: 7.75rem;
}

.btn-container:last-child {
  margin-right: 0;
}

.vertical .choice-hold {
  flex-direction: column;
  align-items: center;
}

.vertical .btn-container {
  margin-right: 0;
  margin-bottom: 7.75rem !important;
}

.vertical .d-flex .col-6 {
  width: 100%;
  margin-bottom: 1rem;
}

.vertical .d-flex .col-6:last-child {
  margin-bottom: 0;
}
</style>
