export function isOpen(schedules) {

  if (!Array.isArray(schedules) || schedules.length === 0) {
    return false;
  }

  const now = new Date();
  const dayOfWeek = now
    .toLocaleString("en-us", { weekday: "long" })
    .toUpperCase();
  const currentTime = now.toTimeString().split(" ")[0];

  return schedules.some((schedule) => {
    const period = schedule.periods[dayOfWeek];
    return (
      period && currentTime >= period.startTime && currentTime <= period.endTime
    );
  });
}

export function openingSoonMessage(schedules, locale = "en-US") {
  const now = new Date();
  let closestOpeningTime = null;
  let closestDayOffset = null;

  for (let i = 0; i < 7; i++) {
    const futureDay = new Date(now.getTime() + i * 24 * 60 * 60 * 1000);
    const dayOfWeek = futureDay
      .toLocaleString(locale, { weekday: "long" })
      .toUpperCase();
    const currentTimeString = i === 0 ? now.toTimeString().split(" ")[0] : "00:00:00";

    schedules.forEach((schedule) => {
      const period = schedule.periods[dayOfWeek];
      if (
        period &&
        ((i === 0 && period.startTime > currentTimeString) || i > 0) &&
        (!closestOpeningTime || period.startTime < closestOpeningTime)
      ) {
        closestOpeningTime = period.startTime;
        closestDayOffset = i;
      }
    });


    if (closestOpeningTime) {
      break;
    }
  }

  if (!closestOpeningTime) {
    return "Currently, there are no upcoming opening times available.";
  }

  const openingTimeDate = new Date(`1970-01-01T${closestOpeningTime}`);
  const closestOpeningTimeFormatted = openingTimeDate.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' });

  if (closestDayOffset === 0) {
    return `Opening today at ${closestOpeningTimeFormatted}`;
  } else if (closestDayOffset === 1) {
    return `Opening tomorrow at ${closestOpeningTimeFormatted}`;
  } else {
    const futureDay = new Date(now.getTime() + closestDayOffset * 24 * 60 * 60 * 1000);
    const dayOfWeek = futureDay.toLocaleString(locale, { weekday: "long" });
    return `Opening ${dayOfWeek} at ${closestOpeningTimeFormatted}`;
  }
}


export function isScheduleOpen(schedule, currentTime) {
  const currentDay = currentTime
    .toLocaleString("en-US", { weekday: "long" })
    .toUpperCase();
  const currentTimeString = currentTime.toTimeString().split(" ")[0];
  const todaySchedule = schedule.periods[currentDay];

  return (
    todaySchedule &&
    currentTimeString >= todaySchedule.startTime &&
    currentTimeString < todaySchedule.endTime
  );
}
