<template>
  <div v-if="open" class="overlay"></div>
  <div v-if="open" class="dialog">
    <div>
      {{ message }}
    </div>
    <div class="dialog-buttons">
      <button v-if="showCancel" class="cancel" @click="cancel">{{ cancelText }}</button>
      <button @click="confirm">{{ okText }}</button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DialogComponent",
  computed: {
    ...mapGetters("dialog", ["open", "title", "message", "showCancel", "okText", "cancelText"]),
  },
  methods: {
    ...mapActions("dialog", ["cancelDialog", "confirmDialog"]),
    cancel() {
      this.cancelDialog();
    },
    confirm() {
      this.confirmDialog();
    },
  },
};
</script>
<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-8-overlay);
}

.dialog {
  padding: 3.75rem;
  font-size: 2rem;
  background: var(--primary-white);
  border-radius: var(--border-radius-xl);
  position: fixed;
  top: 50%;
  left: 50%;
  background: var(--primary-white);
  transform: translate(-50%, -50%);
  z-index: var(--z-index-8);
}

.dialog-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

button {
  border: none;
  cursor: pointer;
  background-color: var(--brand-color);
  color: var(--primary-white);
  padding: 1rem 2rem;
  border-radius: var(--border-radius-xxl);
  font-size: 2rem;
  margin-left: 1rem;
}

button.cancel {
  background-color: var(--primary-white);
  color: var(--brand-color);
  border: 0.125rem solid var(--brand-color);
}
</style>