<template v-if="visible && tipConfig">
  <QuestionAsker v-if="customTipShow" :questions="tipQuestion" @complete="submitCustomTip" @cancel="closeCustomTip" />

  <CenteredContainer>
    <h3>
      <ConfigText :initialValue="config.text.tipPrompt" textKey="tipPrompt" />
    </h3>
    <br />
    <p>
      <ConfigText :initialValue="config.text.tipQuestion" textKey="tipQuestion" />
    </p>

    <div class="tip-container" style="width: 57.5rem; margin-top: 3.125rem">
      <div class="row mb-4">
        <div v-for="(option, index) in tipOptions" :key="index" class="col-12 col-md-4 mb-3 mb-md-0">
          <ButtonLarge
            :defaultWidth="false"
            :percent="option.percent"
            :amount="option.amount"
            :selected="selectedTip === option.amount"
            :locale="locale"
            @buttonClicked="selectTip(option.amount)"
            style="width: 18.3125rem"
          />
        </div>
      </div>
      <div class="row mb-4 button-container">
        <div v-if="tipConfig.allowNoTip" class="button-wrapper">
          <ButtonLarge
            :defaultWidth="false"
            :name="$t('noTip')"
            @buttonClicked="skipTip"
            :fullWidth="true"
          />
        </div>
        <div v-if="tipConfig.allowCustomTip" class="button-wrapper">
          <ButtonLarge
            :defaultWidth="false"
            :name="$t('customTip')"
            @buttonClicked="askCustomTip"
            :fullWidth="true"
          />
        </div>
      </div>
      <div style="height: 8rem"></div>
    </div>
    <button class="btn btn-primary btn-lg w-100 pay-submit-button" @click="submitTip">
      {{ $t('continueButtonText') }}
    </button>
  </CenteredContainer>
</template>

<script>
import { mapGetters } from "vuex";
import { formatCurrency } from "@/utils/helpers";
import CenteredContainer from "@/components/global/CenteredContainer.vue";
import QuestionAsker from "../QuestionAsker.vue";
import ButtonLarge from "@/components/global/ButtonLarge.vue";

export default {
  components: {
    CenteredContainer,
    QuestionAsker,
    ButtonLarge,
  },
  data() {
    return {
      selectedTip: null,
      customTipShow: false,
      tipQuestion: [
        {
          inputType: "money",
          title: "Enter custom tip amount",
          placeholder: "0.00",
          required: false,
          set: "customTip",
          prefill: "0.00",
        },
      ],
    };
  },
  emits: ["set", "cancel"],
  props: {
    locale: {
      type: String,
      default: "EN-US",
    },
  },
  mounted() {
    this.setDefaultTip();
    if (this.tip) {
      this.selectedTip = this.tip;
    }
    this.$store.dispatch("tip/init");
  },
  methods: {
    formatCurrency,
    closeCustomTip() {
      this.customTipShow = false;
    },
    submitCustomTip(event) {
      if (!event[0]?.answer) {
        return;
      }

      const parsedAnswer = parseFloat(event[0]?.answer, 2);
      // tips are in pennies
      const customTip = parsedAnswer * 100;
      this.selectTip(customTip);
      this.submitTip();
    },
    handleBack() {
      this.selectedTip = null;
      this.$store.dispatch("tip/hide");
      this.$emit("cancel");
    },
    selectTip(amount) {
      this.selectedTip = amount;
    },
    submitTip() {
      this.$store.dispatch("tip/hide");
      this.$emit("set", this.selectedTip);
      this.selectedTip = null;
    },
    skipTip() {
      this.selectedTip = null;
      this.$store.dispatch("tip/hide");
      this.$emit("set", 0);
    },
    setDefaultTip() {
      if (this.config?.tip.options.length > 0 && this.config.tip.default >= 0 && this.config.tip.default < this.tipOptions.length) {
        const defaultTipAmount = this.tipOptions[this.config.tip.default].amount;
        this.selectTip(defaultTipAmount);
      }
    },
    async askCustomTip() {
      this.customTipShow = true;
    }
  },
  computed: {
    ...mapGetters(["screenOrientation"]),
    ...mapGetters("location", ["config"]),
    ...mapGetters("order", ["tip"]),
    ...mapGetters("tip", ["tipConfig", "tipOptions", "visible"]),
  },
};
</script>
<style scoped>
.hold {
  position: absolute;
  top: 0;
}

.tip-options {
  display: flex;
  gap: 1rem !important;
  justify-content: space-between;
  margin-bottom: 2.5rem;
}

.tip-options .tip-option {
  margin-right: 1rem !important;
}

.tip-options .tip-option:last-child {
  margin-right: 0;
}

.tip-option {
  flex: 1;
  gap: 1rem;
  cursor: pointer;
  display: flex;
  border-radius: var(--border-radius-lg);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 3.5rem 7rem;
  font-size: 3rem;
  width: 33.875rem;
  font-weight: bold;
  border: none;
  border-radius: var(--border-radius-xl);
  cursor: pointer;
  background-color: var(--primary-white);
  box-shadow: var(--box-shadow-large) !important;
  border: 0.125rem solid var(--grey-outline);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 5rem;
  transition: all 0.1s ease-in-out;
}



.percent {
  font-size: 3.75rem;
  font-weight: 900;
  color: var(--primary-color);
}

.amount {
  font-size: 2.125rem;
  color: var(--primary-color);
}

.btn-outline-primary {
  border-color: var(--primary-color);
  color: var(--primary-color);
  background-color: var(--primary-white);
  border-width: 2px;
  margin-bottom: 1rem;
}

.btn-outline-primary:active {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color);
  color: var(--primary-white) !important;
}

.tip-option:last-child {
  margin-right: 0;
}

.tip-option.selected {
  border-color: var(--primary-color);
  background-color: var(--primary-color-transparent);
}

.tip-option.selected * {
  color: var(--primary-color) !important;
}

.tip-container .row:last-child {
  margin-left: 0;
  margin-right: 0;
}

.tip-container .row:last-child .col-6 {
  padding-left: 0;
  padding-right: 0;
}

.tip-container .row:last-child .col-6:first-child {
  padding-right: 0.5rem !important;
}

.tip-container .row:last-child .col-6:last-child {
  padding-left: 0.5rem !important;
}

.button-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem; /* Add this line to create space between buttons */
}

.button-container .button-wrapper:last-child {
  margin-left: 1rem !important;
}

.button-wrapper {
  flex: 1;
  margin: 0;
  padding: 0;
}

.button-container > .button-wrapper:only-child {
  flex: 0 0 100%;
  margin: 0;
}

.button-container .name {
  margin-top: 0 !important;
}
</style>
