<template>
  <button 
    :aria-label="name" 
    :disabled="disabled" 
    @mousedown="handleInteractionStart"
    @mouseup="handleInteractionEnd"
    @touchstart="handleInteractionStart"
    @touchend="handleInteractionEnd"
    @touchcancel="handleInteractionEnd"
    :class="['btn-container', { 'active': isActive, 'selected': selected, 'full-width': fullWidth, 'default-width': defaultWidth }]">
    <SvgIcon v-if="icon && useIcon" width="12.5rem" height="12.5rem" :name="icon" />
    <div v-if="percent" class="percent">{{ formatPercent(percent) }}</div>
    <div v-if="amount" class="amount">{{ formatCurrency(amount) }}</div>
    <div v-else :class="['name', { 'with-icon': icon && useIcon }]">{{ name }}</div>
    <div v-if="extractedEmojis" class="emoji-container">{{ extractedEmojis }}</div>
    <div class="emoji-container" v-if="emojiString">{{ emojiString }}</div>
  </button>
</template>

<script>
import SvgIcon from "@/components/global/SvgIcon.vue";
import { formatCurrency } from "@/utils/helpers";

export default {
  name: "ButtonLarge",
  components: {
    SvgIcon,
  },
  props: {
    defaultWidth: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "",
    },
    useIcon: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    percent: {
      type: Number,
      default: null,
    },
    amount: {
      type: Number,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: "en-US",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    emojiString: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isActive: false,
      interactionStartTime: 0,
      debounceTimeout: null,
    };
  },
  methods: {
    handleInteractionStart(event) {
      event.preventDefault();
      if (!this.disabled) {
        this.isActive = true;
        this.interactionStartTime = Date.now();
      }
    },
    handleInteractionEnd() {
      if (!this.disabled) {
        const interactionDuration = Date.now() - this.interactionStartTime;
        const remainingTime = Math.max(0, 200 - interactionDuration);

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
          this.isActive = false;
          this.$emit("buttonClicked");
        }, remainingTime);
      } else {
        this.isActive = false;
      }
    },
    formatCurrency,
    formatPercent(value) {
      return this.locale === 'en-GB' ? `%${value}` : `${value}%`;
    },
    updateDisplayName(newDisplayName) {
      this.displayName = newDisplayName;
    },
  },
  computed: {
    extractedEmojis() {
      const colorEmojis = {
        white: '⚪️',
        red: '🔴',
        blue: '🔵',
        green: '🟢',
        yellow: '🟡',
        black: '⚫️',
        orange: '🟠',
        purple: '🟣',
        brown: '🟤',
      };

      const colorCodes = {
        '[w]': 'white',
        '[r]': 'red',
        '[b]': 'blue',
        '[g]': 'green',
        '[y]': 'yellow',
        '[k]': 'black',
        '[o]': 'orange',
        '[p]': 'purple',
        '[n]': 'brown',
      };

      let emojiString = '';
      let displayName = this.name;

      // Handle bracketed color codes - remove from display name
      for (const [code, color] of Object.entries(colorCodes)) {
        if (displayName.includes(code)) {
          emojiString += `${colorEmojis[color]} `;
          displayName = displayName.replace(code, '').trim();
        }
      }

      // Update displayName without affecting full color names
      this.updateDisplayName(displayName);

      // Handle full color names - keep in display name
      for (const [color, emoji] of Object.entries(colorEmojis)) {
        if (new RegExp(`\\b${color}\\b`, 'i').test(this.name)) {
          emojiString += `${emoji} `;
        }
      }

      return emojiString.trim();
    },
    computedFontSize() {
      const baseFontSize = 3; // base font size in rem
      return this.name.length > 14 ? `${baseFontSize * 0.6}rem` : `${baseFontSize}rem`;
    },
  },
  watch: {
    displayName: {
      handler(newValue) {
        this.$nextTick(() => {
          const textElement = this.$el.querySelector('div');
          if (textElement) {
            textElement.textContent = newValue;
          }
        });
      }
    }
  },
  
};
</script>

<style scoped>
.btn-container {
  padding: 3.5rem 7rem;
  font-size: inherit;
  width: 33.875rem;
  font-weight: bold;
  border: none;
  border-radius: var(--border-radius-xl);
  cursor: pointer;
  background-color: var(--primary-white);
  border: 0.125rem solid var(--grey-outline);
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.name {
  font-size: 2.125rem;
  font-weight: bold;
}

.name.with-icon {
  margin-top: 2rem;
}

.default-width {
}

.btn-container:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn-container.active {
  border-color: var(--primary-color);
  background-color: var(--primary-color-transparent);
  transform: scale(0.98);
  color: var(--primary-color) !important;
  transition: all 0.1s ease-in-out;
}

.btn-container.active .name {
  color: var(--primary-color) !important;
}


.btn-container.active span,
.btn-container.active ::v-deep(svg) {
  color: var(--primary-color);
  fill: var(--primary-color);
}

.btn-container.selected {
  border-color: var(--primary-color);
  background-color: var(--primary-color-transparent);
}

.btn-container.selected * {
  color: var(--primary-color) !important;
}

.percent {
  font-size: 3.75rem;
  font-weight: 900;
  color: var(--primary-color);
}

.amount {
  font-size: 2.125rem;
  color: var(--primary-color);
}



.btn-container.full-width {
  width: 100%;
}
</style>
