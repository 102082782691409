<template>
    <div class="location-info d-flex align-items-center">
        <!-- Logo on the left -->
        <div class="logo-container" v-if="logoUrl">
            <LocationLogo :logo="logoUrl" :shape="logoShape" :name="locationName" :disabled="false" />
        </div>

        <!-- Location details on the right -->
        <div class="location-details ms-3">
            <h1>{{ locationName }}</h1>
            <p>{{ fullAddress }}</p>
        </div>
    </div>
</template>

<script>
import LocationLogo from '@/components/global/LocationLogo.vue';

export default {
    name: 'LocationInfo',
    components: {
        LocationLogo
    },
    props: {
        logoUrl: {
            type: String,
            default: ''
        },
        logoShape: {
            type: String,
            default: 'circle'
        },
        locationName: {
            type: String,
            default: ''
        },
        fullAddress: {
            type: String,
            default: ''
        }
    },
};
</script>

<style scoped>
.location-info {
    padding: 1.875rem 2.5rem 2.25rem 2.5rem;
    margin-bottom: 0;
}

.logo-container {
    width: 5.84rem;
    height: 5.84rem;
    position: relative;
    margin-right: 1.5rem;
}

h1 {
    font-size: 2.5rem !important;
    line-height: 3.375rem !important;
}

p {
    font-size: 1.25rem !important;
    line-height: 1.70rem !important;
    color: var(--grey-medium) !important;
}

.ms-3 {
    margin-left: 0rem !important;
}
</style>