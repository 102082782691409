let inMemoryStorage = {};

function safelyPerformStorageOperation(operation, fallbackResult) {
  try {
    return operation();
  } catch (e) {
    
    return fallbackResult;
  }
}

function isWebStorageAvailable() {
  //eslint-disable-next-line no-undef
  return typeof android !== 'undefined' && android.getWebStorage;
}


// clear all local storage keys
export function clearAllData() {
  if (isWebStorageAvailable()) {
    const webStorage = getWebStorage();
    if (webStorage) {
      webStorage.clear();
    }
  }

  if (isLocalStorageAvailable()) {
    safelyPerformStorageOperation(
      () => localStorage.clear(),
      null
    );
  }

  if (isCookieAvailable()) {
    const cookies = document.cookie.split("; ");
    for (let c of cookies) {
      const eqPos = c.indexOf("=");
      const name = eqPos > -1 ? c.substr(0, eqPos) : c;
      eraseCookie(name);
    }
  }

  inMemoryStorage = {};
}

function getWebStorage() {
  return safelyPerformStorageOperation(
    //eslint-disable-next-line no-undef
    () => android.getWebStorage(),
    null
  );
}

function isLocalStorageAvailable() {
  return safelyPerformStorageOperation(() => {
    const testKey = "__test__";
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  }, false);
}

function isCookieAvailable() {
  document.cookie = "testcookie";
  return document.cookie.indexOf("testcookie") !== -1;
}

function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = name + '=; Max-Age=-99999999;';
}

function sanitizeKey(key) {
  return key.replace(/[^a-zA-Z0-9_-]/g, '');
}
function utf8ToB64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

function b64ToUtf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

function encryptData(data) {
  if (typeof data !== 'string') {
    data = JSON.stringify(data);
  }
  return utf8ToB64(data);
}

function decryptData(data) {
  if (typeof data !== 'string' || data.trim() === '') {
    console.warn('decryptData was given non-string or empty input.');
    return null;
  }

  try {
    return b64ToUtf8(data);
  } catch (e) {
    return null;
  }
}

export function saveData(key, data, days = 365 * 20) {
  key = sanitizeKey(key);
  const dataString = JSON.stringify(data);
  const encryptedData = encryptData(dataString);

  if (isWebStorageAvailable()) {
    const webStorage = getWebStorage();
    if (webStorage) {
      webStorage.setItem(key, encryptedData);
      return;
    }
  }

  if (isLocalStorageAvailable()) {
    safelyPerformStorageOperation(
      () => localStorage.setItem(key, encryptedData),
      null
    );
  } else if (isCookieAvailable()) {
    setCookie(key, encryptedData, days);
  } else {
    inMemoryStorage[key] = encryptedData;
  }
}

export function loadData(key, defaultValue) {
  key = sanitizeKey(key);
  let data;

  if (isWebStorageAvailable()) {
    const webStorage = getWebStorage();
    if (webStorage) {
      data = webStorage.getItem(key);
    }
  }

  if (!data && isLocalStorageAvailable()) {
    data = safelyPerformStorageOperation(
      () => localStorage.getItem(key),
      null
    );
  }

  if (!data && isCookieAvailable()) {
    data = getCookie(key);
  }

  if (!data) {
    data = inMemoryStorage[key];
  }

  if (data !== null && data !== undefined) {
    const decryptedData = decryptData(data);
    try {
      return JSON.parse(decryptedData);
    } catch (e) {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
}

export function removeData(key) {
  key = sanitizeKey(key);

  if (isWebStorageAvailable()) {
    const webStorage = getWebStorage();
    if (webStorage) {
      webStorage.removeItem(key);
      return;
    }
  }

  if (isLocalStorageAvailable()) {
    safelyPerformStorageOperation(
      () => localStorage.removeItem(key),
      null
    );
  } else if (isCookieAvailable()) {
    eraseCookie(key);
  } else {
    delete inMemoryStorage[key];
  }
}
