<template>
  <div :class="['custom-checkbox card', customClass, { 'selected': isSelected && !parentSelected }]">
    <div :class="['card-body', { 'check-border-bottom': hasSlotContent }]" @click="toggleSelection">
      <div class="the-check">
        <input type="checkbox" :checked="isSelected" class="form-check-input" />
        <div class="content">
          <label>{{ label }}</label>
          <p class="help-text" v-if="helpText">{{ helpText }}</p>
        </div>
      </div>
      <transition name="slide-fade">
        <div class="slot-content" v-if="hasSlotContent && childrenShownOn === modelValue">
          <slot :parentSelected="isSelected" @click.stop></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxItem",
  props: {
    childrenShownOn: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: Boolean,
    },
    label: {
      type: String,
      required: true,
    },
    helpText: {
      type: String,
      default: "",
    },
    parentSelected: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  emits: ['update:modelValue'],
  computed: {
    isSelected() {
      return this.modelValue;
    },
    hasSlotContent() {
      return !!this.$slots.default;
    },
  },
  methods: {
    toggleSelection() {
      this.$emit('update:modelValue', !this.isSelected);
    },
  },
};
</script>

<style scoped>
label,
input[type="checkbox"] {
  cursor: pointer;
}

.custom-checkbox {
  display: flex;
  width: 100%;
  border: 1px solid #d1d5db;
  border-radius: 0.625rem;
  margin-bottom: 1.25rem;
  cursor: pointer;
  font-size: 1.125rem;
  transition: border-color 0.3s ease; /* Add transition for border color */
}

.custom-checkbox .card-body {
  padding: 0;
}

.custom-checkbox.selected {
  border: 1px solid #007bff;
  overflow: hidden;
}

.custom-checkbox .the-check {
  display: flex;
  align-items: flex-start;
  padding: 1.25rem;
  transition: background-color 0.3s ease; /* Add transition for background color */
}

.custom-checkbox.selected>.card-body>.the-check {
  padding: 1.25rem;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  background-color: #e9f5ff;
}

.custom-checkbox.selected>.check-border-bottom.card-body>.the-check {
  border-bottom: 1px solid #007bff;
}

.custom-checkbox>.check-border-bottom.card-body>.the-check {
  border-bottom: none;
}

.slot-content {
  padding: 1.25rem;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.content {
  display: inline-block;
  margin-left: 0.75rem;
}

.help-text {
  font-size: 1.09375rem;
  color: #6c757d;
  margin-top: 0.3125rem;
}

.title {
  font-weight: bold;
}

.description {
  font-size: 1.09375rem;
  color: #6c757d;
}

.slot-content {
  background: white;
}

label {
  white-space: nowrap;
}

input[type="checkbox"] {
  margin-top: 0.3125rem;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.slot-content {
  overflow: hidden;
}
</style>