
import api from "@/utils/api";

export default {
  namespaced: true,
  state() {
    return {
      receipt: null,
      loadingReceipt: false,
      loadingRating: false,
      receiptError: null,
      locationId: null,
    };
  },
  mutations: {
    SET_RECEIPT(state, receipt) {
      state.receipt = receipt;
    },
    SET_LOCATION_ID(state, locationId) {
      state.locationId = locationId;
    },
    SET_LOADING_RECEIPT(state, loading) {
      state.loadingReceipt = loading;
    },
    SET_LOADING_RATING(state, loading) {
      state.loadingRating = loading;
    },
    SET_RECEIPT_ERROR(state, error) {
      state.receiptError = error;
    },
  },
  getters: {
    receipt(state) {
      return state.receipt;
    },
    loadingReceipt(state) {
      return state.loadingReceipt;
    },
    loadingRating(state) {
      return state.loadingRating;
    },
    receiptError(state) {
      return state.receiptError;
    },
  },
  actions: {
    async fetchReceipt({ commit }, orderRef) {
      commit("SET_LOADING_RECEIPT", true);
      try {
        const response = await api.callAPI("GET", `public/receipt/${orderRef}`);
        commit("SET_RECEIPT", response);
        commit("SET_LOCATION_ID", response.location.id);
      } catch (error) {
        commit("SET_RECEIPT_ERROR", error);
      } finally {
        commit("SET_LOADING_RECEIPT", false);
      }
    },
    async submitReview({ state, commit }, { orderRef, review }) {
      commit("SET_LOADING_RATING", true);
      try {
        await api.callAPI("POST", `/v2/${state.locationId}/order/review/`, { ref: orderRef, review });
        return true;
      } catch (error) {
        
        return false;
      } finally {
        commit("SET_LOADING_RATING", false);
      }
    },
  },
};
