<template>
    <div :class="['save-button-container', { 'sticky-bar': stickyBottom }]">
        <button type="button" class="btn btn-primary" :disabled="!isDirty || loading" @click="handleSave">
            <i v-if="!loading" class="bi bi-save"></i>
            <i v-else class="spinner-border spinner-border-sm"></i>
            Save Settings
        </button>
    </div>
</template>

<script>
export default {
    props: {
        isDirty: {
            type: Boolean,
            required: true
        },
        stickyBottom: {
            type: Boolean,
            default: false
        },
        serverDone: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false
        };
    },
    watch: {
        serverDone(newVal) {
            if (newVal) {
                this.loading = false;
                this.$emit('reset');
            }
        }
    },
    methods: {
        handleSave() {
            this.loading = true;
            this.$emit('save');
        }
    }
};
</script>

<style scoped>
button:disabled {
    opacity: 0.65;
}

.sticky-bar {
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 1rem;
    border-top: 1px solid #dee2e6;
    display: flex;
    justify-content: flex-end;
    z-index: 5000;
}

.spinner-border {
    width: 1rem;
    height: 1rem;
}
</style>
