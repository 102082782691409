<template>
  <div class="vh-100 d-flex justify-content-center align-items-center">
    <div class="d-flex flex-column align-items-center">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CenteredContainer",
};
</script>
