<template>
    <div class="spinner-container">
        <div v-html="fourSvg" v-if="show4" class="four-svg"></div>
        <img src="@/assets/spinner.png" class="spinner-image rotating" alt="Spinner" />
    </div>
</template>

<script>

import svgLib from "@/utils/svgLib";

export default {
    name: 'SpinnerComponent',
    props: {
        show4: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fourSvg: svgLib.four,
            spinnerSvg: svgLib.spinner,
        };
    },
};
</script>

<style scoped>
.spinner-container {
    position: relative;
    display: inline-block;
    width: 12.5rem;
    height: 12.5rem;
}

.four-svg {
    width: 7rem;
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.spinner-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    width: 100%;
    height: 100%;
}

.rotating {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
</style>