<template>
    <div class="rating-container">
        <SvgIcon v-for="n in 5" :key="n" :name="'faceRate' + n" :class="{ selected: n == selectedRating }"
            @click="submitRating(n)" />
    </div>
</template>

<script>
import confetti from 'canvas-confetti';

export default {
    props: {
        orderRef: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            selectedRating: 0,
            showThankYou: false,
        };
    },
    methods: {
        async submitRating(rating) {
            this.selectedRating = rating;
            this.showThankYou = true;

            if (rating >= 3) {
                this.launchConfetti();
            }

            await this.$store.dispatch("receipt/submitReview", {
                orderRef: this.orderRef,
                review: { rating },
            });

            setTimeout(async () => {
                try {
                    this.$router.push({ name: "start" });
                } catch (error) {
                    console.error("Error submitting rating", error);
                }
            }, 250);
        },
        launchConfetti() {
            const brandColor = getComputedStyle(document.documentElement).getPropertyValue('--brand-color').trim();
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 },
                colors: [brandColor]
            });
        },
    },
};
</script>

<style scoped>
.rating-container {
    display: flex;
    align-items: center;
}

.selected {
    color: var(--brand-color);
    fill: var(--brand-color);
    transform: scale(1.2);
    transition: transform 0.3s ease;
}

.thank-you-message {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: var(--brand-color);
}
</style>
