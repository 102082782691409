const start = {
    config: {
        active: true,
        text: {
            "deactivatedMessage": 'Not accepting orders at this time...'
        },
        screensaverEnabled: false,
        screensaverShowText: true,
        screensaverBackgroundType: "video",
        screensaverVideoSource: "path/to/video.mp4",
        logo: null,
        languageSupport: {
            defaultLanguage: "en",
            enabled: false,
            supportedLanguages: ["en", "es", "fr"]
        }
    },
    details: {
        name: "Sample Location"
    },
    orderTypes: [
        {
            type: "dine-in",
            name: "Dine In",
            icon: "forHere",
            orderDetails: [
                {
                    timing: "startOrder",
                    title: "Table Number",
                    inputType: "text",
                    required: true
                }
            ]
        },
        {
            type: "takeout",
            name: "Take Out",
            icon: "toGo"
        }
    ],
    questionsToAsk: [
        {
            title: "Email",
            text: "Please enter your email",
            inputType: "email",
            required: true
        }
    ],
    schedules: [
        {
          "ref": "54551e63-867c-4df4-b07b-1477fd67dd53",
          "name": "Main",
          "periods": {
            "FRIDAY": {
              "endTime": "23:30:00",
              "startTime": "08:00:00"
            },
            "MONDAY": {
              "endTime": "23:30:00",
              "startTime": "00:00:00"
            },
            "SUNDAY": {
              "endTime": "23:30:00",
              "startTime": "06:00:00"
            },
            "TUESDAY": {
              "endTime": "23:59:59",
              "startTime": "00:00:00"
            },
            "SATURDAY": {
              "endTime": "23:30:00",
              "startTime": "09:30:00"
            },
            "THURSDAY": {
              "endTime": "23:30:00",
              "startTime": "00:00:00"
            },
            "WEDNESDAY": {
              "endTime": "23:30:00",
              "startTime": "03:00:00"
            }
          },
          "departments": [
          ]
        }
    ],
    feVersion: "1.0.0",
    storeOpen: true,
    started: false,
    selectedOrderType: null,
    showScreensaverContent: false,
    fadeInClass: "fade-in",
    languageSelectorClass: "animate__animated animate__slideInDown",
    showQuestions: false,
    orderTypeRef: null
};

module.exports = {
    start
};
