<template>
  <CenteredContainer v-if="screenOrientation === 'vertical'" class="vertical">
    <div class="top">
      <h3>{{$t('payUpFrontTitle')}}</h3>
      <div class="payment-total">
        {{ formatCurrency(grandTotal) }}
      </div>
      <div class="payment-taxes">{{ paymentDetails }}</div>
    </div>
    <div class="middle">
      <SvgIcon name="pay-up-front" width="38.625rem" height="38.625rem" />
    </div>
    <div class="bottom">
      <p class="instructions">
        {{$t('payUpFrontInstructions')}}
      </p>
      <br />
      <br />
      <button class="btn btn-lg btn-primary d-block w-100 right" @click="submit">
        {{ $t('submitButtonText') }}
        <SvgIcon name="forward" width="3rem" height="3rem" />
      </button>
    </div>
  </CenteredContainer>
  <div v-else>
    <TwoPartLayout>
      <template v-slot:two>
        <div class="totals align-items-start">
          <h3>{{$t('payUpFrontTitle')}}</h3>
          <div class="payment-total">
            {{ formatCurrency(grandTotal) }}
          </div>
          <div class="payment-taxes">{{ paymentDetails }}</div>
        </div>
        <div class="instructions align-items-start">
          <p class="instructions">
            {{$t('payUpFrontInstructions')}}
          </p>
          <br />
          <br />
          <button class="btn btn-lg btn-primary d-block w-100 right" @click="submit">
            {{ $t('submitButtonText') }}
            <SvgIcon name="forward" width="3rem" height="3rem" />
          </button>
        </div>
      </template>
    </TwoPartLayout>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { formatCurrency, paymentDetailsAsString } from "@/utils/helpers";
import TwoPartLayout from "@/components/global/TwoPartLayout.vue";
import CenteredContainer from "@/components/global/CenteredContainer.vue";
export default {
  components: {
    TwoPartLayout,
    CenteredContainer
  },
  data() {
    return {};
  },
  emits: ["submit", "cancel"],
  methods: {
    formatCurrency,
    handleBack() {
      this.$emit("cancel");
    },
    submit() {
      this.$emit("submit");
    },
  },
  computed: {
    ...mapGetters(["screenOrientation"]),
    ...mapGetters("location", ["config"]),
    ...mapGetters("order", ["taxTotal", "subtotal", "grandTotal", "coupon", "discounts", "surcharges"]),
    paymentDetails() {
      return paymentDetailsAsString(this.subtotal, this.taxTotal, 0, this.discounts, this.surcharges);
    },
  },
};
</script>
<style scoped>
.payment-total {
  font-size: 6rem;
  font-weight: 700;
  color: var(--primary-color);
}

.payment-taxes {
  font-size: 1.5rem;
}

.instructions {
  margin-top: 2rem;
  font-size: 2.125rem;
  line-height: 3rem;
}

.right .svg-icon {
  margin-left: 1rem;
}

.vertical {
  padding: 0 2rem;
}

.vertical .top {
  text-align: center !important;
  margin-bottom: 6rem;
}

.vertical * {
  text-align: center;
}

.vertical .middle {
  margin-bottom: 2rem;
}

.vertical .bottom {
  text-align: center;
  margin-bottom: 2rem;
  width: 40vw;
}
</style>
