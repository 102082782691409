<template>
  <span :class="svgClass" v-html="svgContent" :style="iconStyle"></span>
</template>

<script>
import svgLib from "@/utils/svgLib";
import { camelize } from "vue";

export default {
  props: {
    name: {
      type: String,
      default: null
    },
    customClass: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      svgLib
    };
  },
  computed: {
    svgContent() {
      const icon = this.svgLib[camelize(this.name)];
      if (!icon) {
        
        return null;
      }
      return icon;
    },
    svgClass() {
      return ['svg-icon', this.customClass];
    },
    iconStyle() {
      return {
        width: this.width,
        height: this.height
      };
    }
  }
};
</script>

<style scoped>
.svg-icon {
  display: inline-block;
}
</style>
