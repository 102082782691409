<template>
    <div v-if="config">
        <p class="admin-instructions">Configure how orders are placed on the system and map them to fulfillment types
            and
            order types in the POS.</p>


        <order-type-list :order-types="orderTypes" @edit="editOrderType" @delete="deleteOrderType" />
        <br />
        <button class="btn btn-primary" style="float: right" @click="showModal('create')"
            :disabled="!canAddMoreOrderTypes">
            Create New Order Flow
        </button>
        <modal-component :is-visible="modalVisible" @close="closeModal">
            <order-type :order-type="currentOrderType" :payment-types="paymentTypes" @save="saveOrderType"
                @close="closeModal" />
        </modal-component>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import OrderTypeList from '@/components/features/admin/orderType/OrderTypeList.vue';
import OrderType from '@/components/features/admin/orderType/OrderType.vue';
import ModalComponent from '@/components/features/admin/ModalComponent.vue';

export default {
    components: { OrderTypeList, OrderType, ModalComponent },
    data() {
        return {
            modalVisible: false,
            currentOrderType: null,
            index: -1,
            orig: null
        };
    },
    mounted() {
        this.$store.dispatch('admin/fetchOrderTypes');
    },
    computed: {
        ...mapState('admin', ['config']),
        orderTypes() {
            return this.config ? this.config.orderTypes : [];
        },
        paymentTypes() {
            return this.config ? this.config.paymentTypes : [];
        },
        canAddMoreOrderTypes() {
            return this.orderTypes.length < 3;
        }
    },
    methods: {
        ...mapActions('admin', ['updateOrderType', 'addOrderType']),
        deleteOrderType(index) {

            if (window.confirm('Are you sure you want to delete this order type?')) {
                this.$store.dispatch('admin/deleteOrderType', { index });
            }
        },
        async saveOrderType(updated) {
            if (this.$store.state.admin.activeCount > 0) {
                console.log('Active users:', this.$store.state.admin.activeCount);
                const confirmation = window.confirm(
                    `There are ${this.$store.state.admin.activeCount} active users. Saving changes will log them out. Do you want to continue?`
                );
                if (!confirmation) {
                    return;
                }
            }

            console.log('saveOrderType called with:', updated);

            let resp = false;
            try {

                // make sure no duplicate order type names on create
                if (this.index < 0) {
                    const duplicateNames = this.orderTypes.filter(orderType => orderType.name === updated.name);
                    if (duplicateNames.length > 0) {
                        alert('Duplicate order type names are not allowed');
                        return;
                    }
                }

                if (this.index >= 0) {
                    console.log('Updating existing order type at index:', this.index);
                    resp = await this.updateOrderType({ index: this.index, orderType: updated });
                    console.log('Update response:', resp);
                } else {
                    console.log('Adding new order type');
                    resp = await this.addOrderType(updated);
                    console.log('Add response:', resp);
                }

                if (resp) {
                    console.log('Closing modal');
                    this.closeModal();
                } else {
                    if (this.index >= 0) {
                        console.log('Reverting to original order type at index:', this.index);
                        resp = await this.updateOrderType({ index: this.index, orderType: this.orig });
                        console.log('Revert response:', resp);
                    }
                    console.log('Save failed, not closing modal');
                }
            } catch (error) {
                console.error('Error in saveOrderType:', error);
            }
        },
        editOrderType(orderType, index) {
            this.orig = { ...orderType };
            this.showModal('edit', orderType, index);
        },
        showModal(mode, orderType = {}, index = -1) {
            this.currentOrderType = { ...orderType };
            this.index = index;
            this.modalVisible = true;
        },
        closeModal() {
            this.modalVisible = false;
            this.currentOrderType = null;
            this.index = -1;
        }
    }
}
</script>