<template>
  <template v-if="context === 'kiosk'">
    <link rel="stylesheet" href="./kiosk.css" @load="onStylesheetLoad">
    <link rel="stylesheet" href="./v3.css" @load="onStylesheetLoad">
    <DialogComponent />
    <div :class="[fadeInKiosk, 'app-container']">
      <template v-if="showPairing">
        <PairingView @pairingComplete="onPairingComplete" @close="onPairingClose" />
      </template>
      <template v-else>
        <div :class="{ 'dark-mode': this.config?.darkMode }">
          <TerminalStatusMenu :is-web-socket-connected="isWebSocketConnected" :initialized="initializedAndConnected"
            @attempt-reconnect="attemptReconnect" role="status" aria-live="polite" aria-label="Terminal Status" />

          <div class="full-screen app-container" :class="{
            vertical: screenOrientation == 'vertical'
          }" :style="dynamicStyles" role="document">

            <idle-dialog v-if="timeoutActive" @restart="handleIdleTimeout"
              :idle-timeout-seconds="config?.idleTimeoutSeconds || 30" :timeout-active="timeoutActive" />
            <dialog-component />
            <error-component role="alert" aria-live="assertive" aria-labelledby="errorTitle"
              aria-describedby="errorMessage" />
            <LoadingHandler :type="config?.loaderType || 'spinner'" :message="loadingMessage" :show="isLoading" />
            <router-view @show-pairing-view="showPairingView"></router-view>

          </div>
        </div>
      </template>
    </div>
  </template>

  <template v-else>
    <router-view></router-view>
  </template>
</template>

<script>
import { inject } from "vue";
import { mapGetters, mapActions } from "vuex";
import { hexToRGBA, lightenHexColor } from "./utils/helpers";
import PairingView from "./views/PairingView.vue";
import DialogComponent from "@/components/features/dialog/DialogComponent.vue";
import TerminalStatusMenu from "@/components/features/TerminalStatusMenu.vue";
import IdleDialog from "@/components/features/IdleDialog.vue";
import ErrorComponent from "@/components/features/ErrorComponent.vue";
import LoadingHandler from "@/components/features/LoadingHandler.vue";
import logger from "./utils/logger";

export default {
  name: "App",
  components: {
    PairingView,
    DialogComponent,
    TerminalStatusMenu,
    IdleDialog,
    ErrorComponent,
    LoadingHandler
  },
  data() {
    return {
      fadeInKiosk: 'fade-in',
      stylesLoaded: 0,
      showPairing: false,
    };
  },
  setup() {
    const translator = inject('translator');
    return { translator };
  },
  computed: {
    ...mapGetters(['context', 'isLoading', 'initialized', 'isWebSocketConnected', 'screenOrientation', 'loadingMessage']),
    ...mapGetters('location', ['config', 'brandColor']),
    ...mapGetters('terminal', ['terminalPaired', 'locationId']),
    ...mapGetters('order', ['type', 'timeoutActive', 'started', 'orderTypeId', 'confirmed', 'appRef']),
    initializedAndConnected() {
      return this.initialized && this.isWebSocketConnected;
    },
    adjustedBrandColor() {
      const blackColors = ['#000', '#000000', 'black'];
      return blackColors.includes(this.brandColor.toLowerCase()) ? '#ffffff' : this.brandColor;
    },
    dynamicStyles() {
      return {
        "--brand-color": this.adjustedBrandColor,
        "--primary-color-darker": hexToRGBA(this.adjustedBrandColor, 0.8),
        "--bs-primary": this.adjustedBrandColor,
        "--primary-color": this.adjustedBrandColor,
        "--primary-color-transparent": hexToRGBA(this.adjustedBrandColor, 0.05),
        "--primary-color-light": lightenHexColor(this.adjustedBrandColor, 90),
      };
    },
  },
  watch: {
    'config'(newValue) {
      if (!newValue?.active) {
        this.$router.push({ name: 'start' });
      }
      if (newValue?.darkMode) {
        document.documentElement.classList.add('dark-mode');
      } else {
        document.documentElement.classList.remove('dark-mode');
      }
    },
  },
  methods: {
    ...mapActions('terminal', ['checkAuth']),
    ...mapActions(['initializeLocation']),
    onStylesheetLoad() {
      this.stylesLoaded += 1;
      if (this.stylesLoaded === 2) {
        setTimeout(() => {
          this.fadeInKiosk = '';
        }, 200);
      }
    },
    onPairingComplete() {
      this.showPairing = false;
      this.initializePairedKiosk();
    },
    onPairingClose() {
      // safe-reload the page
      window.location.reload();
    },
    async initializePairedKiosk() {
      this.$store.dispatch('setTranslator', { translator: this.translator });
      await this.initializeLocation(this.locationId);
    },
    async attemptReconnect() {
      logger.info('Attempting to reconnect...');
      if (!navigator.onLine) {
        logger.error('No internet connection.');
        alert('Not connected to the internet.');
        return;
      }
      try {
        await this.$store.dispatch('retryConnectWebsocket');
        logger.info('WebSocket reconnection attempted');
      } catch (error) {
        logger.error('Error attempting to reconnect:', error);
      }
    },
    async handleIdleTimeout() {
      if (this.timeoutActive) {
        logger.info('Handling idle timeout, initiating reset.');
        this.reset("idle");
      }
    },
    async reset(source) {
      if (this.started) {
        await this.$store.dispatch("order/resetOrder", source);
        this.$router.push({ name: "start" });
      }
    },
    showPairingView() {
      this.showPairing = true;
    },
    checkOrientation() {
      const orientation =
        window.innerWidth > window.innerHeight ? "horizontal" : "vertical";
      this.$store.commit("SET_SCREEN_ORIENTATION", orientation);
    },
  },
  async mounted() {
    if (this.context === 'kiosk') {

      if (this.$store.state.terminal.pairingMethod === 'mid') {
        // Load kiosk app in preview mode
        this.initializing = true;
        await this.$store.dispatch("loadPublicLocationData", this.locationId);
        this.initializing = false;
        return;
      }

      // always reset to start view
      this.$router.push({ name: "start" });


      let isAuthenticated = !this.terminalPaired || !await this.checkAuth();
      if (isAuthenticated) {
        // try pairing by serial
        await this.$store.dispatch('terminal/registerTerminalSerial');
        isAuthenticated = !this.terminalPaired || !await this.checkAuth();
      }

      this.checkOrientation();
      window.addEventListener("resize", this.checkOrientation);
      if (this.config?.darkMode) {
        document.documentElement.classList.add('dark-mode');
      }

      this.showPairing = isAuthenticated;

      const links = document.querySelectorAll('link[rel="stylesheet"]');
      links.forEach(link => {
        if (link.sheet) {
          this.onStylesheetLoad();
        }
      });
      if (!this.showPairing) {
        this.initializePairedKiosk();
      }
    }
  },
};
</script>

<style>
.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
</style>
