<template>
  <div class="terminal-management">
    <div v-if="isAdmin" class="card shadow-sm mb-4">
      <div class="card-body">
        <h5 class="card-title">Add New Terminal</h5>
        <p class="text-muted mb-3">Admins can add and delete terminals. Enter the terminal name below.</p>
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Enter terminal name" v-model="newTerminalName" aria-label="New Terminal Name">
          <button type="button" class="btn btn-primary" @click="addTerminal">Add Terminal</button>
        </div>
      </div>
    </div>

    <div v-if="unregisteredTerminals.length" class="card shadow-sm mb-4">
      <div class="card-body">
        <h5 class="card-title">Unregistered Terminals</h5>
        <p class="text-muted mb-3">Use the PIN code to register these terminals on your kiosk.</p>
        <ul class="list-group list-group-flush">
          <li v-for="terminal in unregisteredTerminals" :key="terminal.id" class="list-group-item d-flex justify-content-between align-items-center">
            <div>
              <strong>{{ terminal.name }}</strong>
              <span class="badge bg-secondary ms-2" v-if="terminal.pin">PIN CODE: {{ terminal.pin }}</span>
              <span class="badge bg-secondary ms-2" v-else>Awaiting Registration</span>
            </div>
            <button class="btn btn-ouline-danger btn-sm" @click="confirmDelete(terminal, false)"><SvgIcon name="delete" /></button>
          </li>
        </ul>
      </div>
    </div>

    <div class="card shadow-sm">
      <div class="card-body">
        <h5 class="card-title">Registered Terminals</h5>
        <ul class="list-group list-group-flush">
          <li v-for="terminal in registeredTerminals" :key="terminal.id" class="list-group-item d-flex justify-content-between align-items-center">
            <div>
              <span :class="['terminal-status', terminal.connected ? 'connected' : 'disconnected']"></span>
              <strong>{{ terminal.name }}</strong>
            </div>
            <div class="d-flex align-items-center">
              <span class="version-pill">Version: {{ terminal.version }}</span>
              <span class="last-seen-pill">{{ formatLastSeen(terminal.last_called_at) }}</span>
              <button class="btn btn-outline-danger btn-sm ms-2" @click="confirmDelete(terminal, true)" v-if="isAdmin">
                <SvgIcon name="delete" />
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      newTerminalName: '',
    };
  },
  mounted() {
    this.$store.dispatch('admin/fetchTerminals');
  },
  computed: {
    ...mapGetters("admin", ['isAdmin']),
    unregisteredTerminals() {
      return this.terminals?.filter(t => !t.registered) || [];
    },
    registeredTerminals() {
      return this.terminals?.filter(t => t.registered) || [];
    },
    terminals() {
      return this.$store.state.admin.terminals;
    }
  },
  methods: {
    addTerminal() {
      if (this.newTerminalName.trim()) {
        const terminalData = {
          name: this.newTerminalName,
          locationId: this.$store.state.admin.activeLocation
        };
        this.$store.dispatch('admin/addTerminal', terminalData);
        this.newTerminalName = ''; // Clear the input after adding
      }
    },
    confirmDelete(terminal, isRegistered) {
      const message = isRegistered
        ? "Are you sure? This will unlink and reset the terminal. You will need to re-register on premise."
        : "Are you sure you want to delete this terminal?";
      if (confirm(message)) {
        this.$store.dispatch('admin/deleteTerminal', terminal.id);
      }
    },
    saveTerminals() {
      this.$store.dispatch('admin/fetchTerminals');
    },
    formatLastSeen(lastSeenDate) {
      const now = new Date();
      const lastSeen = new Date(lastSeenDate);
      const diffTime = Math.abs(now - lastSeen);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
      if (diffDays <= 1) {
        return "Today";
      }
      return `${diffDays} days ago`;
    }
  }
};
</script>

<style scoped>

.card {
  box-shadow: none !important;
}

.input-group .form-control {
  border-radius: 0.25rem;
}

.list-group-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.terminal-status {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.connected {
  background-color: green;
}

.disconnected {
  background-color: grey;
}

.card {
  border-radius: 0.5rem;
}

.card-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.text-muted {
  font-size: 0.9rem;
}

.badge {
  font-size: 1rem;
  padding: 0.5em;
}

.version-pill {
  background-color: #6c757d;
  color: white;
  border-radius: 50px;
  padding: 0.25em 0.75em;
  font-size: 0.9rem;
  margin-right: 10px;
  display: inline-block;
}

.last-seen-pill {
  background-color: #adb5bd;
  color: white;
  border-radius: 50px;
  padding: 0.25em 0.75em;
  font-size: 0.9rem;
  margin-right: 10px;
  display: inline-block;
}

</style>
