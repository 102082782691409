<template>
    <div class="order-item animate__animated animate__backInUp">
        <img v-if="orderItem && orderItem.image" class="item-image" :src="imageUrl(orderItem.image)" :alt="orderItem.name"
            style="width: 5rem; float: left; border-radius: 5px; margin-right: 10px;" />
        <div class="details" @click="editItem">
            <span class="name" role="button" tabindex="0">{{ orderItem.name }}</span>
            <span :class="{'choices': true, 'with-image': orderItem && orderItem.image}" v-html="formatChoices(orderItem.lineModifierSets)"></span>
            <span class="special-requests" v-if="orderItem.instructions">
                <strong>Special Request:</strong> {{ orderItem.instructions }}</span>
        </div>
        <div class="controls">
            <div class="price">{{ formatCurrency(orderItem.total) }}</div>
            <QuantitySelector v-if="showQuantityEdit" :base-font-size="4" :value="orderItem.quantity"
                @quantity="updateQuantity" style="margin-right: 2rem;" />
            <button @click="removeItem" class="remove" aria-label="Remove item">
                <SvgIcon name="close" aria-hidden="true" width="2.5625rem" height="2.5625rem" />
            </button>
        </div>
    </div>
</template>
<script>
import { formatCurrency, imageUrl } from '@/utils/helpers';
import QuantitySelector from '@/components/features/itemOrder/QuantitySelector.vue';

export default {
    props: {
        orderItem: {
            type: Object,
            required: true,
        },
        itemIndex: {
            type: Number,
            required: true,
        },
        showQuantityEdit: {
            type: Boolean,
            default: true,
        },
        showItemDetails: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        QuantitySelector,
    },
    emits: ['remove', 'edit'],
    methods: {
        imageUrl,
        formatCurrency,
        editItem() {
            this.$emit('edit', { itemIndex: this.itemIndex, item: this.orderItem });
        },
        removeItem() {
            this.$emit('remove');
        },
        updateQuantity(newQuantity) {
            this.$store.dispatch('order/updateItemQuantity', {
                itemIndex: this.itemIndex,
                quantity: newQuantity
            });
        },
        formatChoices(lineModifierSets) {
            if (!this.showItemDetails || !lineModifierSets) {
                return '';
            }

            const formatModifiers = (modifiers, indentLevel) => {
                if (!modifiers || modifiers.length === 0) return '';

                return modifiers.map(modifier => {
                    const nestedSets = formatModifierSets(modifier.lineModifierSets, indentLevel + 1);
                    return `
                        <div style="margin-left: ${indentLevel}rem;">
                            ${modifier.name} ${modifier.price > 0 ? `(+ ${this.formatCurrency(modifier.price)})` : ''} 
                            ${nestedSets}
                        </div>
                    `;
                }).join('');
            };

            const formatModifierSets = (modifierSets, indentLevel) => {
                if (!modifierSets || modifierSets.length === 0) return '';

                return modifierSets.map(set => {
                    const modifiers = formatModifiers(set.lineModifiers, indentLevel + 1);
                    return `
                        <div style="margin-left: ${indentLevel}rem;">
                            ${set.name ? `<strong>${set.name}:</strong>` : ''}
                            ${modifiers}
                        </div>
                    `;
                }).join('');
            };

            return formatModifierSets(lineModifierSets, 0);
        },
    },
};
</script>



<style scoped>
.order-item {
    background-color: var(--primary-white);
    margin: 1.5rem 2.25rem;
    padding: 1.5rem 1.875rem;
    border: 0.125rem solid var(--grey-outline);
    border-radius: var(--border-radius-lg);
    animation-duration: 0.2s;
}

.name {
    font-size: 1.25rem;
    font-weight: 700;
}

.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.price {
    font-size: 1.25rem;
    flex-grow: 1;
    align-self: flex-end;
}

.choices {
    display: block;
    margin-top: 0.5rem;
    color: var(--grey-dark);
}

.choices.with-image {
    margin-left: 6rem; /* Adjust this value as needed */
}
</style>

