<template>
    <div>
        <form @submit.prevent="saveSettings" v-if="config">
            <h2 class="mb-4">Tip Settings</h2>
            <div class="row mb-3">
                <div class="col">
                    <label for="tipOption1" class="form-label">Tip Option 1 (%)</label>
                    <input type="number" class="form-control" id="tipOption1" v-model="settings.tip.options[0]" min="0"
                        max="100">
                    <div v-if="settings.tip.options[0] < 0 || settings.tip.options[0] > 100" class="text-danger">
                        Please enter a value between 0 and 100.
                    </div>
                </div>
                <div class="col">
                    <label for="tipOption2" class="form-label">Tip Option 2 (%)</label>
                    <input type="number" class="form-control" id="tipOption2" v-model="settings.tip.options[1]" min="0"
                        max="100">
                    <div v-if="settings.tip.options[1] < 0 || settings.tip.options[1] > 100" class="text-danger">
                        Please enter a value between 0 and 100.
                    </div>
                </div>
                <div class="col">
                    <label for="tipOption3" class="form-label">Tip Option 3 (%)</label>
                    <input type="number" class="form-control" id="tipOption3" v-model="settings.tip.options[2]" min="0"
                        max="100">
                    <div v-if="settings.tip.options[2] < 0 || settings.tip.options[2] > 100" class="text-danger">
                        Please enter a value between 0 and 100.
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label for="defaultTip" class="form-label">Default Tip (%)</label>
                <select class="form-select" id="defaultTip" v-model="settings.tip.default"
                    :aria-describedby="'defaultTipHelp'">
                    <option v-for="(option, index) in settings.tip.options" :key="option" :value="index">{{ option }}%
                    </option>
                </select>
                <small id="defaultTipHelp" class="form-text text-muted">Select the default tip percentage to be
                    suggested to customers.</small>
            </div>
            <div class="mb-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="settings.tip.allowNoTip" id="allowNoTip">
                    <label class="form-check-label" for="allowNoTip">Allow No Tip</label>
                </div>
                <small class="form-text text-muted">Allow customers to choose not to leave a tip.</small>
            </div>
            <div class="mb-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="settings.tip.allowCustomTip"
                        id="allowCustomTip">
                    <label class="form-check-label" for="allowCustomTip">Allow Custom Tip</label>
                </div>
                <small class="form-text text-muted">Allow customers to enter a custom tip amount.</small>
            </div>
            <div class="mb-3">
                <label for="tipCalculationMethod" class="form-label">Tip Calculation Method</label>
                <select class="form-select" id="tipCalculationMethod" v-model="settings.tip.calculationMethod">
                    <option value="includeTaxes">Include taxes when calculating tip suggestions</option>
                    <option value="excludeTaxes">Do not include taxes when calculating tip suggestions</option>
                </select>
            </div>
            <!--
            <h2 class="mt-5 mb-4">Payment Types</h2>
            <div v-for="(paymentType, index) in settings.paymentTypes" :key="index" class="mb-4">
                <div class="mb-3" v-if="paymentType.icon">
                    <SvgIcon :name="paymentType.icon" width="24" height="24" />
                </div>
                <div class="mb-3">
                    <label :for="'name-' + index" class="form-label">Name</label>
                    <input type="text" class="form-control" :id="'name-' + index" v-model="paymentType.name"
                        placeholder="Enter payment type name">
                </div>
                <div class="mb-3">
                    <label :for="'instructions-' + index" class="form-label">Instructions</label>
                    <textarea class="form-control" :id="'instructions-' + index" v-model="paymentType.instructions"
                        placeholder="Enter instructions"></textarea>
                </div>
            </div>
            -->
            <save-button :isDirty="isSettingsDirty" @save="saveSettings" :stickyBottom="true"
                :serverDone="serverDone"></save-button>
        </form>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SaveButton from '@/components/features/admin/SaveButton.vue';

export default {
    components: {
        SaveButton
    },
    computed: {
        ...mapState('admin', ['config', 'isAdmin']),
        settings: {
            get() {
                return this.config || {};
            },
            set(value) {
                this.setConfig(value);
            }
        },
        isSettingsDirty() {
            return JSON.stringify(this.config) !== JSON.stringify(this.originalSettings);
        },
    },
    data() {
        return {
            serverDone: false,
            originalSettings: null,
            editingField: '',
            defaultAdvantageProgramSettings: {
                enabled: false,
                type: '',
                priceAdjustmentPercentage: null,
                feePercentage: null,
                ticketDescription: '',
                discountPercentage: null
            }
        };
    },
    methods: {
        ...mapActions('admin', ['fetchConfig', 'saveConfig', 'setConfig']),
        isValidPercentage(value) {
            if (value === null || value === undefined) return true;
            const regex = /^\d+(\.\d{1,2})?$/;
            return regex.test(value);
        },
        validateSettings() {
            const { tip, advantageProgram } = this.settings;
            const validTipOptions = tip.options.every(option => option >= 0 && option <= 100);
            const validAdvantageProgram = !advantageProgram.enabled || (
                (advantageProgram.type === 'dualPricing' && this.isValidPercentage(advantageProgram.priceAdjustmentPercentage)) ||
                (advantageProgram.type === 'supplementalFee' && this.isValidPercentage(advantageProgram.feePercentage)) ||
                (advantageProgram.type === 'cashDiscount' && this.isValidPercentage(advantageProgram.discountPercentage))
            );
            return validTipOptions && validAdvantageProgram;
        },
        saveSettings() {
            if (!this.validateSettings()) {
                return;
            }

            this.serverDone = false;
            if (!this.config.advantageProgram) {
                this.$set(this.config, 'advantageProgram', this.defaultAdvantageProgramSettings);
            }
            this.saveConfig(this.config).then(() => {
                this.originalSettings = JSON.parse(JSON.stringify(this.config));
            })
            .finally(() => {
                this.serverDone = true;
            })
        }
    },
    async created() {
        await this.fetchConfig();
        if (!this.config.advantageProgram) {
            this.$set(this.config, 'advantageProgram', this.defaultAdvantageProgramSettings);
        }
        this.originalSettings = JSON.parse(JSON.stringify(this.config));
    }
};
</script>

<style scoped>
h2 {
    margin-top: 2rem;
}

.float {
    width: 8rem;
}

.icon-pay-up-front {
    /* Add your custom icon styles here */
}

.icon-pay-credit {
    /* Add your custom icon styles here */
}
</style>
