<template>
  <div class="step-component">
    <div class="card">
      <!-- Header with Title and Progress Bar -->
      <div class="card-header">
        <div class="top-section" v-if="currentStep">

          <button class="circle-button" v-if="showBackButton" @click="goBack">
            <SvgIcon width="1.5rem" height="1.5rem" name="back" />
          </button>
          <span class="steps-text">{{ currentStep }} of {{ steps.length-1 }}</span>
        </div>
        <!--<h5 class="m-0 text-center">{{ title }}</h5>
        <div class="progress my-2">
          <div class="progress-bar" role="progressbar" :style="{ width: progressPercentage + '%' }"
            :aria-valuenow="progressPercentage" aria-valuemin="0" aria-valuemax="100"></div>
        </div>-->
      </div>

      <!-- Content Slot -->
      <div class="card-body">
        <slot></slot>
      </div>

      <!-- Navigation Buttons -->
      <div class="card-footer d-flex justify-content-between align-items-center">
        <button v-if="showForwardButton" class="btn btn-primary" @click="goForward">
          Continue
        </button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'StepComponent',
  props: {
    title: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    showForwardButton: {
      type: Boolean,
      default: false,
    },
    showCTAButton: {
      type: Boolean,
      default: false,
    },
    ctaText: {
      type: String,
      default: 'Continue',
    },
  },
  computed: {
    progressPercentage() {
      return ((this.currentStep + 1) / this.steps.length) * 100;
    },
  },
  methods: {
    setDynamicHeights() {
      const header = this.$el.querySelector('.card-header');
      const footer = this.$el.querySelector('.card-footer');
      if (header) {
        const headerHeight = header.offsetHeight + 'px';
        document.documentElement.style.setProperty(
          '--header-height',
          headerHeight
        );
      }
      if (footer) {
        const footerHeight = footer.offsetHeight + 'px';
        document.documentElement.style.setProperty(
          '--footer-height',
          footerHeight
        );
      }
    },
    goBack() {
      this.$emit('back');
    },
    goForward() {
      this.$emit('forward');
    },
    ctaAction() {
      this.$emit('cta');
    },
  },
  mounted() {
    // Measure header and footer heights and set CSS variables
    this.setDynamicHeights();
    window.addEventListener('resize', this.setDynamicHeights);
  },
  unmounted() {
    window.removeEventListener('resize', this.setDynamicHeights);
  },
};
</script>


<style scoped>
.step-component {
  background-color: #f8f9fa;
}

.steps-text {
  margin-left: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
}

/* Card Styles */
.card {
  border-radius: 1rem;
  background-color: white;
  border: none;
  margin-bottom: 0;
  overflow: hidden;
  padding: 1.25rem !important;
}

/* Header and Footer Fixed Position */
.card-header,
.card-footer {
  position: fixed;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 10;
  padding: 1rem;
  border: none;
}

/* Header at the Top */
.card-header {
  top: 0;
}

/* Footer at the Bottom */
.card-footer {
  bottom: 0;
}

/* Body Content with Scroll */
.card-body {
  margin-top: 4.5rem;
  margin-bottom: var(--footer-height);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* Smooth scrolling on iOS */
  padding: 1rem;
}

/* Progress Bar Styles */
.progress {
  height: 0.5rem;
  border-radius: 0;
}

.progress-bar {
  background-color: #007bff;
}

.circle-button {
  border-radius: 100px;
  background: #f5f5f5;
  color: #00000099 !important;
  padding: 15px;
  font-weight: 700;
}

.btn.btn-primary {

  width: 100%;
  height: 48px;
  text-transform: uppercase;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  isolation: isolate;


  border-radius: 100px;
  font-weight: 700;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
</style>
