<template>
    <div class="connection-status" :class="statusClass">
        <transition name="fade">
            <div class="full-screen-disconnected" v-if="isDisconnected">
                <div v-if="showSpinner">
                    <h1>
                        <span class="spinner"></span> Reconnecting, please wait...
                    </h1>
                </div>
                <div v-else>
                    <h1 v-if="disconnectReason === 'internet'">
                        <SvgIcon name="notConnected"
                            style="width: 1.5em; height: 1.5em; margin-left: -3.75rem; margin-right: 0.75rem;" /> No
                        Internet Connection
                    </h1>
                    <h1 v-else-if="disconnectReason === 'websocket'">
                        Service Unavailable
                    </h1>
                    <p v-if="disconnectReason === 'websocket'">
                        We're currently experiencing a temporary service interruption.
                    </p>
                    <p v-if="disconnectReason === 'websocket'">
                        Ordering will be available again once the service is restored.
                    </p>
                    <div v-if="disconnectReason === 'internet'">
                        <p>Please try the following steps:</p>
                        <ul>
                            <li>Check your network cables, modem, and router or reconnect to your Wi-Fi network</li>
                            <li>Restart your device.</li>
                            <li>Contact your internet service provider if the issue persists.</li>
                        </ul>
                    </div>
                    <p><span class="spinner"></span> Attempting to reconnect...</p>
                    <button @click="attemptReconnect">Reconnect Now</button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'TerminalStatusMenu',
    props: {
        isWebSocketConnected: {
            type: Boolean,
            required: true,
            default: true,
        },
        initialized: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    data() {
        return {
            isDisconnected: false,
            showSpinner: true,
            disconnectReason: '',
        };
    },
    computed: {
        statusClass() {
            return {
                connected: this.initialized && this.isWebSocketConnected,
                disconnected: !this.initialized || !this.isWebSocketConnected,
            };
        },
    },
    methods: {
        attemptReconnect() {
            this.$emit('attempt-reconnect');
        },
        updateConnectionStatus() {
            if (!navigator.onLine) {
                this.handleDisconnection('internet');
            } else if (!this.isWebSocketConnected) {
                this.handleDisconnection('websocket');
            } else {
                this.isDisconnected = false;
                this.disconnectReason = '';
                this.showSpinner = true;
            }
        },
        handleDisconnection(reason) {
            this.isDisconnected = true;
            this.disconnectReason = reason;
            this.showSpinner = true;
            setTimeout(() => {
                this.showSpinner = false;
            }, 10000);

        },
    },
    watch: {
        isWebSocketConnected() {
            this.updateConnectionStatus();
        },
        initialized() {
            this.updateConnectionStatus();
        },
    },
    mounted() {
        this.updateConnectionStatus();
        window.addEventListener('online', this.updateConnectionStatus);
        window.addEventListener('offline', this.updateConnectionStatus);
    },
    unmounted() {
        window.removeEventListener('online', this.updateConnectionStatus);
        window.removeEventListener('offline', this.updateConnectionStatus);
    },
};
</script>

<style scoped>
.connection-status {
    position: fixed;
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    z-index: 9999;
}


.full-screen-disconnected {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    transition: opacity 0.5s ease-in-out;
    backdrop-filter: blur(15px);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to,
.fade-leave {
    opacity: 1;
}

.full-screen-disconnected h1 {
    font-size: 2em;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
}

.full-screen-disconnected p,
.full-screen-disconnected ul {
    font-size: 1.2em;
    margin-bottom: 1em;
}

.full-screen-disconnected ul {
    list-style-type: disc;
    padding-left: 1.5em;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>