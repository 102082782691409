<template>
  <div class="two-part-layout" :class="{'vertical-layout': screenOrientation === 'vertical'}">
    <div class="container-fluid p-0 m-0 h-100">
      <div class="row g-0 h-100" :class="{'flex-column': screenOrientation === 'vertical'}">
        <div class="col-md-6 d-flex align-items-center justify-content-center">
          <div class="d-block one">
            <!-- Content for the first part -->
            <slot name="one">First Part</slot>
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-center justify-content-center">
          <div class="d-block two">
            <!-- Content for the second part -->
            <slot name="two">Second Part</slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const { mapGetters } = require("vuex");
export default {
  name: "TwoPartLayout",
  computed: {
    ...mapGetters(["screenOrientation"]),
  }
};
</script>
<style scoped>
.two-part-layout {
  height: calc(100vh - 12.5rem);
  margin: 6.25rem !important;
}

.vertical-layout .md-6 * {
  text-align: center !important;
}

.two {
  width: 43.25rem;
  margin: auto;
}
</style>
