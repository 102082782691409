<template>
    <CenteredContainer v-if="visible" :class="{ keyboard: (isMobile && isFocused) }">
        <div class="pin-entry">
            <h1 class="text-center welcome-title">Shift4 Kiosk</h1>
            <p class="text-center welcome-text">To get started, please enter your unique 6-digit PIN below:</p>
            <input id="terminal-pin" type="tel" class="pin-input form-control text-center"
                :class="{ 'success-border': isSuccess }" maxlength="6" v-model="pinCode" @input="handleInput"
                @keyup.enter="submitPin" autofocus placeholder="" @focus="isFocused = true" @blur="isFocused = false"
                pattern="[0-9]*" inputmode="numeric">

            <div v-if="isLoading" class="loading-overlay">
                <div class="loader"></div>
            </div>
            <div v-if="errorMessage" class="alert alert-danger mt-3" style="max-width: 42rem;">{{ errorMessage }}</div>
            <div v-if="canRetryRegistration" class="mt-3 text-center">
                <button @click="retryRegistration" class="btn btn-outline-primary btn-lg mt-3">
                    <SvgIcon name="refresh" width="2.5rem" height="2.5rem" /> Retry
                </button>
            </div>
            <div v-if="isSuccess" class="success-message">
                <h2>Registration Successful!</h2>
                <p>You will be redirected shortly...</p>
            </div>
        </div>
    </CenteredContainer>
</template>

<script>
import CenteredContainer from '@/components/global/CenteredContainer.vue';

import { mapGetters } from 'vuex';

export default {
    components: {
        CenteredContainer
    },
    data() {
        return {
            visible: false,
            pinCode: '',
            isLoading: false,
            isSuccess: false,
            isFocused: false
        };
    },
    computed: {
        ...mapGetters(["isMobile"]),
        ...mapGetters("location", ["config"]),
        ...mapGetters('terminal', ['locationId', 'registrationError', 'apiToken', 'canRetryRegistration', 'terminalAuthenticated']),
        errorMessage() {
            return this.registrationError;
        }
    },
    async mounted() {
        setTimeout(() => {
            this.visible = true;
        }, 2000);

        // Check for Shift4.getConfig interface
        // eslint-disable-next-line no-undef
        if (!this.apiToken && typeof Shift4Kiosk !== 'undefined' && typeof Shift4Kiosk.deviceConfig === 'function') {
            // eslint-disable-next-line no-undef
            const config = JSON.parse(Shift4Kiosk.deviceConfig());
            await this.$store.dispatch("terminal/registerTerminalSerial", config.serialNumber);
        }
    },
    methods: {
        async retryRegistration() {
            this.errorMessage = ''; // Clear the error message
            this.isLoading = true; // Show loading indicator

            // eslint-disable-next-line no-undef
            if (typeof Shift4Kiosk !== 'undefined' && typeof Shift4Kiosk.deviceConfig === 'function') {
                // eslint-disable-next-line no-undef
                const config = JSON.parse(Shift4Kiosk.deviceConfig());
                await this.$store.dispatch("terminal/registerTerminalSerial", config.serialNumber);
            }

            this.isLoading = false; // Hide loading indicator
        },
        handleInput() {
            this.pinCode = this.pinCode.toUpperCase();
            this.$store.commit('terminal/SET_REGISTRATION_ERROR', '');
            if (this.pinCode.length === 6) {
                this.submitPin();
            }
        },
        async submitPin() {
            if (!this.pinCode || this.isLoading) return;

            this.isLoading = true;
            await this.$store.dispatch('terminal/registerTerminal', { pin: this.pinCode });
            this.isLoading = false;
            if (!this.errorMessage) {
                this.isSuccess = true;
            }
        }
    }
};
</script>

<style scoped>
.welcome-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.welcome-text {
    font-size: 1.5rem;
    color: #495057;
    margin-bottom: 2rem;
}

.pin-input {
    font-size: 2.5rem;
    letter-spacing: 1rem;
    padding: 1rem;
    border: 0.125rem solid #007bff;
    transition: border-color 0.3s ease;
}

.pin-input:focus {
    border-color: #0056b3;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.alert {
    font-size: 1.25rem;
}

.keyboard {
    height: 50vh !important;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
}

.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.success-border {
    border-color: green !important;
}


.success-message {
    text-align: center;
    margin-top: 2rem;
    padding: 1rem;
    background-color: #d4edda;
    border: 0.125rem solid #c3e6cb;
    border-radius: 0.25rem;
}

.success-message h2 {
    font-size: 2rem;
    color: #155724;
    margin-bottom: 0.5rem;
}

.success-message p {
    font-size: 1.25rem;
    color: #155724;
}
</style>