<template>
  <div v-if="visible" class="special-requests">
    <div class="title">{{ $t('specialInstructions') }}</div>
    <textarea v-model="specialRequest" @click="openModal" :disabled="!canOpen" :maxlength="charLimit" autocomplete="off"></textarea>
    <p>{{ specialRequestInstructions }}</p>
    <p v-if="charLimitMessage">{{ charLimitMessage }}</p>
    <GetText v-if="isModalOpen" :title="$t('specialInstructions')"
      :instructions="$t('specialRequestInstructions')" :isOpen="isModalOpen" :char-limit="charLimit" @apply="setSpecialRequest"
      @close="isModalOpen = false" :button-text="$t('submitButtonText')" />
  </div>
</template>

<script>
import GetText from './../../global/GetTextModal';
import { mapGetters } from 'vuex';

export default {
  name: 'SpecialRequest',
  components: { GetText },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    specialRequestInstructions: {
      type: String,
      required: false,
      default: ''
    },
    initialSpecialRequest: {
      type: String,
      required: false,
      default: ''
    },
    charLimit: {
      type: Number,
      required: false,
      default: 32
    }
  },
  data() {
    return {
      specialRequest: this.initialSpecialRequest,
      isModalOpen: false,
      canOpen: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.canOpen = true;
    }, 500);
  },
  computed: {
    ...mapGetters(["isMobile"]),
    charLimitMessage() {
      return this.charLimit ? `${this.specialRequest.length}/${this.charLimit}` : '';
    }
  },
  watch: {
    specialRequest(newVal) {
      const emojiFreeText = newVal.replace(/[\u{1F600}-\u{1F64F}]/gu, ''); // Remove emojis
      if (emojiFreeText !== newVal) {
        this.specialRequest = emojiFreeText;
      }
      this.$emit('update:specialRequest', emojiFreeText);
    }
  },
  methods: {
    openModal() {
      if (this.isMobile && this.canOpen) {
        this.isModalOpen = true;
      }
    },
    setSpecialRequest(text) {
      this.specialRequest = text;
      this.isModalOpen = false;
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 1.5rem;
  font-weight: 700;
}

.special-requests {
  border-top: 0.125rem solid var(--grey-outline);
  padding: 2rem 2.125rem;
}

.special-requests textarea {
  width: 100%;
  height: 12.8751rem;
  padding: 0.5rem;
  border: 0.125rem solid var(--grey-outline);
  border-radius: var(--border-radius-xs);
  font-size: 2rem;
}

.special-requests p {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--grey-medium);
}
</style>
