<template>
  <div :class="fadeInClass">
    <div v-if="type">
      <question-asker v-if="showQuestions" :questions="questionsToAsk" @cancel="handleQuestionCancel"
        @complete="handleQuestionComplete" />

      <GetTextComponent v-if="showCouponModal" :isOpen="showCouponModal" :title="$t('couponCode')"
        :instructions="$t('couponCodeInstructions')" :buttonText="$t('apply')" :validate="validateCouponCode"
        @close="showCouponModal = false, setBarcodeActive(true)" @apply="handleApplyCoupon" />

      <template v-if="orderType.view === 'retail'">
        <item-order @close="close" :specialRequest="config.allowSpecialRequests"
          :initialModifications="selectedItem?.lineModifierSets || []" />
        <SpaceX @item-selected="seachSelected" :total="total" @barcode-scanned="handleScan" @removed="removeItem"
          @reset="reset" @checkout="handleCheckout" @applyCoupon="showCouponModalHandler"
          @removeCoupon="handleRemoveCoupon" :cart-title="$t('cartTitle')" :departments="departments" :items="items"
          :order-items="orderItems" :selected-order-type="orderType" @setBarcodeActive="setBarcodeActive"
          :order-types="orderTypes" :hasCoupons="hasCoupons" :coupon="coupon" />

      </template>
      <template v-else>
        <item-order @close="close" :showQuantity="config.rails === 'stm'"
          :specialRequest="config.allowSpecialRequests" />

        <div class="layout">
          <div class="menu-col" ref="menu">
            <span ref="locationInfo" />
            <LocationInfo :logoUrl="config.logo?.url" :logoShape="config.logo?.shape || 'circle'"
              :locationName="details.name" :fullAddress="fullAddress" />
            <div class="search-button-hold">
              <LanguageSelector v-if="config" :default-language="config.languageSupport.defaultLanguage"
                :enabled="config.languageSupport.enabled"
                :supported-languages="config.languageSupport.supportedLanguages" />
            </div>

            <PillNavigation class="tab-nav" :tabs="departmentTabs" :nav-top="navTop" :logo="config.logo?.url"
              :shape="config.logo?.shape || 'circle'" :selectedTabRef="selectedDepartmentTab"
              @tab-selected="departmentTabSelected" ref="nav" />
            <div class="departments">
              <Department v-for="department in departments" :key="department.ref" :name="department.name"
                :id="`dep-${department.ref}`" :items="departmentItems(department)" :cols="Number(config.menu_cols)"
                :description="department.description" @item-selected="menuSelected" />
            </div>

          </div>
          <div class="order-col">
            <OrderControls :customerName="customerName" :orderCount="orderItems.length" :cart-title="$t('cartTitle')"
              :orderTypes="orderTypes" :selected="orderTypeId" @orderTypeSelected="orderTypeSelected" @reset="reset" />
            <OrderSummary :use-icons="config.icons" :items="orderItems" :emptyCartMessage="$t('emptyCartMessage')"
              :discounts="discounts" :coupon="coupon" :subtotal="subtotal" :taxTotal="taxTotal" @removed="removeItem"
              @checkout="handleCheckout" @edit="handleItemEdit" @reset="reset" @applyCoupon="showCouponModalHandler"
              @removeCoupon="handleRemoveCoupon"
              :showQuantityEdit="orderType.view === 'restaurant' && config.rails === 'stm'"
              :next-button-text="nextButtonText" :payment-types="orderType.allowedPaymentTypes" :hasCoupons="hasCoupons"
              :show-item-details="config.orderSummaryShowModifiers || false" :surcharges="surcharges || []" />
          </div>
        </div>
      </template>
      <BarcodeScanner @scan="handleScan" :is-active="barcodeActive" aria-label="Barcode Scanner" role="application" />
    </div>
    <ErrorModal :visible="errorModalVisible" :message="errorMessage" @back="closeErrorModal"
      @startOver="reset" :allowBack="true" :allowStartOver="true" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import LanguageSelector from "@/components/features/LanguageSelector.vue";
import ItemOrder from "@/components/features/itemOrder/ItemOrder.vue";
import BarcodeScanner from "@/components/features/BarcodeScanner.vue";
import LocationInfo from "@/components/global/LocationInfo.vue";
import PillNavigation from "@/components/global/PillNavigation.vue";
import GetTextComponent from "@/components/global/GetTextModal.vue";
import Department from "@/components/features/menu/MenuDepartment.vue";
import SpaceX from "@/components/features/menu/spaceX/MainLayout.vue";
import OrderControls from "@/components/features/order/OrderControls.vue";
import OrderSummary from "@/components/features/order/OrderSummary.vue";
import QuestionAsker from "@/components/features/QuestionAsker.vue";
import ErrorModal from "@/components/global/ErrorModal.vue";
import lookupList from "@/utils/lookupList";
import { formatCurrency, formatAddress } from "@/utils/helpers";

const preloadSpinner = new Image();
preloadSpinner.src = require('@/assets/spinner.png');


export default {
  inject: ["translator"],
  data() {
    return {
      showQuestions: false,
      questionsToAsk: [],
      rightColVisible: false,
      selectedDepartmentTab: null,
      disableAutoSelect: false,
      navTop: false,
      selectedItem: null,
      lookupItems: lookupList,
      showCouponModal: false,
      couponCode: '',
      fadeInClass: 'fade-in',
      errorModalVisible: false,
      errorMessage: '',

    };
  },
  components: {
    LanguageSelector,
    ItemOrder,
    SpaceX,
    LocationInfo,
    PillNavigation,
    Department,
    OrderControls,
    OrderSummary,
    QuestionAsker,
    GetTextComponent,
    BarcodeScanner,
    ErrorModal,
  },
  computed: {
    ...mapGetters(["screenOrientation"]),
    ...mapGetters("location", ["details", "config", "hasCoupons"]),
    ...mapGetters("menu", ["departments", "items"]),
    ...mapGetters("customer", ["availableLastOrderedItems"]),
    ...mapGetters("orderItem", ["item"]),
    ...mapGetters("order", {
      barcodeLookup: "barcodeLookup",
      type: "type",
      orderTypeId: "orderTypeId",
      orderItems: "items",
      subtotal: "subtotal",
      taxTotal: "taxTotal",
      grandTotal: "grandTotal",
      customerName: "name",
      barcodeActive: "barcodeActive",
      coupon: "coupon",
      total: "total",
      discounts: "discounts",
      surcharges: "surcharges",
    }),
    ...mapState(['setupMode']),
    isInSetupMode() {
      return this.setupMode.active && this.setupMode.screen === this.$route.name;
    },
    nextButtonText() {
      if (this.orderItems.length > 0 && this.total === 0) {
        return this.$t('submitOrderButtonText');
      } else {
        return this.orderType.view === 'restaurant' ? this.$t('checkout') : this.$t('pay');
      }
    },
    fullAddress() {
      return this.formatAddress(this.details);
    },
    orderType() {
      return this.config.orderTypes.filter(ot => ot.active).find((ot) => ot.id === this.orderTypeId);
    },
    orderTypes() {
      return this.config.orderTypes.filter(ot => ot.active);
    },
    departmentTabs() {
      return this.departments.map(department => ({
        ref: department.ref,
        name: department.name,
      }));
    }
  },
  mounted() {
    this.selectedDepartmentTab = this.departmentTabs[0].ref;
    this.$store.dispatch("orderItem/reset");
    this.$store.dispatch("order/clearPendingTicket");
    this.selectedItem = null;

    if (this.type && this.orderType.view === "restaurant") {
      this.scrollableDiv = this.$refs.menu;
      this.checkDepartmentsTop();

      // Delay the scroll event binding to ensure the layout is stable
      this.$nextTick(() => {
        this.scrollableDiv.addEventListener("scroll", this.handleMenuScroll);
      });
    }

    if (this.barcodeLookup) {
      this.handleScan(this.barcodeLookup);
      this.$store.dispatch("order/setBarcodeLookup", null);
    }

    setTimeout(() => {
      this.fadeInClass = '';
    }, 300);
  },
  methods: {
    formatAddress,
    closeErrorModal() {
      this.errorModalVisible = false;
      this.errorMessage = '';
    },
    reset() {
      this.$router.push({ name: "start" });
    },
    setBarcodeActive(active) {
      this.$store.dispatch("order/setBarcodeActive", active);
    },
    seachSelected(item) {
      this.handleItemSelected(item, true);
    },
    menuSelected(item) {
      this.handleItemSelected(item);
    },
    handleMenuScroll() {
      this.checkDepartmentsTop();
      const nav = this.$refs.nav.$el;
      const rect = nav.getBoundingClientRect();

      if (this.scrollableDiv.scrollTop > 0 && rect.top <= 1) {
        this.navTop = true;
      } else {
        this.navTop = false;
      }
    },
    checkDepartmentsTop() {
      if (this.disableAutoSelect) return;
      const parentTop = this.$refs.menu.getBoundingClientRect().top;
      this.departments.forEach((department) => {
        const sectionElement = this.$el.querySelector(`#dep-${department.ref}`);
        if (sectionElement) {
          const sectionTop = sectionElement.getBoundingClientRect().top;
          if (sectionTop <= (parentTop + 150)) {
            this.selectedDepartmentTab = department.ref;
          }
        }
      });
    },
    async handleScan(barcode) {
      const normalizedBarcode = barcode.trim().toLowerCase();

      let item = this.items.find(item =>
        item.customLabels.some(label => label.toLowerCase().padStart(12, '0') === normalizedBarcode.padStart(12, '0'))
      );

      if (!item) {
        const lookupItem = this.lookupItems.find(i =>
          i.lookup.includes(normalizedBarcode)
        );
        if (lookupItem && lookupItem.ref) {
          item = this.items.find(i => i.ref === lookupItem.ref);
        }
      }

      if (item) {
        this.handleItemSelected(item, true);
      } else {
        try {
          const response = await this.$store.dispatch("order/barcodeNotFound", normalizedBarcode);
          if (response.possibleMatch) {
            item = this.items.find(i => i.ref === response.possibleMatch.ref);
            if (item) {
              this.$store.dispatch("dialog/openDialog", {
                title: "Found Item",
                message: `Is this the right item? ${item.name}, Price: ${formatCurrency(item.price)}`,
                showCancel: true,
                onConfirm: () => this.handleItemSelected(item, true),
                onCancel: () => this.$store.dispatch("dialog/openDialog", {
                  title: "Item Not Found",
                  message: `Sorry, we couldn't find that item. Barcode: ${barcode}`
                })
              });
            }
          } else {
            this.$store.dispatch("dialog/openDialog", {
              title: "Item Not Found",
              message: "Sorry, we couldn't find that item."
            });
          }
        } catch (error) {
          this.$store.dispatch("dialog/openDialog", {
            title: "Error",
            message: "An error occurred during the barcode scan.",
          });
        } finally {
          this.$store.dispatch('doneLoading');
        }
      }
    },
    departmentTabSelected(ref) {
      this.selectedDepartmentTab = ref;
      this.disableAutoSelect = true;

      if (this.departmentTabs.length > 0 && ref === this.departmentTabs[0].ref) {
        this.scrollToTopOfMenu();
      } else {
        this.scrollToDepartment(ref);
      }
    },
    scrollToTopOfMenu() {
      const menu = this.$refs.menu;
      if (menu) {
        this.navTop = false;
        menu.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    departmentItems(department) {
      const itemMap = new Map(this.items.map((item) => [item.ref, item]));
      return department.items.map((itemRef) => {
        const baseItem = itemMap.get(itemRef.ref);
        if (baseItem) {
          baseItem.lineModifierSets = itemRef.lineModifierSets || null;
          return baseItem;
        } else {
          return null;
        }
      }).filter(Boolean);
    },
    handleItemSelected(item, skipModalIfNoModifiers = false, modifications = []) {
      let hasModifierSets = item.modifierSets.some(modifierSet => modifierSet.modifiers.length > 0);

      if (!hasModifierSets && (skipModalIfNoModifiers || !this.config?.allowSpecialRequests)) {
        this.orderItem(item);
        return;
      }

      this.$store.dispatch("orderItem/start", {
        item,
        quantity: 1,
        lineModifierSets: modifications,
        specialRequest: "",
      });
      this.selectedItem = item;
    },
    handleItemEdit(payload) {
      const cartItem = payload.item;
      const index = payload.itemIndex;

      const item = this.items.find((item) => item.ref === cartItem.itemRef);
      if (item) {
        this.$store.dispatch("orderItem/start", {
          item,
          quantity: cartItem.quantity,
          lineModifierSets: cartItem.lineModifierSets,
          specialRequest: cartItem.instructions,
          editIndex: index
        });
        this.selectedItem = item;
      }
    },
    handleRemoveCoupon() {
      this.$store.dispatch("order/removeCoupon");
    },
    close() {
      this.$store.dispatch("orderItem/close");
      this.selectedItem = null;
    },
    orderItem(item) {
      item.itemRef = item.ref || item.itemRef;
      const fullItem = this.items.find((i) => i.ref === item.itemRef);
      console.log('Full item', fullItem);
      const image = fullItem.images?.[0] || null;
      console.log('Adding item to order', item);
      this.$store.dispatch("order/addItem", {
        ...item,
        image,
        quantity: 1,
        total: item.price,
      });
      this.selectedItem = null;
    },
    async validateCouponCode(code) {
      const result = await this.$store.dispatch("coupon/validate", { code });
      return result;
    },
    handleApplyCoupon() {
      this.setBarcodeActive(true);
      this.showCouponModal = false;
    },
    showCouponModalHandler() {
      this.setBarcodeActive(false);
      this.showCouponModal = true;
    },
    removeItem(index) {
      this.$store.dispatch("order/removeItem", index);
    },
    orderTypeSelected(type) {

      // we essentially want to check if the order type has any questions
      // if it does, we want to send the user to the start view with the order type id
      // if it doesn't - then just set the order type
      if (type.orderDetails.filter(q => q.timing === 'startOrder').length > 0) {
        this.$router.push({ name: 'start', query: { orderTypeRef: type.id } });
      } else {
        this.$store.dispatch("order/setOrderType", type);
      }
    },
    scrollToDepartment(departmentRef) {
      if (!this.$el.querySelector) return;

      const departmentElement = this.$el.querySelector(`#dep-${departmentRef}`);
      if (departmentElement) {
        const elementTop = departmentElement.getBoundingClientRect().top - this.$refs.menu.getBoundingClientRect().top + this.$refs.menu.scrollTop;

        const baseFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        const desiredOffsetRems = 9;
        const desiredOffsetPixels = baseFontSize * desiredOffsetRems;

        const targetScrollTop = elementTop - desiredOffsetPixels;

        const menu = this.$refs.menu;
        const currentBottomPadding = parseFloat(getComputedStyle(menu).paddingBottom);
        const requiredHeight = targetScrollTop + menu.clientHeight;
        if (menu.scrollHeight - currentBottomPadding < requiredHeight) {
          const additionalPadding = requiredHeight - (menu.scrollHeight - currentBottomPadding);
          menu.style.paddingBottom = `${currentBottomPadding + additionalPadding}px`;
        }

        this.smoothScroll(menu, targetScrollTop, 600);

        setTimeout(() => {
          this.disableAutoSelect = false;
        }, 2000);
      }
    },
    smoothScroll(element, target, duration) {
      const start = element.scrollTop,
        change = target - start,
        startTime = performance.now();

      function scrollAnimation(currentTime) {
        const timeElapsed = currentTime - startTime;
        const progress = timeElapsed / duration;
        const easeInOutQuad = t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        element.scrollTop = start + change * easeInOutQuad(progress);
        if (timeElapsed < duration) requestAnimationFrame(scrollAnimation);
      }

      requestAnimationFrame(scrollAnimation);
    },
    handleQuestionCancel() {
      this.showQuestions = false;
    },
    async handleQuestionComplete(answers) {
      this.$store.dispatch("order/fillOrderDetails", answers);
      this.showQuestions = false;
      try {
        const result = await this.$store.dispatch("order/validate");

        if (result.success) {
          this.$router.push({ name: "checkout" });
        } else {
          this.errorModalVisible = true;
          this.errorMessage = result.errorMessage;
        }
      } catch (e) {
        this.errorModalVisible = true;
        this.errorMessage = "An error occurred during the order validation.";
      }
    },
    prepareQuestionsToAsk(questions) {
      const keysToTranslate = ['email', 'phone', 'badge', 'name'];
      return questions.map(question => {
        if (this.translator.currentLanguage === this.config.languageSupport.defaultLanguage) {
          return question;
        }

        const key = keysToTranslate.find(k => question.title.toLowerCase().includes(k));
        if (key) {
          question.title = this.$t(`${key}Question`);
          question.text = '';
        }
        return question;
      });
    },
    async continueCheckout() {

      const orderDetailsClone = JSON.parse(JSON.stringify(this.orderType?.orderDetails || []));
      this.questionsToAsk = this.prepareQuestionsToAsk(orderDetailsClone.filter(q => q.timing === 'beforeValidate'));
      if (this.questionsToAsk.length > 0) {
        this.showQuestions = true;
        return;
      }

      try {
        const response = await this.$store.dispatch("order/validate");
        if (!response.success) {
          this.errorModalVisible = true;
          this.errorMessage = response.errorMessage;
          return;
        }
        if (this.total > 0) {
          this.$router.push({ name: "checkout" });
        }
      } catch (e) {
        this.errorModalVisible = true;
        this.errorMessage = "An error occurred during the order validation.";
        return;
      }
    },
    async handleCheckout() {
      if (this.orderType.promptCoupon && !this.coupon) {
        this.$store.dispatch("dialog/openDialog", {
          title: "Do you have a coupon code?",
          message: 'You have not entered a coupon code. Do you have one?',
          showCancel: true,
          okText: 'Yes',
          cancelText: 'No',
          onConfirm: () => {
            this.showCouponModal = true;
          },
          onCancel: () => {
            this.continueCheckout();
          }
        });
      } else {
        this.continueCheckout();
      }
    },
  },
  watch: {
    item(newItem) {
      if (newItem) {
        this.setBarcodeActive(false);
      } else {
        this.setBarcodeActive(true);
      }
    },
    type(newType) {
      if (newType && this.orderType.view === "restaurant") {
        this.$nextTick(() => {
          this.scrollableDiv = this.$refs.menu;
          this.scrollableDiv.addEventListener("scroll", this.handleMenuScroll);
          this.checkDepartmentsTop();
        });
      } else {
        this.scrollableDiv = this.$refs.menu;
        if (!this.scrollableDiv) return;

        this.scrollableDiv.removeEventListener("scroll", this.handleMenuScroll);
      }
    },
  },
};
</script>

<style scoped>
.menu-col .search-button-hold {
  position: fixed;
  top: 1.875rem;
  right: 44.25rem;
}

.row>* {
  padding: 0;
}

.layout {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.menu-col {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  flex: 1;
}

.menu-col .departments {
  padding: 0 2.5rem 3rem 2.5rem;
}

.order-col {
  width: 43rem !important;
  overflow-y: scroll;
  height: 100vh;
  border-left: 0.125rem solid #eaeaea;
  padding: 0;
  background-color: var(--primary-white);
}

/* Vertical */

.vertical .layout {
  flex-direction: column;
}

.vertical .menu-col,
.vertical .order-col {
  width: 100% !important;
}

.vertical .menu-col {
  height: calc(100vh - 25rem);
}

.vertical .order-col {
  height: 25rem;
  border-top: 0.125rem solid var(--grey-outline);
  display: block;
}
</style>
