<template>
    <div>
        <list-component :items="coupons" @edit="openEditModal" @delete="deleteCoupon" />

        <br />
        <button class="btn btn-primary mb-3" style="float: right;" @click="openAddModal">Create New Coupon</button>

        <modal-component :isVisible="isModalVisible" @close="closeModal">
            <form @submit.prevent="saveCoupon">
                <div v-if="errorMessage" class="alert alert-danger">
                    {{ errorMessage }}
                </div>
                <div class="mb-3 form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="couponActive" v-model="coupon.active">
                    <label class="form-check-label" for="couponActive">Active</label>
                </div>
                <div class="mb-3">
                    <label for="couponName" class="form-label">Coupon/Reporting Name</label>
                    <input type="text" class="form-control" id="couponName" v-model="coupon.name"
                        placeholder="e.g., 10% OFF" required maxlength="20">
                </div>
                <div class="mb-3">
                    <label for="couponCode" class="form-label">Coupon Code</label>
                    <input type="text" class="form-control" id="couponCode" v-model="coupon.code"
                        @input="coupon.code = coupon.code.toUpperCase()" placeholder="e.g., SAVE10" required
                        maxlength="10">
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label for="couponAmount" class="form-label">Coupon Amount</label>
                        <input type="number" class="form-control" id="couponAmount" v-model="coupon.amount" required>
                    </div>
                    <div class="col">
                        <label for="couponType" class="form-label">Coupon Type</label>
                        <select class="form-select" id="couponType" v-model="coupon.type" required>
                            <option value="%">Percent (%)</option>
                            <option value="$">Amount ($)</option>
                        </select>
                    </div>
                </div>
                <div class="preview mb-3">
                    <h5>Preview</h5>
                    <CouponItem :coupon="coupon" :discounts="coupon.amount" />
                </div>
                <div class="d-flex justify-content-between">
                    <button type="button" class="btn btn-outline-secondary me-2" @click="closeModal">Cancel</button>
                    <button type="submit" class="btn btn-primary">Save Changes</button>
                </div>
            </form>
        </modal-component>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ListComponent from '@/components/features/admin/ListComponent';
import ModalComponent from '@/components/features/admin/ModalComponent';
import CouponItem from '@/components/features/order/CouponItem.vue';

export default {
    components: {
        ListComponent,
        ModalComponent,
        CouponItem
    },
    data() {
        return {
            isModalVisible: false,
            coupon: {
                name: '',
                code: '',
                amount: '',
                type: '%',
                active: true,
                appendDate: false
            },
            isEditMode: false,
            showTooltip: false,
            errorMessage: null, // Add errorMessage to hold the error returned by the backend
        };
    },
    computed: {
        ...mapState('coupon', ['coupons'])
    },
    methods: {
        ...mapActions('coupon', ['fetchCoupons', 'updateCoupon', 'createCoupon', 'deleteCoupon']),
        openAddModal() {
            this.isEditMode = false;
            this.coupon = { active: true, name: '', code: '', amount: '', type: '%', append_date: false };
            this.isModalVisible = true;
            this.errorMessage = null; // Reset error message
        },
        openEditModal(coupon) {
            this.isEditMode = true;
            this.coupon = { ...coupon }; // Clone the coupon to avoid mutating the original object
            this.isModalVisible = true;
            this.errorMessage = null; // Reset error message
        },
        closeModal() {
            this.isModalVisible = false;
            this.coupon = { name: '', code: '', amount: '', append_date: false };
            this.errorMessage = null; // Reset error message
        },
        async saveCoupon() {
            this.errorMessage = null; // Clear previous errors

            try {
                if (this.isEditMode) {
                    await this.updateCoupon({ id: this.coupon.id, couponData: this.coupon });
                } else {
                    await this.createCoupon(this.coupon);
                }
                this.closeModal();
            } catch (error) {
                this.errorMessage = error.response?.data?.error || 'An error occurred'; // Display the error message
            }
        },
        getTodayDate() {
            const today = new Date();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            return month + day;
        }
    },
    created() {
        this.fetchCoupons();
    }
};
</script>

<style scoped>
/* Add any scoped styles for the Coupons component here */
.btn-success {
    margin-bottom: 1rem;
}

.modal-content {
    padding: 2rem;
}

.info-icon {
    margin-left: 5px;
    cursor: pointer;
}

.tooltip-text {
    position: absolute;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 200px;
    z-index: 1000;
}
</style>
